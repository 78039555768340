import {useVipCustomer} from '@adamlip/okeoke.client';
import {CircularProgress, Modal, ModalBody, ModalContent} from '@nextui-org/react';
import useBasket from 'hooks/useBasket';
import {useContext, useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {UserContext} from 'contexts/UserContext';
import TakeAwayDeliverySlide from './Slides/TakeAwayDeliverSlide';
import BasketItems from './Slides/BasketItems';
import Payment from './Slides/Payment';
import {AppParamsContext} from 'contexts/AppParamsContext';
import {MainContext} from 'contexts/MainContext';

export default function BasketModal({isOpen, setIsOpen}) {
  const {appParams} = useContext(AppParamsContext);
  const {userData, isVipBrand} = useContext(UserContext);
  const {checkSavedTable} = useContext(MainContext);
  const swiperRef = useRef();

  const {handleChangePaymentType, deliveryInfo, notes, setTakeAway, setOrderIdentifier, orderItems} = useBasket();

  const [delivery, setDelivery] = useState(false);
  const [carPickUp, setCarPickUp] = useState(false);
  const [selectedTab, setSelectedTab] = useState('takeAway');
  const [orderSubmitLoading, setOrderSubmitLoading] = useState(false);
  const [usableMoney, setUsableMoney] = useState(0);
  const [allowSlide, setAllowSlide] = useState(true);
  const {getVipProfile} = useVipCustomer();
  const [swiperSlidesLength, setSwiperSlidesLength] = useState(1);
  const [activeSlideName, setActiveSlideName] = useState('basketItems');
  const activeSlide = useRef(0);
  const goToNext = () => {
    swiperRef.current.slideNext();
  };

  const onReachEnd = () => {
    checkSavedTable();
    if (isVipBrand && userData?.vipID) {
      getVipProfile(userData?.vipID).then((res) => {
        if (res.success) {
          setUsableMoney(res.data.money);
        } else {
          setUsableMoney(0);
        }
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      let pos = userData?.email?.search('@');
      if (pos > 12) pos = 12;
      let shortEmail = userData?.email?.slice(0, pos);
      let savedIdentifier = localStorage['orderIdentifier'] != null && localStorage['orderIdentifier'] !== 'undefined' ? localStorage['orderIdentifier'] : null;
      let identifier = savedIdentifier || userData?.firstName || shortEmail || 'Anonymous';
      identifier = identifier.slice(0, 12);
      setOrderIdentifier(identifier);
    }
  }, [isOpen]);

  useEffect(() => {
    let totalSlidesCount = 1;
    if (appParams?.basketModal?.visibleBlocks?.basket) totalSlidesCount++;
    if (appParams?.basketModal?.visibleBlocks?.takeAwayDelivery) totalSlidesCount++;
    setSwiperSlidesLength(totalSlidesCount);
    if (appParams?.basketModal?.paymentTypes?.offline && appParams?.basketModal?.paymentTypes?.online) {
      handleChangePaymentType('Online');
    } else {
      if (appParams?.basketModal?.paymentTypes?.offline) {
        handleChangePaymentType('Offline');
      }
      if (appParams?.basketModal?.paymentTypes?.online) {
        handleChangePaymentType('Online');
      }
    }
    setTakeAway(appParams?.basketModal?.whereWhen?.defaultWhere === 'eatIn' ? 0 : 1);
  }, [appParams]);

  useEffect(() => {
    if (activeSlideName === 'takeAway') {
      if (delivery) {
        if (deliveryInfo === null) {
          setAllowSlide(false);
        } else {
          setAllowSlide(true);
        }
      }
      if (selectedTab === 'carPickUp') {
        if (selectedTab === 'carPickUp' && (notes?.length || '') < 12) {
          setAllowSlide(false);
        } else {
          setAllowSlide(true);
        }
      }
      if (!delivery && selectedTab !== 'carPickUp') {
        setAllowSlide(true);
      }
    } else {
      setAllowSlide(true);
    }
  }, [selectedTab, notes, delivery, deliveryInfo, activeSlideName]);
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => {
        setIsOpen(!isOpen);
      }}
      size={appParams?.basketModal?.fullScreen ? 'full' : '2xl'}
      radius='none'
      className='bg-background'
      classNames={{
        base: `m-0 max-w-[820px] ${appParams?.basketModal?.fullScreen ? '' : 'h-[80vh]'} `,
        wrapper: 'sm:items-end',
        closeButton: 'z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]',
      }}
      motionProps={{
        initial: {y: 890},
        animate: {y: 0, transition: {duration: 0.5, type: 'spring', stiffness: 50}},
        exit: {y: 890, transition: {duration: 0.5}},
      }}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody className='px-2 overflow-hidden'>
              <div className='h-full'>
                {orderSubmitLoading && (
                  <div className='absolute top-0 left-0 bottom-0 right-0 bg-black opacity-80 z-10 flex justify-center items-center'>
                    <CircularProgress size='lg' color='primary' aria-label='aria label' />
                  </div>
                )}
                <Swiper
                  allowSlideNext={orderItems?.filter((item) => item?.count > 0)?.length > 0 && allowSlide}
                  onReachEnd={() => onReachEnd()}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{clickable: true}}
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  onSlideChange={(e) => {
                    activeSlide.current = e?.activeIndex;
                    setActiveSlideName(e?.slides?.[e?.activeIndex]?.id);
                  }}
                  className='h-full'>
                  {appParams?.basketModal?.visibleBlocks?.basket && (
                    <SwiperSlide className='h-full flex flex-col' key={'basketItems'} tag='basketItems'>
                      <BasketItems goToNext={goToNext} />
                    </SwiperSlide>
                  )}
                  {appParams?.basketModal?.visibleBlocks?.takeAwayDelivery && (
                    <SwiperSlide className='h-full flex flex-col' key={'takeAwayDelivery'} id='takeAway' tabIndex={'takeAway'}>
                      <TakeAwayDeliverySlide
                        goToNext={goToNext}
                        setAllowSlide={setAllowSlide}
                        delivery={delivery}
                        setDelivery={setDelivery}
                        carPickUp={carPickUp}
                        setCarPickUp={setCarPickUp}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                      />
                    </SwiperSlide>
                  )}
                  <SwiperSlide className='h-full flex flex-col overflow-hidden'>
                    <Payment setOrderSubmitLoading={setOrderSubmitLoading} usableMoney={usableMoney} setIsOpen={setIsOpen} />
                  </SwiperSlide>
                </Swiper>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
