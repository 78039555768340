import { useOrders } from "@adamlip/okeoke.client";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import DraggableOrderCard from "components/DraggableOrdersV2/DraggableOrderCard";
import Droppable from "components/DraggableOrdersV2/Droppable";
import DroppableBin from "components/DraggableOrdersV2/DroppableBin";
import { createContext, useEffect, useState } from "react";
const FloatingOpenOrdersContext = createContext();

const FloatingOpenOrdersContextProvider = ({ children }) => {
  const [openOrders, setOpenOrders] = useState(null);
  const { orders } = useOrders();

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { distance: 8 },
    }),
    useSensor(TouchSensor, {
      activationConstraint: { distance: 8 },
    })
  );
  const [activeObjectID, setActiveObjectID] = useState(null);
  useEffect(() => {
    if (orders) {
      setOpenOrders(
        Object.values(orders)
          ?.filter((order) => order?.orderState < 90)
          ?.map((order, index) => ({
            ...order,
            id: order.uuid,
            top: 40 + index * 80,
            left: 0,
          }))
      );
    }
  }, [orders]);
  const handleDragStart = ({ active }) => {
    setActiveObjectID(active.id);
  };
  const handleDragEnd = (e) => {
    setActiveObjectID(null);
    if (e?.over?.id === "bin") {
      setOpenOrders((prev) =>
        prev.filter((order) => order?.uuid !== e?.active?.id)
      );
    }

    const containerWidth = document.getElementById("container").offsetWidth;
    const cardWidth = document.getElementById(e?.active?.id).offsetWidth;
    let draggedOrderTop =
      openOrders.find((order) => order?.uuid === e?.active.id)?.top || 0;
    let draggedOrderLeft =
      openOrders.find((order) => order?.uuid === e?.active.id)?.left || 0;
    let newTop = draggedOrderTop + e.delta.y;
    let newLeft = draggedOrderLeft + e.delta.x;

    if (newLeft < containerWidth / 2) {
      newLeft = 0;
    } else {
      newLeft = containerWidth - cardWidth;
    }

    setOpenOrders((prev) =>
      prev.map((order) => {
        if (order?.uuid === e?.active.id) {
          return { ...order, top: newTop, left: newLeft };
        } else {
          return order;
        }
      })
    );
  };
  return (
    <FloatingOpenOrdersContext.Provider value={{ openOrders, setOpenOrders }}>
      <div
        className="overflow-hidden"
        id="container"
        style={{
          pointerEvents: activeObjectID ? "none" : "auto",
        }}
      >
        <DndContext
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          sensors={sensors}
        >
          <Droppable id="screen">
            {openOrders?.map((order) => (
              <DraggableOrderCard
                key={order?.uuid}
                order={order}
                orders={openOrders}
                active={activeObjectID === order?.uuid}
                setOrders={setOpenOrders}
                setActiveObjectID={setActiveObjectID}
              />
            ))}
          </Droppable>
          {activeObjectID && <DroppableBin id="bin"></DroppableBin>}
        </DndContext>
      </div>

      {children}
    </FloatingOpenOrdersContext.Provider>
  );
};

export { FloatingOpenOrdersContext, FloatingOpenOrdersContextProvider };
