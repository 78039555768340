import { OEAsset, useClient, useDictionary } from "@adamlip/okeoke.client";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Radio,
  RadioGroup,
  cn,
} from "@nextui-org/react";
import ModifierV4Type1 from "components/ModifierV4Type1";
import { useEffect, useState, useContext } from "react";
import LoadSvg from "utils/LoadSvg-v3";
import { AppParamsContext } from "contexts/AppParamsContext";

function ModifierGroupSelector({
  group,
  firstInvalid,
  setModifierCount,
  readOnly,
  tryedToAdd,
  name,
}) {
  const { getName } = useClient();
  const { appParams } = useContext(AppParamsContext);
  const { getValue } = useDictionary();
  const [selectedModifier, setSelectedModifier] = useState("");
  const [selectedModifiersCount, setSelectedModifiersCount] = useState(0);
  const [shakeIt, setShakeIt] = useState(false);
  useEffect(() => {
    let count = getSelectedCount();
    setSelectedModifiersCount(count);
    // eslint-disable-next-line
  }, [group]);

  useEffect(() => {
    if (group.type === 1) {
      for (let modifier of group.modifiers) {
        if (modifier.count === 1) setSelectedModifier(modifier.uuid);
      }
    }
  }, [group.type, group.modifiers]);

  const handelClickOnModifier = (params) => {
    if (group.type === 1) {
      setModifierCount(selectedModifier, 0);
      setModifierCount(params.modifierUUID, 1);
    }
    if (group.type === 2) {
      increaseCount(params);
    }
  };

  const increaseCount = (params) => {
    if (group.max !== 0 && selectedModifiersCount === group.max) return null;
    if (params.max !== 0) {
      params.max <= params.count
        ? setModifierCount(params.modifierUUID, params.max)
        : setModifierCount(params.modifierUUID, params.count + 1);
    } else
      setModifierCount(params.modifierUUID, parseInt(params.count || 0) + 1);
  };
  const decreaseCount = (params) => {
    //if(group.min !== 0 && selectedModifiersCount === group.min) return null
    if (params.min !== 0) {
      params.min >= params.count
        ? setModifierCount(params.modifierUUID, params.min)
        : setModifierCount(params.modifierUUID, params.count - 1);
    } else {
      setModifierCount(params.modifierUUID, parseInt(params.count || 1) - 1);
      if (params?.min > 0) {
        setShakeIt(true);
        setTimeout(() => {
          setShakeIt(false);
        }, 500);
      }
    }
  };

  const resetCount = (params) => {
    setModifierCount(params.modifierUUID, params.min == null ? 0 : params.min);
  };

  const getSelectedCount = () => {
    let selectedCount = 0;

    for (let i = 0; i < group.modifiers.length; i++) {
      selectedCount +=
        group.modifiers[i].count != null ? group.modifiers[i].count : 0;
    }
    return selectedCount;
  };
  if (!group?.availability?.visible) return null;

  if (group?.attributes?.className?.includes("type1"))
    return (
      <>
        <ModifierV4Type1 group={group} setCount={setModifierCount} />
      </>
    );
  if (group?.type === 1) {
    return (
      <div>
        <div className="text-lg font-bold">{getName(group.name)}</div>
        {getName(group.description) !== "" && (
          <div className="text-sm font-light opacity-50">
            {getName(group.description)}
          </div>
        )}
        {group?.min > 0 && (
          <div className="text-sm font-normal text-primary">
            {getValue("app_modifierGroupDesc_v1_min_part1")} {group.min}{" "}
            {getValue("app_modifierGroupDesc_v1_min_part2")}
          </div>
        )}
        <div className="mt-2">
          <RadioGroup
            value={group?.modifiers?.find((mod) => mod.count > 0)?.uuid}
            className="w-full"
            onValueChange={(value) => {
              let modifier = group?.modifiers?.find(
                (mod) => mod.uuid === value
              );
              handelClickOnModifier({
                modifierUUID: modifier.uuid,
                count: modifier.count,
              });
            }}
            classNames={{
              wrapper: "w-full",
            }}
          >
            {group?.modifiers
              ?.filter((modifier) => modifier?.availability?.visible === 1)
              ?.map((modifier) => {
                //console.log(modifier);
                return (
                  <Radio
                    classNames={{
                      base: cn(
                        "inline-flex m-0 bg-[var(--main-bg-color)] hover:bg-[var(--main-bg-color)] items-center justify-between max-w-full",
                        "flex-row-reverse  cursor-pointer rounded-[var(--general-border-radius)] gap-4 p-4 border-2 border-[var(--main-border-color)]",
                        "data-[selected=true]:border-primary"
                      ),
                      wrapper: cn(
                        "group-data-[selected=true]:border-primary border-[var(--main-border-color)]"
                      ),
                    }}
                    className="w-full"
                    key={modifier?.uuid}
                    value={modifier?.uuid}
                  >
                    <div>
                      <div>{getName(modifier?.name)}</div>
                      {modifier.price > 0 && (
                        <div>+{parseInt(modifier.price)} Ft</div>
                      )}
                    </div>
                    <div className="text-sm opacity-60 italic">
                      {getName(modifier?.description)}
                    </div>
                  </Radio>
                );
              })}
          </RadioGroup>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`${
          firstInvalid === group?.uuid && group?.valid === false
            ? "animate-pulse"
            : ""
        }  orderItemModifierGroup-root type-${group.type} ${
          group.valid ? "valid" : "invalid"
        } ${tryedToAdd && !group?.valid ? "outline" : ""}`}
        id={group?.uuid}
        name={group?.uuid}
      >
        <div className={` ${shakeIt ? "shake" : ""}`}>
          <div className="orderItemModifierGroup-name font-large">
            {getName(group.name)}
          </div>
          {getName(group.description) !== "" && (
            <div className="orderItemModifierGroup-desc font-smallest">
              {getName(group.description)}
            </div>
          )}
          {group?.min > 0 && (
            <div className="orderItemModifierGroup-desc flex flex-row gap-1 font-smallest">
              {getValue("app_modifierGroupDesc_v1_min_part1")} {group.min}{" "}
              {getValue("app_modifierGroupDesc_v1_min_part2")}
            </div>
          )}
        </div>

        {group.type === 2 && (
          <>
            {group.min !== 0 && selectedModifiersCount < group.min && (
              <div className="orderItemModifierGroup-desc font-smallest">{`${getValue(
                "app_modifierGroupDesc_v1_part1"
              )} ${group.min - selectedModifiersCount} ${getValue(
                "app_modifierGroupDesc_v1_part2"
              )}`}</div>
            )}
            {group.min !== 0 &&
              group.max !== 0 &&
              selectedModifiersCount >= group.min &&
              selectedModifiersCount < group.max && (
                <div className="orderItemModifierGroup-desc font-smallest">{`${getValue(
                  "app_modifierGroupDesc_v2_part1"
                )} ${
                  group.max < 11 ? group.max - selectedModifiersCount : ""
                } ${getValue("app_modifierGroupDesc_v2_part2")}`}</div>
              )}
            {group.max !== 0 && selectedModifiersCount === group.max && (
              <div className="orderItemModifierGroup-desc font-smallest">{`${getValue(
                "app_modifierGroupDesc_v3"
              )}`}</div>
            )}
          </>
        )}
        <div className="flex flex-col gap-2 w-full">
          {group.modifiers
            ?.filter((modifier) => modifier?.availability?.visible === 1)

            ?.map((modifier) => {
              if (modifier?.max === 1) {
                return (
                  <Checkbox
                    key={modifier?.uuid}
                    aria-label={modifier?.uuid}
                    classNames={{
                      base: cn(
                        "flex w-full max-w-none bg-content1 m-0 flex-row-reverse	",
                        "items-center justify-start",
                        "cursor-pointer rounded-[var(--general-border-radius)] gap-2 p-4 border-2 border-primary",
                        "data-[selected=true]:border-primary"
                      ),
                      label: "w-full",
                      wrapper: "border-2 border-primary",
                    }}
                    radius={
                      appParams?.styles?.borderRadius?.general === "0px"
                        ? "none"
                        : ""
                    }
                    isSelected={modifier?.count > 0}
                    onValueChange={(value) => {
                      if (value === true) {
                        increaseCount({
                          modifierUUID: modifier.uuid,
                          count: modifier.count,
                          min: modifier.min,
                          max: modifier.max,
                        });
                      } else {
                        decreaseCount({
                          modifierUUID: modifier.uuid,
                          count: modifier.count,
                          min: modifier.min,
                          max: modifier.max,
                        });
                      }
                    }}
                  >
                    <div className="flex flex-row gap-2 justify-between">
                      <div
                        className={`orderItemModifierGroup-modifier-name  ${
                          appParams?.styles?.fonts?.capitalize
                            ? "font-bold !uppercase"
                            : ""
                        }`}
                      >
                        {getName(modifier.name)}
                      </div>
                      <div
                        className={`orderItemModifierGroup-modifier-price  ${
                          appParams?.styles?.fonts?.capitalize
                            ? "font-bold !uppercase"
                            : ""
                        }`}
                      >
                        +{parseInt(modifier.price)} Ft
                      </div>
                    </div>
                  </Checkbox>
                );
              }
              return (
                <div
                  key={modifier.uuid}
                  onClick={() => {
                    if (group?.type === 2) {
                      if (modifier.count === 0) {
                        increaseCount({
                          modifierUUID: modifier.uuid,
                          count: modifier.count,
                          min: modifier.min,
                          max: modifier.max,
                        });
                      } else {
                        decreaseCount({
                          modifierUUID: modifier.uuid,
                          count: modifier.count,
                          min: modifier.min,
                          max: modifier.max,
                        });
                      }
                    }
                  }}
                  className={`orderItemModifierGroup-modifier-root ${
                    appParams?.styles?.fonts?.capitalize
                      ? "font-extrabold uppercase"
                      : ""
                  } font-small ${
                    modifier.availability.available === 0 ||
                    modifier.availability.visible === 0
                      ? "dnone"
                      : ""
                  } ${modifier.count > 0 ? "selected" : "not-selected"}`}
                >
                  {modifier.attributes?.appImage != null && (
                    <OEAsset
                      uuid={modifier.attributes?.appImage}
                      size="l"
                      addClass="modifier-img"
                    />
                  )}
                  <div className="flex flex-col gap-0 items-start grow ">
                    <div className={`orderItemModifierGroup-modifier-name`}>
                      {getName(modifier.name)}
                    </div>
                    <div className="orderItemModifierGroup-modifier-price !ml-2">
                      +{parseInt(modifier.price)} Ft
                    </div>
                  </div>
                  {group.type === 2 && (
                    <>
                      <div className="flex justify-end items-end ml-2">
                        {modifier?.max === 1 ? (
                          <></>
                        ) : (
                          <ButtonGroup className="gap-1">
                            <Button
                              size="sm"
                              isIconOnly
                              color="primary"
                              variant="flat"
                              className="!rounded-[var(--order-button-border-radius)]"
                              onClick={() =>
                                decreaseCount({
                                  modifierUUID: modifier.uuid,
                                  count: modifier.count,
                                  min: modifier.min,
                                  max: modifier.max,
                                })
                              }
                            >
                              <LoadSvg name="minusIcon" />
                            </Button>
                            <Button
                              size="sm"
                              isIconOnly
                              color="primary"
                              variant="bordered"
                              className="!rounded-[var(--order-button-border-radius)]"
                            >
                              {modifier?.count}
                            </Button>
                            <Button
                              size="sm"
                              isIconOnly
                              color="primary"
                              variant="flat"
                              className="!rounded-[var(--order-button-border-radius)]"
                              onClick={() =>
                                increaseCount({
                                  modifierUUID: modifier.uuid,
                                  count: modifier.count,
                                  min: modifier.min,
                                  max: modifier.max,
                                })
                              }
                            >
                              <LoadSvg name="plusIcon" />
                            </Button>
                          </ButtonGroup>
                        )}
                      </div>
                      {/* <ChangeCountButton
                  increaseCount={increaseCount}
                  decreaseCount={decreaseCount}
                  max={modifier.max}
                  min={modifier.min}
                  params={{
                    modifierUUID: modifier.uuid,
                    count: modifier.count == null ? modifier.min : modifier.count,
                    min: modifier.min,
                    max: modifier.max,
                  }}
                  count={modifier.count == null ? modifier.min : modifier.count}
                  type={modifier.max === 1 ? 'multiple' : 'stackableMultiple'}
                  reset={resetCount}
                /> */}
                    </>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default ModifierGroupSelector;
