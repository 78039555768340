import CustomInput from "components/CustomInput";
import useStorage from "hooks/useStorage";
import { useEffect, useState } from "react";
import { useDictionary } from "@adamlip/okeoke.client";
import useBasket from "hooks/useBasket";

export default function CarPickUp({ setCarPickUp }) {
  const { getSV, setSV } = useStorage();
  const { getValue } = useDictionary();
  const { setNotes } = useBasket();

  const [carID, setCarID] = useState("");

  const handleChange = (value) => {
    setCarID(value);
    setSV("carID", value);
    setCarPickUp(value);
    setNotes(`rendszám: ${value?.slice(0, 100)}`);
  };

  useEffect(() => {
    let id = getSV("carID");
    if (id) {
      setCarID(id);
      setCarPickUp(id);
      setNotes(`rendszám: ${id?.slice(0, 100)}`);
    }
  }, []);

  return (
    <div className="">
      <CustomInput
        label={getValue("app_v2_basket_slider_take_away_car_id")}
        onChange={(e) => handleChange(e.target.value)}
        value={carID}
      />
    </div>
  );
}
