import axios from "axios";
import appConfig from "appConfig";

const axiosInstance = axios.create({
  baseURL: appConfig.baseUrl || window.location.origin,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization:
      "Bearer " +
      localStorage[
        `brandAppV2-${
          appConfig.baseUrl == null ? "prod" : appConfig.baseUrl
        }-apiToken`
      ],
  },
});

export const AuthAPI = {
  refreshJWTToken: function () {
    return axiosInstance.request({
      method: "POST",
      url: `/api/v1/auth/refreshJWTToken`,
      data: {
        playerId: localStorage["playerId"],
        deviceUUID: localStorage["deviceUUID"],
      },
    });
  },
  generatePin: function (email) {
    return axiosInstance.request({
      method: "POST",
      url: `/api/v1/auth/generateUserPin`,
      data: { email },
    });
  },
  validatePin: function (email, pinCode) {
    return axiosInstance.request({
      method: "POST",
      url: `/api/v1/auth/validateUserPin`,
      data: {
        email,
        pinCode,
        playerId: localStorage["playerId"],
        deviceUUID: localStorage["deviceUUID"],
      },
    });
  },
  loginAnonymous: function (uuid) {
    return axiosInstance.request({
      method: "POST",
      url: `/api/v1/auth/loginAnonymous`,
      data: {
        uuid,
      },
    });
  },
};
