import { useClient, useDictionary } from "@adamlip/okeoke.client";
import { Listbox, ListboxItem } from "@nextui-org/react";
import SelectLocationModal from "components/SelectLocationModal";
import useBasket from "hooks/useBasket";
import { useState, useContext, useEffect } from "react";
import { AppParamsContext } from "contexts/AppParamsContext";

export default function LocationCard() {
  const { getValue } = useDictionary();
  const { locations } = useClient();
  const { appParams } = useContext(AppParamsContext);
  const { orderLocation, savedTable, requestedReadyTime } = useBasket();
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState("");

  const handleAction = (key) => {
    if (key === "location") {
      setLocationModalOpen(true);
    }
  };

  useEffect(() => {
    switch (appParams?.order?.locationSelectorType) {
      case "2":
        setSelectedStyle("border-2 border-[var(--main-text-color)]");
        break;
      default:
        setSelectedStyle("shadow-sm bg-[var(--main-secondary-bg-color)]");
    }
  }, [appParams]);

  return (
    <div className="px-3 w-full mb-4">
      <SelectLocationModal
        isOpen={locationModalOpen}
        setIsOpen={setLocationModalOpen}
        isDismissable={true}
      />
      <Listbox
        aria-label="User Menu"
        onAction={(key) => handleAction(key)}
        className={`p-0 gap-0 divide-y mt-2  divide-[var(--main-border-color)] overflow-visible ${selectedStyle} rounded-[var(--general-border-radius)]`}
        itemClasses={{
          base: "px-3 first:rounded-t-[var(--general-border-radius)] last:rounded-b-[var(--general-border-radius)] rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80",
          title: appParams?.styles?.fonts?.capitalize
            ? "font-extrabold uppercase"
            : "",
        }}
      >
        <ListboxItem
          aria-label="location"
          key="location"
          textValue={getValue("app_v2_basket_slider_selected_location")}
          endContent={
            <svg
              aria-hidden="true"
              fill="none"
              focusable="false"
              height="1em"
              role="presentation"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="1em"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          }
          className="group h-auto py-3"
          startContent={
            <div className="bg-success/10 text-success flex items-center rounded-small justify-center w-7 h-7">
              <svg
                width={"100%"}
                height={"100%"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 17"
              >
                <path
                  stroke="#101828"
                  strokeWidth="1"
                  className={"stroke-success"}
                  d="M2 7.833v3c0 1.886 0 2.829.586 3.415.586.585 1.528.585 3.414.585h4c1.886 0 2.828 0 3.414-.585.586-.586.586-1.529.586-3.415v-3"
                />
                <path
                  stroke="#101828"
                  strokeLinecap="round"
                  strokeWidth="1"
                  className={"stroke-success"}
                  d="M10 11.833c-.456.405-1.182.667-2 .667-.818 0-1.544-.262-2-.667"
                />
                <path
                  fill="#101828"
                  className={"fill-success"}
                  d="m5.313 6.5.483-.13a.5.5 0 0 0-.983.13h.5Zm5.373 0h.5a.5.5 0 0 0-.983-.13l.483.13Zm-5.873 0C4.813 7.325 4.14 8 3.3 8v1a2.507 2.507 0 0 0 2.514-2.5h-1ZM3.3 8c-.653 0-1.209-.41-1.422-.98l-.937.348A2.515 2.515 0 0 0 3.3 9V8Zm10.823-.98c-.213.57-.768.98-1.421.98v1c1.08 0 2.003-.678 2.358-1.632l-.937-.349ZM12.701 8c-.84 0-1.515-.675-1.515-1.5h-1c0 1.384 1.13 2.5 2.514 2.5V8ZM8 8c-1.073 0-1.956-.704-2.204-1.63l-.966.26C5.2 8.003 6.487 9 8 9V8Zm2.203-1.63C9.955 7.297 9.073 8 8 8v1c1.512 0 2.8-.996 3.169-2.37l-.966-.26ZM2.07 6.136l.643-1.117-.867-.498-.642 1.116.866.499Zm12.723-.5-.642-1.115-.866.498.642 1.117.866-.5ZM3.944 2.668h8.112v-1H3.943v1Zm9.928.806a1.811 1.811 0 0 0-1.816-1.806v1a.81.81 0 0 1 .816.806h1Zm.28 1.047a2.098 2.098 0 0 1-.28-1.047h-1c0 .542.143 1.075.414 1.545l.866-.498ZM3.127 3.473a.81.81 0 0 1 .816-.806v-1c-1 0-1.816.805-1.816 1.806h1ZM15.06 7.368c.029-.077.07-.183.091-.318.021-.135.02-.274.006-.439l-.997.083c.011.13.007.18.003.2a.65.65 0 0 1-.04.125l.937.35Zm-1.131-1.233c.183.318.217.388.231.56l.997-.084c-.035-.418-.182-.664-.362-.975l-.866.499ZM2.714 5.018c.27-.47.413-1.003.413-1.545h-1c0 .367-.096.728-.28 1.047l.867.498ZM1.877 7.02a.645.645 0 0 1-.04-.124.892.892 0 0 1 .003-.2L.843 6.61c-.014.165-.015.304.006.439.021.135.063.241.091.318l.937-.349Zm-.672-1.383c-.18.311-.327.557-.362.975l.997.083c.014-.171.048-.24.231-.56l-.866-.498Z"
                />
              </svg>
            </div>
          }
        >
          <div className="flex flex-col gap-0">
            <div className="">
              {getValue("app_v2_basket_slider_selected_location")}
            </div>
            <div className="font-bold text-base">
              {locations?.[orderLocation.uuid]?.name}
            </div>
            <div className="text-sm font-light opacity-50">{`${
              locations?.[orderLocation.uuid]?.address?.city || ""
            } ${locations?.[orderLocation.uuid]?.address?.zip || ""} ${
              locations?.[orderLocation.uuid]?.address?.street || ""
            } ${
              locations?.[orderLocation.uuid]?.address?.streetNumber || ""
            }`}</div>
            {savedTable?.name != null && savedTable?.name.length > 0 && (
              <>
                <div className="">
                  {getValue("app_v2_basket_slider_selected_table")}
                </div>
                <div className="font-bold text-base">{savedTable?.name}</div>
              </>
            )}
          </div>
        </ListboxItem>
        {appParams?.basketModal?.visibleBlocks?.readyTime && (
          <ListboxItem
            aria-label="readyTime"
            key="readyTime"
            textValue={getValue("app_v2_pickupTime")}
            endContent={
              <svg
                aria-hidden="true"
                fill="none"
                focusable="false"
                height="1em"
                role="presentation"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                width="1em"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            }
            className="group h-auto py-3"
            startContent={
              <div className="bg-primary/10 text-success flex items-center rounded-small justify-center w-7 h-7">
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 81 80"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-primary"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M35.2835 21.4779C37.736 21.8283 39.4878 23.9304 39.1374 26.7332C39.1374 28.8353 37.0353 30.9375 34.2325 30.5871C31.78 30.2368 30.0282 28.1346 30.3786 25.3318C30.3786 23.2297 32.4807 21.1276 35.2835 21.4779Z"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    className="fill-primary"
                    d="M29.3286 37.2438C28.9783 37.2438 28.6279 37.2438 28.2775 37.5942C27.2265 37.9446 26.1754 38.2949 24.774 37.9446C23.0223 37.2439 21.2705 35.4921 20.5698 34.0907C19.8691 32.6893 21.9712 30.9375 23.0223 31.9886C23.723 32.3389 24.0733 33.0396 24.4237 33.7403C25.4747 34.7914 26.5258 35.1417 27.9272 34.0907C27.9272 34.0907 28.2775 34.0907 28.2775 33.7403L29.3286 32.6893C29.679 32.3389 30.0293 31.9886 31.0804 31.9886C32.8321 31.9886 34.5839 31.9886 36.3357 31.9886C38.0874 31.9886 39.4888 31.9886 40.8902 32.6893C41.9413 33.39 42.642 34.441 43.3427 35.4921C45.0945 39.346 45.0945 45.302 45.0945 49.5062C45.0945 51.6083 42.9924 51.6083 42.2917 50.9076C41.5909 50.2069 41.5909 49.5062 41.5909 48.8055C41.5909 46.353 41.5909 43.5502 41.2406 41.0977C41.2406 40.0467 40.5399 37.5942 40.1895 36.8935C40.1895 37.9446 40.1895 39.346 40.1895 40.7474V58.6154H35.2846C35.2846 57.9147 35.2846 57.2139 35.2846 56.5132C35.2846 54.7615 35.2846 50.9076 35.2846 49.1558H34.2335C34.2335 50.2069 34.2335 51.6083 34.2335 53.0097V58.6154H29.3286V37.2438Z"
                    fill="black"
                  />
                  <path
                    className="fill-primary"
                    d="M28.6276 42.499H23.7227V44.2507H28.6276V42.499Z"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    className="fill-primary"
                    d="M55.2527 21.478C57.7052 21.8283 59.1066 23.9305 58.7563 26.0326C58.4059 28.1347 56.6541 29.8865 54.2017 29.5361C52.0996 29.1857 50.3478 27.434 50.6982 24.9815C50.6982 23.9305 51.3989 22.8794 52.0996 22.5291C52.8003 21.8283 53.8513 21.1276 55.2527 21.478ZM54.9024 22.5291C53.1506 22.1787 51.7492 23.5801 51.3989 24.9815C51.0485 26.7333 52.4499 28.1347 53.8513 28.485C55.6031 28.8354 57.0045 27.434 57.3549 26.0326C57.7052 23.9305 56.6541 22.5291 54.9024 22.5291ZM45.7932 44.6013H60.1577V42.8495H46.8443V40.7474H52.0996C52.4499 40.7474 52.8003 40.397 52.8003 40.0467L51.0485 31.9886C51.7492 31.9886 52.4499 31.9886 52.8003 31.9886C52.8003 32.6893 53.1506 33.7403 53.501 34.441L54.9024 40.397C54.9024 40.7474 54.9024 41.4481 55.6031 41.4481C55.6031 40.7474 55.6031 40.397 55.6031 39.6963L56.3038 36.1928C56.6541 35.4921 56.6541 34.441 57.0045 33.7403C57.0045 33.39 57.3549 31.6382 57.7052 31.2879C58.0556 31.2879 59.1066 31.6382 59.457 31.2879C59.8073 31.2879 59.8073 30.5872 59.457 30.5872C59.457 30.5872 59.1066 30.5872 58.7563 30.2368C56.6541 30.5872 57.0045 30.5872 56.6541 32.3389C56.3038 33.39 56.3038 34.441 55.9534 35.4921C55.6031 35.8424 55.6031 36.5432 55.6031 36.8935L55.2527 38.2949V37.9446C55.2527 37.5942 54.9024 36.8935 54.9024 36.5432L53.8513 31.2879C53.8513 30.9375 53.8513 30.5872 53.1506 30.5872C52.4499 30.5872 51.7492 30.5872 51.0485 30.9375C51.0485 30.5872 50.3478 30.2368 49.9974 30.2368H38.0855C37.3848 30.2368 37.3848 30.5872 37.3848 30.9375V31.2879C38.4358 31.2879 40.1876 31.2879 41.2386 31.9886C41.589 32.3389 42.2897 32.6893 42.64 33.39C42.9904 34.0907 43.6911 34.7914 43.6911 35.1417C44.7422 37.2439 45.0925 39.6963 45.4429 42.1488C45.7932 43.1999 45.7932 43.9006 45.7932 44.6013Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <div className="flex flex-col gap-0">
              <div className="">{getValue("app_v2_pickupTime")}</div>
              <div className="font-bold text-base">
                {requestedReadyTime
                  ? moment(requestedReadyTime).format("YYYY.MM.DD HH:mm:SS")
                  : getValue("app_v2_pickUp_soonAsPossible")}
              </div>
            </div>
          </ListboxItem>
        )}
      </Listbox>
    </div>
  );
}
