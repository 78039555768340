import { useDictionary } from "@adamlip/okeoke.client";
import useBasket from "hooks/useBasket";

export default function Delivery() {
  const { getValue } = useDictionary();
  const { deliveryCost } = useBasket();

  if (deliveryCost == null || deliveryCost === 0) return null;

  return (
    <div className="basketItem-root service-fee">
      <div className="basketItem-name-root">
        <div className="basketItem-name font-small">
          {`${getValue("app_v2_delivery_cost")}`}
        </div>
      </div>
      <div className="basketItem-totalPrice font-small">
        <div className="submitted-orderItem-price">{deliveryCost} Ft</div>
      </div>
    </div>
  );
}
