import { useDictionary } from "@adamlip/okeoke.client";

export default function Header({ loginType, emailSaved }) {
  const { getValue } = useDictionary();

  if (loginType === "reg")
    return (
      <h3>
        {emailSaved
          ? getValue("app_v2_login_typePin")
          : getValue("app_v2_login_typeEmail")}
      </h3>
    );

  if (loginType === "login")
    return <h3>{getValue("app_v2_login_type_email")}</h3>;
}
