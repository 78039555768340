import {useDictionary} from '@adamlip/okeoke.client';
import {ScrollShadow} from '@nextui-org/react';
import WhereAndLocation from './WhereAndLocation';
import {useContext, useEffect} from 'react';
import Footer from './Footer';
import {AppParamsContext} from 'contexts/AppParamsContext';
import useBasket from 'hooks/useBasket';

export default function TakeAwayDeliverySlide({goToNext, setAllowSlide, allowSlide, delivery, setDelivery, selectedTab, setSelectedTab, carPickUp, setCarPickUp}) {
  const {getValue} = useDictionary();
  const {appParams} = useContext(AppParamsContext);
  const {notes, deliveryInfo} = useBasket();

  useEffect(() => {
    if (delivery) {
      if (deliveryInfo === null) {
        setAllowSlide(false);
      } else {
        setAllowSlide(true);
      }
    }
    if (selectedTab === 'carPickUp') {
      if (selectedTab === 'carPickUp' && (notes?.length || '') < 12) {
        setAllowSlide(false);
      } else {
        setAllowSlide(true);
      }
    }
    if (!delivery && selectedTab !== 'carPickUp') {
      setAllowSlide(true);
    }
  }, [selectedTab, notes, delivery, deliveryInfo]);
  return (
    <div className='h-full flex flex-col gap-2 grow pb-10'>
      <div className={`${appParams?.styles?.fonts?.capitalize ? 'font-extrabold uppercase' : ''} basket-slide-header  font-bold text-xl`}>
        {getValue('app_v2_basket_slider_take_away')}
      </div>
      <div className='grow overflow-hidden'>
        <ScrollShadow className='h-full'>
          <WhereAndLocation setDelivery={setDelivery} setCarPickUp={setCarPickUp} carPickUp={carPickUp} onSelectTab={(tab) => setSelectedTab(tab)} />
        </ScrollShadow>
      </div>
      <Footer delivery={delivery} goToNext={goToNext} carPickUp={carPickUp} selectedTab={selectedTab} />
    </div>
  );
}
