import CustomInput from "components/CustomInput";
import useBasket from "hooks/useBasket";
import { useDictionary } from "@adamlip/okeoke.client";

export default function DeliveryInfo({ setInOrder, selectedDeliveryAddress }) {
  const { deliveryInfo } = useBasket();
  const { getValue } = useDictionary();

  const deliveryData = setInOrder ? deliveryInfo : selectedDeliveryAddress;

  if (!deliveryData) return null;

  return (
    <div className="flex flex-col gap-2">
      {/* <CustomInput
          isDisabled
          label={getValue("app_v2_address_label")}
          value={deliveryInfo?.addressLabel}
        /> */}
      <CustomInput
        isDisabled
        label={getValue("app_v2_address_name")}
        value={deliveryData?.name || ""}
      />
      <CustomInput
        isDisabled
        label={getValue("app_v2_address")}
        value={`${deliveryData?.state || ""} ${deliveryData?.zip || ""} ${
          deliveryData?.city || ""
        } ${deliveryData?.street || ""} ${deliveryData?.streetNumber || ""}`}
      />
      <CustomInput
        isDisabled
        label={getValue("app_v2_address_phone")}
        value={deliveryData?.telNumber || ""}
      />
    </div>
  );
}
