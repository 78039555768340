import { Button } from "@nextui-org/react";
import { useDictionary } from "@adamlip/okeoke.client";
import useBasket from "hooks/useBasket";
import { AppParamsContext } from "contexts/AppParamsContext";
import { useContext } from "react";
import toast from "react-hot-toast";

export default function Footer({ delivery, goToNext, carPickUp, selectedTab }) {
  const { getValue } = useDictionary();
  const { appParams } = useContext(AppParamsContext);
  const { orderItems, deliveryInfo, takeAway, notes } = useBasket();

  const handleGoToNext = () => {
    if (
      appParams?.basketModal?.whereWhen?.carPickUp &&
      selectedTab === "carPickUp"
    ) {
      if (notes?.length > 12) {
        goToNext();
      } else {
        toast.error(getValue("app_v2_basket_slider_car_id_missing"));
      }
    } else {
      goToNext();
    }
  };

  return (
    <div className="basket-slide-footer">
      <Button
        size="lg"
        fullWidth
        color="primary"
        className={`${
          appParams?.styles?.fonts?.capitalize ? "font-extrabold uppercase" : ""
        } rounded-[var(--order-button-border-radius)]`}
        onClick={handleGoToNext}
        isDisabled={
          (delivery && deliveryInfo === null) ||
          orderItems?.filter((item) => item?.count > 0)?.length === 0
        }
      >
        {delivery && deliveryInfo === null
          ? getValue("app_v2_basket_slider_select_deliveryAddress")
          : `${getValue(
              `app_v2_basket_slider_take_away_next_${
                takeAway ? "take_away" : "eat_in"
              }`
            )}`}
        {/* {carPickUp == null || carPickUp?.length < 1
          ? getValue("app_v2_basket_slider_select_car_id")
          : `${getValue(
              `app_v2_basket_slider_take_away_next_car`
            )}  - ${carPickUp}`} */}
        {carPickUp?.length > 0 && " - " + carPickUp}
      </Button>
    </div>
  );
}
