import SelectTable from "views/SelectTable";
import { Modal, ModalBody, ModalContent } from "@nextui-org/react";

export default function SelectTableModal({ isOpen, setIsOpen, onClose }) {
  const handleSelect = (res) => {
    if (typeof onClose === "function") onClose(res);
    setIsOpen(!isOpen);
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => {
        handleSelect();
      }}
      size={"2xl"}
      radius="none"
      className="bg-background"
      classNames={{
        wrapper: "sm:items-end",
        closeButton:
          "z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]",
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            {/* <ModalHeader></ModalHeader> */}
            <ModalBody className="px-2 overflow-hidden">
              <SelectTable onSelect={(res) => handleSelect(res)} />
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
