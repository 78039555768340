import { useClient } from "@adamlip/okeoke.client";
import Tracker from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";
import useBasket from "hooks/useBasket";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "./UserContext";

const OpenReplayContext = createContext();

const OpenReplayContextProvider = ({ children }) => {
  const { orderLocation } = useBasket();
  const tracker = useRef(null);
  const { userData } = useContext(UserContext);
  const { brandID, brandName } = useClient();
  const [trackerInstalled, setTrackerInstalled] = useState(false);

  const addEvent = (eventName, msg) => {
    if (typeof tracker.current?.event === "function" && trackerInstalled)
      tracker.current.event(eventName, msg);
  };

  const addIssue = (issueName, msg) => {
    if (typeof tracker.current?.issue === "function" && trackerInstalled)
      tracker.current.issue(issueName, msg);
  };

  useEffect(() => {
    if (userData != null && tracker.current != null && trackerInstalled)
      tracker.current.setUserID(userData.email);
  }, [userData, trackerInstalled]);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development" && brandID != null) {
      tracker.current = new Tracker({
        projectKey: "91jWTH6mHQP5PtY5AWTU",
        respectDoNotTrack: true,
      });
      tracker.current.use(
        trackerAssist({
          callConfirm: "Will you allow the agent to call you?",
          controlConfirm: "Will you allow the agent to control your device?",
          config: {},
          onAgentConnect: () => () => {},
          onCallStart: () => () => {},
          onRemoteControlStart: () => () => {},
        })
      );
      tracker.current.start({
        metadata: {
          brandID: brandID,
          brandName: brandName,
        },
      });
      setTrackerInstalled(true);
    } else {
      setTrackerInstalled(false);
    }
  }, [brandID]);

  useEffect(() => {
    if (
      orderLocation?.name != null &&
      tracker.current !== null &&
      trackerInstalled
    ) {
      tracker?.current?.setMetadata("location", orderLocation?.name);
    }
  }, [orderLocation, trackerInstalled]);
  return (
    <OpenReplayContext.Provider
      value={{ tracker, addEvent, addIssue, trackerInstalled }}
    >
      {children}
    </OpenReplayContext.Provider>
  );
};

export { OpenReplayContext, OpenReplayContextProvider };
