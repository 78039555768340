import { useDictionary } from "@adamlip/okeoke.client";
import useBasket from "hooks/useBasket";

export default function ServiceFee() {
  const { getValue } = useDictionary();
  const { sumDiscountServiceFee, serviceFee } = useBasket();

  if (sumDiscountServiceFee == null || sumDiscountServiceFee === 0) return null;

  return (
    <div className="basketItem-root service-fee">
      <div className="basketItem-name-root">
        <div className="basketItem-name font-small">
          {serviceFee?.type === 1 &&
            `${getValue("app_v2_serviceFee")} (${serviceFee?.percent}%)`}
          {serviceFee?.type === 2 && getValue("app_v2_reverseServiceFee")}
        </div>
      </div>
      <div className="basketItem-totalPrice font-small">
        {serviceFee?.type === 1 && (
          <div className="submitted-orderItem-price">
            {sumDiscountServiceFee} Ft
          </div>
        )}
        {serviceFee?.type === 2 && (
          <div className="submitted-orderItem-price">
            {serviceFee?.percent} %
          </div>
        )}
      </div>
    </div>
  );
}
