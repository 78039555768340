import {LeadingActions, SwipeAction, SwipeableList, SwipeableListItem} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import ItemWrapper from '../ItemWrapper';
import ItemImage from '../ItemImage';
import ItemHeader from '../ItemHeader';
import {useItem, useDictionary} from '@adamlip/okeoke.client';

export default function SwipeableItem({assembledItem, banned, setItemModalOpen, addItemToBasket, readOnly}) {
  const {assembledItem: hookAssembledItem} = useItem(assembledItem.uuid);
  const {getValue} = useDictionary();

  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction
        onClick={() => {
          if (hookAssembledItem?.valid && hookAssembledItem?.modifierGroups?.length === 0 && hookAssembledItem?.sizes?.length === 1) {
            console.log('add to basket');
            addItemToBasket();
            return;
          } else {
            if (!banned) setItemModalOpen(true);
          }
        }}>
        <div className='h-full right-[-10px] bg-primary  flex items-center justify-center p-2 text-[var(--button-text-color)]'>{getValue('app_v2_addToBasket')}</div>
      </SwipeAction>
    </LeadingActions>
  );

  return (
    <SwipeableList fullSwipe className='w-full'>
      <SwipeableListItem leadingActions={leadingActions()} className='w-full'>
        <ItemWrapper assembledItem={assembledItem} banned={banned} onClick={() => setItemModalOpen(true)} className={`orderItemListItem-root pl-2 hidden-content appear w-full`}>
          <ItemHeader
            assembledItem={assembledItem}
            banned={banned}
            // nameClass="text-sm"
            readOnly={readOnly}
          />
          <ItemImage assembledItem={assembledItem} />
        </ItemWrapper>
      </SwipeableListItem>
    </SwipeableList>
  );
}
