import { useDictionary } from "@adamlip/okeoke.client";
import { Button, Select, SelectItem } from "@nextui-org/react";
import { UserContext } from "contexts/UserContext";
import useStorage from "hooks/useStorage";
import { useContext, useEffect, useState } from "react";
import AddressModal from "./AddressModal";
import useBasket from "hooks/useBasket";
import { AppParamsContext } from "contexts/AppParamsContext";

export default function InvoiceAddresses({ setAddressInOrder }) {
  const { invoiceAddresses } = useContext(UserContext);
  const { getValue } = useDictionary();
  const { setInvoiceInfo } = useBasket();
  const { getSV, setSV } = useStorage();
  const [newAddressModal, setNewAddressModal] = useState(false);
  const { appParams } = useContext(AppParamsContext);
  const [editAddressModalOpen, setEditAddressModalOpen] = useState(false);
  const [selectedAddressID, setSelectedAddressID] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    if (invoiceAddresses != null) {
      let obj = {};
      for (let address of invoiceAddresses) {
        obj[address.id] = address;
      }
      setSelectedAddress(obj?.[selectedAddressID]);
      if (selectedAddressID && setAddressInOrder) {
        setSV("selectedInvoiceID", selectedAddressID);
        setInvoiceInfo(obj?.[selectedAddressID]);
      }
    }
  }, [invoiceAddresses, selectedAddressID]);

  useEffect(() => {
    let id = getSV("selectedInvoiceID");
    if (id?.length > 0) {
      setSelectedAddressID(id);
    }
  }, []);

  return (
    <div
      className={`${
        appParams?.styles?.fonts?.capitalize ? "font-extrabold uppercase" : ""
      } flex flex-col gap-2`}
    >
      <AddressModal
        isOpen={editAddressModalOpen}
        setIsOpen={setEditAddressModalOpen}
        data={selectedAddress}
        onDelete={() => setSelectedAddressID(null)}
        onSave={setSelectedAddressID}
      />
      <AddressModal
        isOpen={newAddressModal}
        setIsOpen={setNewAddressModal}
        data={null}
        onSave={setSelectedAddressID}
      />
      {!setAddressInOrder && (
        <div className="text-lg">{getValue("app_v2_invoice_addresses")}</div>
      )}
      {invoiceAddresses?.length > 0 && (
        <Select
          aria-label="12"
          label={getValue("app_v2_address_selectAddress")}
          selectedKeys={
            selectedAddressID ? [selectedAddressID.toString()] || [] : []
          }
          onChange={(e) => setSelectedAddressID(e.target.value)}
          classNames={{
            trigger: `${
              selectedAddressID != null ? "" : "border-2 border-danger"
            } bg-[--var(input-bg-color)]`,
          }}
          disallowEmptySelection={true}
        >
          {invoiceAddresses?.map((address) => (
            <SelectItem
              key={address.id}
              textValue={address?.addressLabel}
              aria-label="34"
            >
              {address.addressLabel}
            </SelectItem>
          ))}
        </Select>
      )}
      <div className="flex flex-row gap-2 mt-2">
        {selectedAddressID != null && (
          <Button
            className={`${
              appParams?.styles?.fonts?.capitalize
                ? "font-extrabold uppercase"
                : ""
            } rounded-[var(--order-button-border-radius)]`}
            fullWidth
            color="secondary"
            onClick={() => {
              setEditAddressModalOpen(true);
            }}
          >
            {getValue("app_v2_address_edit")}
          </Button>
        )}
        <Button
          className={`${
            appParams?.styles?.fonts?.capitalize
              ? "font-extrabold uppercase"
              : ""
          } rounded-[var(--order-button-border-radius)]`}
          fullWidth
          color="primary"
          onClick={() => {
            setNewAddressModal(true);
          }}
        >
          {getValue("app_v2_address_addNew")}
        </Button>
      </div>
    </div>
  );
}
