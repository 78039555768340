import React, { useState, useEffect } from "react";
import { useDictionary } from "@adamlip/okeoke.client";
import TextField from "components/TextField";
import LoadSvg from "utils/LoadSvg-v3";
import { IBAN } from "utils/IBAN-v1";
import { Helpers } from "utils/Helpers-v1";
import useStorage from "hooks/useStorage";

const IBANMASK = "**99999 - 9999999 - 9999999 - 9999999";
const ACCOUNTMASK = "*9999999 - 99999999 - 99999999";

function RaiffeisenPayParams(props) {
  const {
    handleValidationDataChange,
    deleteValidationDataField,
    selected,
    setValid,
    agentID,
  } = props;

  const { getValue } = useDictionary();
  const { getSV, setSV, removeSV } = useStorage();

  const [rafiPayEmail, setRafiPayEmail] = useState(getSV("login_email"));
  const [rafiPayPhoneNumber, setRafiPayPhoneNumber] = useState("");
  const [rafiPayAccountNumber, setRafiPayAccountNumber] = useState("");
  const [selectedIdentifier, setSelectedIdentifier] = useState("");
  const [accountHelperText, setAccountHelperText] = useState("");
  const [accountHelperText2, setAccountHelperText2] = useState("");
  const [mask, setMask] = useState(ACCOUNTMASK);

  useEffect(() => {
    let accountNumber = getSV("rafiPayAccountNumber");
    if (accountNumber != null && accountNumber !== "") {
      try {
        let isIban = accountNumber[0].toLowerCase() === "h";
        isIban ? setMask(IBANMASK) : setMask(ACCOUNTMASK);
        handleChange({
          target: { name: "accountNumber", value: accountNumber },
        });
      } catch (e) {
        console.log(e);
      }
    }

    let email =
      getSV("rafiPayEmail") == null
        ? getSV("login_email")
        : getSV("rafiPayEmail");
    setSV("rafiPayEmail", email);
    setRafiPayEmail(email);
    setRafiPayPhoneNumber(getSV("rafiPayPhoneNumber") || "");

    if (getSV("rafiPayIdentifier") != null) {
      setSelectedIdentifier(getSV("rafiPayIdentifier"));
    } else {
      setSV("rafiPayIdentifier", "rafiPayEmail");
      setSelectedIdentifier("rafiPayEmail");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedIdentifier === "rafiPayEmail") {
      deleteValidationDataField("rafiPayPhoneNumber");
      deleteValidationDataField("rafiPayAccountNumber");
    }
    if (selectedIdentifier === "rafiPayPhoneNumber") {
      deleteValidationDataField("rafiPayEmail");
      deleteValidationDataField("rafiPayAccountNumber");
    }
    if (selectedIdentifier === "rafiPayAccountNumber") {
      deleteValidationDataField("rafiPayPhoneNumber");
      deleteValidationDataField("rafiPayEmail");
    }

    if (selectedIdentifier === "" && selected) {
      handleValidationDataChange(
        "identifier",
        "app_v2_paymentTypeError_noIdentifier"
      );
    } else {
      deleteValidationDataField("identifier");
      if (
        getSV(selectedIdentifier) != null &&
        getSV(selectedIdentifier) !== ""
      ) {
        deleteValidationDataField("emptyIdentifier");
      }
      if (getSV(selectedIdentifier) === "" && selected) {
        handleValidationDataChange(
          "emptyIdentifier",
          "app_v2_paymentTypeError_emptyIdentifier"
        );
      }
    }
    // eslint-disable-next-line
  }, [selectedIdentifier]);

  useEffect(() => {
    if (typeof setValid === "function") {
      setTimeout(() => {
        setValid(agentID, getSV(selectedIdentifier) === "");
      }, 300);
    }
    // eslint-disable-next-line
  }, [selectedIdentifier]);

  const handleChange = (e) => {
    if (e.target.name === "rafiPayEmail") {
      e.target.value === ""
        ? removeSV("rafiPayEmail")
        : setSV("rafiPayEmail", e.target.value);
      setRafiPayEmail(e.target.value);
    }
    if (e.target.name === "rafiPayPhoneNumber") {
      let number = Helpers.replaceAll(e.target.value, {
        _: "",
        " ": "",
        "-": "",
      });
      if (number.length === 12) {
        setSV("rafiPayPhoneNumber", number.slice(0, 3) + "-" + number.slice(3));
        deleteValidationDataField("rafiPayPhoneNumber");
        deleteValidationDataField("emptyIdentifier");
      } else {
        setSV("rafiPayPhoneNumber", "");
        handleValidationDataChange(
          "rafiPayPhoneNumber",
          getValue("app_v2_accountNumber_invalidPhone")
        );
      }
      setRafiPayPhoneNumber(e.target.value);
    }
    if (e.target.name === "rafiPayAccountNumber") {
      handleBankCode(e.target.value);
      setRafiPayAccountNumber(e.target.value.toUpperCase());
      handleMaskSetByAccountNumber(e.target.value);
      saveAccountNumber(e.target.value);
    }
  };

  const handleBankCode = (code) => {
    let validateBank = IBAN.validateBank(code);
    let msg = "";

    if (validateBank !== "") {
      validateBank === "NO"
        ? (msg = getValue("app_v2_accountNumber_rafiPayNO"))
        : (msg = getValue("app_v2_accountNumber_rafiPayOK"));
      setAccountHelperText2(
        `${msg} ${validateBank === "NO" ? "" : `(${validateBank})`}`
      );
    } else {
      setAccountHelperText2("");
    }
  };

  const handleMaskSetByAccountNumber = (accountNumber) => {
    let isIban =
      accountNumber != null &&
      accountNumber !== "" &&
      accountNumber[0].toLowerCase() === "h";
    isIban ? setMask(IBANMASK) : setMask(ACCOUNTMASK);
  };

  const saveAccountNumber = (accountNumber) => {
    let newValue = Helpers.replaceAll(accountNumber, {
      "-": "",
      _: "",
      " ": "",
    });

    if (newValue !== "" && newValue[0].toLowerCase() === "h") {
      if (newValue.length === 28) {
        let valid = IBAN.validate(newValue);
        if (valid) {
          setSV("rafiPayAccountNumber", newValue);
          setAccountHelperText(getValue("app_v2_accountNumber_validIban"));
          deleteValidationDataField("rafiPayAccountNumber");
          deleteValidationDataField("emptyIdentifier");
        } else {
          setAccountHelperText(getValue("app_v2_accountNumber_invalidIban"));
          handleValidationDataChange(
            "rafiPayAccountNumber",
            getValue("app_v2_accountNumber_invalidIban")
          );
        }
      }
    } else {
      if (newValue.length === 16) {
        let valid = IBAN.validateAccount(newValue);
        if (valid) {
          setSV("rafiPayAccountNumber", IBAN.generate(`${newValue}00000000`));
          setAccountHelperText(getValue("app_v2_accountNumber_validOTP"));
          deleteValidationDataField("rafiPayAccountNumber");
          deleteValidationDataField("emptyIdentifier");
        } else {
          setAccountHelperText(getValue("app_v2_accountNumber_invalidNumber"));
          handleValidationDataChange(
            "rafiPayAccountNumber",
            getValue("app_v2_accountNumber_invalidNumber")
          );
        }
      }
      if (newValue.length === 24) {
        let valid = IBAN.validateAccount(newValue);
        if (valid) {
          setSV("rafiPayAccountNumber", IBAN.generate(newValue));
          setAccountHelperText(getValue("app_v2_accountNumber_validNumber"));
          deleteValidationDataField("rafiPayAccountNumber");
          deleteValidationDataField("emptyIdentifier");
        } else {
          setAccountHelperText(getValue("app_v2_accountNumber_invalidNumber"));
          handleValidationDataChange(
            "rafiPayAccountNumber",
            getValue("app_v2_accountNumber_invalidNumber")
          );
        }
      }
    }
    if (
      newValue.length !== 24 &&
      newValue.length !== 28 &&
      newValue.length !== 16
    ) {
      setSV("rafiPayAccountNumber", "");
      setAccountHelperText(getValue("app_v2_accountNumber_missing"));
      handleValidationDataChange(
        "rafiPayAccountNumber",
        getValue("app_v2_accountNumber_missing")
      );
    }
    if (newValue.length === 0) {
      setAccountHelperText("");
      deleteValidationDataField("rafiPayAccountNumber");
    }
  };

  const handleIdentifierSelect = (identifier) => {
    setSelectedIdentifier(identifier);
    setSV("rafiPayIdentifier", identifier);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    let pasteData = e.clipboardData.getData("Text");
    handleMaskSetByAccountNumber(pasteData);
    handleChange({
      target: { name: "rafiPayAccountNumber", value: pasteData },
    });
  };

  const invokePaste = async () => {
    let data = await navigator.clipboard.readText();
    handleMaskSetByAccountNumber(data);
    handleChange({ target: { name: "rafiPayAccountNumber", value: data } });
  };

  /*let info = <div style={{width: "100%", padding: "1em"}}>
       Fizetési kérelemmel jelenleg a következő bankok ügyfelei tudnak fizetni: Raiffeisen Bank, Budapest Bank, Erste Bank, Gránit Bank, OTP Bank, Takarékbank <br/><br/> A fizetés elindításához be kell lépnie a számlavezető bankjának a mobilapplikációjába vagy netbankjába és jóváhagyni az azonnali átutalást. A számlavezető bankjánál érdeklődjön a szolgáltatás igénybevételének a lehetőségéről.<br/><br/> A fizetési kérelem a Magyar Nemzeti Bank azonnali fizetési rendszerére épülő fizetési szolgáltatás. Fizetési kérelem fogadásához meg kell adni a bankszámlaszámot vagy bankszámlához rendelt másodlagos azonosítót (email cím vagy telefonszám).<br/><br/>
        <div style={{width: "100%", background: "#fff", padding: "1em"}}>
            <img src={`https://cdn.okeoke.io/brands/global/payments/rafi_logo.png`} alt="logo" style={{width: "60%", display: "block", margin: "auto"}} />
        </div>
    </div>*/

  /*
        Raiffeisen 120
        Budapest Bank 101
        Erste Bank 116
        Gránit Bank 121
        OTP bank 117
        Takarékbank 115

    */

  return (
    <div className={`raiffeisen-pay-params-input-fields`}>
      <div
        className={`identifier identifier-rafiPayEmail ${
          selectedIdentifier === "rafiPayEmail" ? "selected" : "not-selected"
        }`}
        onClick={() => {
          handleIdentifierSelect("rafiPayEmail");
        }}
      >
        <div className="radio-root">
          <div
            className={`radio-select-circle ${
              selectedIdentifier === "rafiPayEmail"
                ? "selected"
                : "not-selected"
            }`}
          ></div>
        </div>
        <TextField
          className="email"
          name="rafiPayEmail"
          label={getValue("app_v2_account_email")}
          value={rafiPayEmail}
          onChange={handleChange}
          fullWidth={true}
          margin="normal"
          variant="filled"
          disabled={selectedIdentifier !== "rafiPayEmail"}
        />
      </div>
      <div
        className={`identifier identifier-rafiPayAccountNumber ${
          selectedIdentifier === "rafiPayAccountNumber"
            ? "selected"
            : "not-selected"
        }`}
        onClick={() => {
          handleIdentifierSelect("rafiPayAccountNumber");
        }}
      >
        <div className="radio-root">
          <div
            className={`radio-select-circle ${
              selectedIdentifier === "rafiPayAccountNumber"
                ? "selected"
                : "not-selected"
            }`}
          ></div>
        </div>
        <TextField
          className="account-number-field"
          name="rafiPayAccountNumber"
          label={getValue("app_v2_account_accountNumber")}
          value={rafiPayAccountNumber}
          onChange={handleChange}
          fullWidth={true}
          margin="normal"
          variant="filled"
          disabled={selectedIdentifier !== "rafiPayAccountNumber"}
          helperText={accountHelperText}
          helperText2={accountHelperText2}
          mask={mask}
          onPaste={handlePaste}
        />
        <LoadSvg name="plusIcon" onClick={invokePaste} />
      </div>
      <div
        className={`identifier identifier-rafiPayPhoneNumber ${
          selectedIdentifier === "rafiPayPhoneNumber"
            ? "selected"
            : "not-selected"
        }`}
        onClick={() => {
          handleIdentifierSelect("rafiPayPhoneNumber");
        }}
      >
        <div className="radio-root">
          <div
            className={`radio-select-circle ${
              selectedIdentifier === "rafiPayPhoneNumber"
                ? "selected"
                : "not-selected"
            }`}
          ></div>
        </div>
        <TextField
          className="phoneNumber"
          name="rafiPayPhoneNumber"
          label={getValue("app_v2_account_phone")}
          value={rafiPayPhoneNumber}
          onChange={handleChange}
          fullWidth={true}
          margin="normal"
          variant="filled"
          disabled={selectedIdentifier !== "rafiPayPhoneNumber"}
          helperText={"+36XX XXX XXXX"}
          mask={"+36 99 999 9999"}
        />
      </div>
    </div>
  );
}

export default RaiffeisenPayParams;

//HU20117 7342505 4984970 0000000 <- utalj ide egy kis aprót :)
