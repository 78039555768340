import { Input } from "@nextui-org/react";
import { useDictionary } from "@adamlip/okeoke.client";
import { useContext } from "react";
import { AppParamsContext } from "contexts/AppParamsContext";

export default function EmailField({
  handleChangeEmail,
  handleBlurEmail,
  emailInvalid,
  email,
  visible,
}) {
  const { appParams } = useContext(AppParamsContext);
  const { getValue } = useDictionary();

  if (!visible) return null;

  return (
    <Input
      aria-label="input"
      classNames={{
        inputWrapper:
          "rounded-[var(--general-border-radius)] bg-[var(--main-secondary-bg-color)]",
        label: `${
          appParams?.styles?.fonts?.capitalize ? "font-bold uppercase" : ""
        } group-data-[filled-within=true]:text-foreground-500`,
      }}
      type="email"
      name="email"
      label={getValue("app_v2_login_emailInput")}
      value={email || ""}
      onChange={handleChangeEmail}
      onBlur={handleBlurEmail}
      error={emailInvalid}
      errorMessage={emailInvalid ? getValue("app_v2_login_invalidEmail") : ""}
    />
  );
}
