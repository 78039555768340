import { useDictionary } from "@adamlip/okeoke.client";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/modal";
import { Button } from "@nextui-org/react";
import { InvoiceAPI } from "apis/InvoiceApi";
import { UserContext } from "contexts/UserContext";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Helpers } from "utils/Helpers-v1";
import { Switch } from "@nextui-org/react";
import useBasket from "hooks/useBasket";
import Inputs from "./Inputs";

export default function AddressModal({
  isOpen,
  setIsOpen,
  data,
  onDelete,
  onSave,
}) {
  const { getValue } = useDictionary();
  const { updateAddress, addAddress, deleteAddress } = useContext(UserContext);
  const { updateValidationObject } = useBasket();

  const userTemplate = {
    addressLabel: "",
    name: "",
    state: "",
    zip: "",
    street: "",
    streetNumber: "",
    telNumber: "",
    taxNumber: "",
    taxType: "NO_TAX_NUMBER",
    city: "",
    countryCode: "HU",
    addressType: 1,
    floor: "",
  };

  const helperTemplate = {
    addressLabel: null,
    name: null,
    state: null,
    zip: null,
    street: null,
    streetNumber: null,
    telNumber: null,
    city: null,
  };

  const requiredFields = [
    "addressLabel",
    "name",
    "zip",
    "street",
    "streetNumber",
    "city",
  ];

  const [userData, setUserData] = useState(userTemplate);
  const [userDataHelperText, setUserDataHelperText] = useState(helperTemplate);
  const [hasTaxNumber, setHasTaxNumber] = useState(false);
  const [taxIsLoading, setTaxIsLoading] = useState(false);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    try {
      if (
        e.target.name === "zip" &&
        e.target.value.replaceAll("_", "").length === 4
      )
        getRegion(e.target.value);
    } catch (e) {
      console.log(e);
    }
    if (hasTaxNumber) {
      try {
        if (
          e.target.name === "taxNumber" &&
          e.target.value.replaceAll("_", "").length === 8
        )
          getTax(e.target.value);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getRegion = (zip) => {
    InvoiceAPI.getRegionByZip(zip)
      .then((response) => {
        if (response.data?.code != null) {
          let newUserData = { ...userData };
          newUserData.state = response.data.state;
          newUserData.city = `${response.data.region}${
            response.data.sub_region != ""
              ? ", " + response.data.sub_region
              : ""
          }`;
          newUserData.zip = response.data.code;

          let newUserDataHelperText = { ...userDataHelperText };
          newUserDataHelperText.zip = null;

          setUserDataHelperText(newUserDataHelperText);
          setUserData(newUserData);
        } else {
          let newUserDataHelperText = { ...userDataHelperText };
          newUserDataHelperText.zip = getValue("app_v2_address_invalidZip");
          setUserDataHelperText(newUserDataHelperText);
        }
      })
      .catch((error) => console.error(error));
  };

  const getTax = (taxNumber) => {
    if (taxNumber.length !== 8) return null;
    setTaxIsLoading(true);
    InvoiceAPI.getTaxData(taxNumber)
      .then((response) => {
        if (response.success) {
          let newUserData = { ...userData };
          newUserData.name = response.data.shortName;
          newUserData.city = response.data.city;
          newUserData.taxNumber = response.data.taxNumber;
          newUserData.zip = response.data.zip;
          newUserData.state = "-";
          newUserData.street = response.data.street;
          newUserData.streetNumber = response.data.streetNumber;

          let newUserDataHelperText = { ...userDataHelperText };
          newUserDataHelperText.taxNumber = null;

          setUserDataHelperText(newUserDataHelperText);
          setUserData(newUserData);
        } else {
          let newUserDataHelperText = { ...userDataHelperText };
          newUserDataHelperText.taxNumber = getValue(
            "app_v2_address_invalidTaxNumber"
          );
          setUserDataHelperText(newUserDataHelperText);
        }
        setTaxIsLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const checkTaxNumber = (taxNumber) => {
    InvoiceAPI.getTaxData(taxNumber).then((res) => {
      console.log(res);
      if (res.success) {
        let newUserDataHelperText = { ...userDataHelperText };
        newUserDataHelperText.taxNumber = null;
        setUserDataHelperText(newUserDataHelperText);
        updateValidationObject("invoiceValid", true);
        setUserData({ ...userData, taxNumber: res.data.taxNumber });
      } else {
        let newUserDataHelperText = { ...userDataHelperText };
        newUserDataHelperText.taxNumber = getValue(
          "app_v2_address_invalidTaxNumber"
        );
        console.log("AddresModal");
        updateValidationObject("invoiceValid", false);
        setUserDataHelperText(newUserDataHelperText);
      }
    });
  };

  const validateData = () => {
    let newData = { ...userDataHelperText };
    let valid = true;

    for (const [key, value] of Object.entries(userData)) {
      if (requiredFields.includes(key)) {
        newData[key] =
          value === "" ? getValue("app_v2_address_requiredField") : null;
        valid = valid ? value !== "" : false;
      }
      // if (key === 'telNumber') {
      //   try {
      //     let repValue = Helpers.replaceAll(value, {_: '', ' ': ''});
      //     if (repValue.length > 0 && repValue.length !== 12) {
      //       let newUserDataHelperText = {...userDataHelperText};
      //       newUserDataHelperText.telNumber = getValue('app_v2_address_invalidPhone');
      //       setUserDataHelperText(newUserDataHelperText);
      //       valid = false;
      //     } else {
      //       let newUserDataHelperText = {...userDataHelperText};
      //       newUserDataHelperText.telNumber = null;
      //       setUserDataHelperText(newUserDataHelperText);
      //     }
      //   } catch (e) {
      //     console.log(e);
      //   }
      // }
      if (hasTaxNumber && key === "taxNumber") {
        try {
          let repValue = Helpers.replaceAll(value, { _: "", " ": "" });
          console.log(repValue);
          if (repValue.length > 0 && repValue.length !== 13) {
            let newUserDataHelperText = { ...userDataHelperText };
            newUserDataHelperText.taxNumber = getValue(
              "app_v2_address_invalidTaxNumber"
            );
            setUserDataHelperText(newUserDataHelperText);
            valid = false;
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
    return valid;
  };

  const formatPhoneNumber = (input) =>
    Helpers.replaceAll(input, { _: "", " ": "" });

  const handleResponse = (response) => {
    if (response != null) {
      toast.success(getValue("app_v2_toast_userDataSaved"));
      onSave(response);
      setIsOpen(false);
      setUserData(userTemplate);
      setUserDataHelperText(helperTemplate);
    }
  };

  const handleError = (error) => {
    console.log(error);
    toast.error(getValue("app_v2_toast_userDataInvalid"));
  };

  const saveUserData = () => {
    let dataValid = validateData();

    if (!dataValid) {
      toast.error(getValue("app_v2_toast_userDataInvalid"));
    } else {
      let newUserData = { ...userData };
      newUserData.telNumber = formatPhoneNumber(userData.telNumber);
      newUserData.taxType = hasTaxNumber ? "HAS_TAX_NUMBER" : "NO_TAX_NUMBER";
      if (newUserData.countryCode == null) newUserData.countryCode = "HU";
      if (newUserData.addressType == null) newUserData.addressType = 1;

      console.log(newUserData);

      if (userData.id == null) {
        addAddress(newUserData).then(handleResponse).catch(handleError);
      } else {
        updateAddress(newUserData).then(handleResponse).catch(handleError);
      }
    }
  };

  const handleDeleteAddress = () => {
    if (data?.id) {
      deleteAddress(data?.id).then((res) => {
        console.log(res);
        if (res === 1) {
          setIsOpen(false);
          if (typeof onDelete === "function") onDelete();
        }
      });
    }
  };

  useEffect(() => {
    if (hasTaxNumber && userData?.taxNumber?.length > 0) {
      checkTaxNumber(userData?.taxNumber);
    } else {
      updateValidationObject("invoiceValid", true);
    }
  }, [hasTaxNumber]);

  useEffect(() => {
    if (data != null) {
      setUserData(data);
      setHasTaxNumber(data.taxType === "HAS_TAX_NUMBER");
    }
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => {
        setIsOpen(!isOpen);
      }}
      radius="none"
      className="bg-background"
      size="2xl"
      classNames={{
        base: "m-0 max-w-[820px] flex flex-col",
        wrapper: "sm:items-end",
        closeButton:
          "z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]",
      }}
      scrollBehavior="inside"
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader>
              <div className="title font-extraLarge">
                {getValue("app_v2_address_modal_title")}
              </div>
            </ModalHeader>
            <ModalBody className="px-2 flex flex-col gap-2 grow">
              <div className="tax-number-check flex flex-row justify-between items-center">
                <Switch
                  isSelected={hasTaxNumber}
                  onValueChange={() => setHasTaxNumber(!hasTaxNumber)}
                >
                  {getValue("app_v2_address_modal_hasTax")}
                </Switch>
              </div>
              <Inputs
                userData={userData}
                userDataHelperText={userDataHelperText}
                handleChange={handleChange}
                hasTaxNumber={hasTaxNumber}
                taxIsLoading={taxIsLoading}
                checkTaxNumber={checkTaxNumber}
              />
            </ModalBody>
            <ModalFooter>
              {userData.id != null && (
                <Button
                  variant="light"
                  color="danger"
                  onClick={handleDeleteAddress}
                  className="ml-0 mr-auto"
                >
                  {getValue("app_v2_address_delete")}
                </Button>
              )}
              <Button
                variant="flat"
                color="danger"
                onClick={() => setIsOpen(false)}
              >
                {getValue("app_v2_address_cancel")}
              </Button>
              <Button variant="flat" color="success" onClick={saveUserData}>
                {getValue("app_v2_address_save")}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
