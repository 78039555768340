import { useDictionary } from "@adamlip/okeoke.client";
import useStorage from "hooks/useStorage";
import { Checkbox, Input, cn } from "@nextui-org/react";
import { useEffect, useState, useContext } from "react";
import { AppParamsContext } from "contexts/AppParamsContext";

export default function Reg({
  firstNameInvalid,
  setFirstNameInvalid,
  visible,
  acceptedPolicy,
  setAcceptedPolicy,
}) {
  const { getValue } = useDictionary();
  const { setSV, removeSV, getSV } = useStorage();
  const { appParams } = useContext(AppParamsContext);

  const [firstName, setFirstName] = useState("");
  const [localReferal, setLocalReferal] = useState("");
  const handleChangeFirstName = (e) => {
    let value = e.target.value;
    setFirstName(value);
    value === ""
      ? removeSV("login_first_name")
      : setSV("login_first_name", value);

    if (firstNameInvalid) {
      setFirstNameInvalid(value.length < 2);
    }
  };

  const handleFirstNameBlur = (e) => {
    if (e.target.value.length < 2) {
      setFirstNameInvalid(true);
    } else {
      setFirstNameInvalid(false);
    }
  };

  const handleChangeReferal = (e) => {
    setLocalReferal(e.target.value);
    localStorage["referalID"] = e.target.value;
  };

  useEffect(() => {
    let firstName = getSV("login_first_name");
    setLocalReferal(localStorage["referalID"]);
    setFirstName(firstName);
    setFirstNameInvalid(firstName?.length < 2);
  }, []);

  if (!visible) return null;

  return (
    <>
      <Input
        aria-label="input"
        type="text"
        classNames={{
          inputWrapper:
            "shadow-none	rounded-[var(--general-border-radius)] bg-[var(--main-secondary-bg-color)]",
          input: cn(
            "shadow-none	 bg-[var(--main-secondary-bg-color)] autofill:!bg-[var(--main-secondary-bg-color)] focus:!bg-[var(--main-secondary-bg-color)]"
          ),
          label: `${
            appParams?.styles?.fonts?.capitalize ? "font-bold uppercase" : ""
          } group-data-[filled-within=true]:text-foreground-500`,
        }}
        name="firstName"
        label={getValue("app_v2_login_firstName")}
        value={firstName || ""}
        onChange={handleChangeFirstName}
        onBlur={handleFirstNameBlur}
        error={firstNameInvalid}
        errorMessage={
          firstNameInvalid ? getValue("app_v2_login_firstNameInvalid") : ""
        }
      />
      {appParams?.moreMenuElements?.referal && (
        <>
          <p>{getValue("app_v2_login_referal_desc")}</p>
          <Input
            aria-label="input"
            type="text"
            classNames={{
              inputWrapper:
                "shadow-none	rounded-[var(--general-border-radius)] bg-[var(--main-secondary-bg-color)]",
              input: cn(
                "shadow-none	 bg-[var(--main-secondary-bg-color)] autofill:!bg-[var(--main-secondary-bg-color)] focus:!bg-[var(--main-secondary-bg-color)]"
              ),
              label: `${
                appParams?.styles?.fonts?.capitalize
                  ? "font-bold uppercase"
                  : ""
              } group-data-[filled-within=true]:text-foreground-500`,
            }}
            name="referal"
            label={getValue("app_v2_profile_ref_id")}
            value={localReferal || ""}
            onChange={handleChangeReferal}
          />
        </>
      )}
      {appParams?.login?.shouldCheckBoxBeChecked ? (
        <Checkbox
          isSelected={acceptedPolicy}
          onValueChange={(value) => {
            setAcceptedPolicy(value);
          }}
          isRequired
          classNames={{
            wrapper: "border-2 border-[var(--button2-bg-color)] rounded-full",
          }}
        >
          {getValue("app_v2_login_policyStart")}{" "}
          <a
            className="w-full text-center underline"
            href={
              appParams?.others?.privacyLink ||
              "https://download.okeoke.io/docs/policy.pdf"
            }
            target="_blank"
            rel="noreferrer"
          >
            {getValue("app_v2_login_policyLink")}
          </a>{" "}
          {getValue("app_v2_login_policyEnd")}{" "}
          <span className="text-danger">*</span>
        </Checkbox>
      ) : (
        <>
          <p className="px-2 privacy-link text-sm">
            {getValue("app_v2_login_policyText")}
          </p>
          <a
            className="w-full text-center underline"
            href={
              appParams?.others?.privacyLink ||
              "https://download.okeoke.io/docs/policy.pdf"
            }
            target="_blank"
            rel="noreferrer"
          >
            {getValue("app_v2_login_policyLink")}
          </a>
        </>
      )}
    </>
  );
}
