import rgbToHsl from 'rgb-to-hsl';

function paramsToRgb(params) {
  let rgb = '';
  rgb = params?.replace('rgba(', '');
  rgb = rgb?.replace(')', '');
  let r = rgb?.split(',')[0];
  let g = rgb?.split(',')[1];
  let b = rgb?.split(',')[2];
  let formatted = rgbToHsl(r, g, b);
  let h = parseInt(formatted?.[0]?.toFixed(0));
  formatted = h + ' ' + formatted?.[1] + ' ' + formatted?.[2];
  return formatted;
}
function setProps(appParams) {
  document.documentElement?.style.setProperty('--main-text-color', appParams?.styles?.colorVariables?.mainTextColor || '#000');
  document.documentElement?.style.setProperty('--main-bg-color', appParams?.styles?.colorVariables?.mainBgColor || '#fff');
  document.documentElement?.style.setProperty(
    '--order-items-bg-color',
    appParams?.styles?.colorVariables?.orderItemsBgColor || appParams?.styles?.colorVariables?.mainBgColor || '#fff'
  );
  document.documentElement?.style.setProperty(
    '--order-items-text-color',
    appParams?.styles?.colorVariables?.orderItemsTextColor || appParams?.styles?.colorVariables?.mainTextColor || '#000'
  );
  document.documentElement?.style.setProperty(
    '--order-items-card-bg-color',
    appParams?.styles?.colorVariables?.orderItemsCardBgColor || appParams?.styles?.colorVariables?.orderItemsBgColor || appParams?.styles?.colorVariables?.mainBgColor || '#000'
  );
  document.documentElement?.style.setProperty(
    '--order-items-card-text-color',
    appParams?.styles?.colorVariables?.orderItemsCardTextColor ||
      appParams?.styles?.colorVariables?.orderItemsTextColor ||
      appParams?.styles?.colorVariables?.mainTextColor ||
      '#000'
  );
  document.documentElement?.style.setProperty('--top-bar-bg-color', appParams?.styles?.colorVariables?.topBarBgColor || '#fff');
  document.documentElement?.style.setProperty('--top-bar-icon-color', appParams?.styles?.colorVariables?.topBarIconColor || '#000');
  document.documentElement?.style.setProperty('--top-bar-selected-icon-color', appParams?.styles?.colorVariables?.topBarSelectedIconColor || '#fff');
  document.documentElement?.style.setProperty('--top-bar-border-color', appParams?.styles?.colorVariables?.topBarBorderColor || '#fff');
  document.documentElement?.style.setProperty('--top-bar-cart-badge-bg-color', appParams?.styles?.colorVariables?.topBarCartBadgeBgColor || '#ff0000');
  document.documentElement?.style.setProperty('--top-bar-cart-badge-text-color', appParams?.styles?.colorVariables?.topBarCartBadgeTextColor || '#fff');
  document.documentElement?.style.setProperty('--bottom-bar-border-color', appParams?.styles?.colorVariables?.bottomBarBorderColor || '#fff');
  document.documentElement?.style.setProperty('--bottom-bar-bg-color', appParams?.styles?.colorVariables?.bottomBarBgColor || '#fff');
  document.documentElement?.style.setProperty('--bottom-bar-text-color', appParams?.styles?.colorVariables?.bottomBarTextColor || '#000');
  document.documentElement?.style.setProperty('--bottom-bar-nav-bg-color', appParams?.styles?.colorVariables?.bottomBarNavBgColor || '#000');
  document.documentElement?.style.setProperty('--bottom-bar-nav-text-color', appParams?.styles?.colorVariables?.bottomBarNavTextColor || '#fff');
  document.documentElement?.style.setProperty('--bottom-bar-selected-nav-bg-color', appParams?.styles?.colorVariables?.bottomBarSelectedNavBgColor || '#ff0000');
  document.documentElement?.style.setProperty('--bottom-bar-selected-nav-text-color', appParams?.styles?.colorVariables?.bottomBarSelectedNavTextColor || '#fff');
  document.documentElement?.style.setProperty('--bottom-bar-icon-color', appParams?.styles?.colorVariables?.bottomBarIconColor || '#fff');
  document.documentElement?.style.setProperty('--bottom-bar-selected-icon-color', appParams?.styles?.colorVariables?.bottomBarSelectedIconColor || '#ff0000');
  document.documentElement?.style.setProperty('--top-bar-nav-bg-color', appParams?.styles?.colorVariables?.topBarNavBgColor || '#000');
  document.documentElement?.style.setProperty('--top-bar-nav-text-color', appParams?.styles?.colorVariables?.topBarTextColor || '#fff');
  document.documentElement?.style.setProperty('--top-bar-selected-nav-bg-color', appParams?.styles?.colorVariables?.topBarSelectedNavBgColor || '#ff0000');
  document.documentElement?.style.setProperty('--top-bar-selected-nav-text-color', appParams?.styles?.colorVariables?.topBarSelectedNavTextColor || '#fff');
  document.documentElement?.style.setProperty('--startNav-bg-color', appParams?.styles?.colorVariables?.startNavBgColor || '#000');
  document.documentElement?.style.setProperty('--startNav-text-color', appParams?.styles?.colorVariables?.startNavTextColor || '#fff');
  document.documentElement?.style.setProperty('--startNav-border-color', appParams?.styles?.colorVariables?.startNavBorderColor || '#000');
  document.documentElement?.style.setProperty('--startNav-border-width', appParams?.styles?.borderRadius?.startNavBorderWidth || '2px');

  document.documentElement?.style.setProperty('--button-bg-color', appParams?.styles?.colorVariables?.buttonBgColor || '#000');
  document.documentElement?.style.setProperty('--button-text-color', appParams?.styles?.colorVariables?.buttonTextColor || '#fff');
  document.documentElement?.style.setProperty('--button-border-color', appParams?.styles?.colorVariables?.buttonBorderColor || '#000');
  document.documentElement?.style.setProperty('--button-border-width', appParams?.styles?.borderRadius?.buttonBorderWidth || '2px');
  document.documentElement?.style.setProperty('--button2-bg-color', appParams?.styles?.colorVariables?.button2BgColor || '#ff0000');
  document.documentElement?.style.setProperty('--button2-text-color', appParams?.styles?.colorVariables?.button2TextColor || '#fff');
  document.documentElement?.style.setProperty('--button2-border-color', appParams?.styles?.colorVariables?.button2BorderColor || '#ff0000');
  document.documentElement?.style.setProperty('--button2-border-width', appParams?.styles?.borderRadius?.button2BorderWidth || '2px');

  document.documentElement?.style.setProperty('--system-color-error', appParams?.styles?.colorVariables?.systemColorError || '#fff');
  document.documentElement?.style.setProperty('--system-color-success', appParams?.styles?.colorVariables?.systemColorSuccess || '#fff');

  document.documentElement?.style.setProperty('--body-font', appParams?.styles?.fonts?.body || 'Roboto');

  document.documentElement?.style.setProperty('--general-border-radius', appParams?.styles?.borderRadius?.general || '10px');
  document.documentElement?.style.setProperty('--order-button-border-radius', appParams?.styles?.borderRadius?.orderButton || '50px');
  document.documentElement?.style.setProperty('--general-button-border-radius', appParams?.styles?.borderRadius?.generalButton || '10px');
  document.documentElement?.style.setProperty('--main-secondary-bg-color', appParams?.styles?.colorVariables?.secondaryBg || '#000');
  document.documentElement?.style.setProperty('--main-border-color', appParams?.styles?.colorVariables?.borderColor || '#000');
  document.documentElement?.style.setProperty('--bottomBar-height', appParams?.styles?.topBottomBar?.size || '100px');
  document.documentElement?.style.setProperty(
    '--selectedCategory-BgColor',
    appParams?.styles?.colorVariables?.selectedCategoryBackground || appParams?.styles?.colorVariables?.button2BgColor || '#fff'
  );
  document.documentElement?.style.setProperty(
    '--selectedCategory-TextColor',
    appParams?.styles?.colorVariables?.selectedCategoryText || appParams?.styles?.colorVariables?.button2TextColor || '#fff'
  );
  document.documentElement?.style.setProperty('--modal-radius', appParams?.styles?.borderRadius?.modalRadius || '0px');
  document.documentElement?.style.setProperty(
    '--startNav-category-bg-color',
    appParams?.styles?.colorVariables?.startCategoryBg || appParams?.styles?.colorVariables?.buttonBgColor || '#000'
  );
  document.documentElement?.style.setProperty(
    '--startNav-category-text-color',
    appParams?.styles?.colorVariables?.startCategoryText || appParams?.styles?.colorVariables?.buttonTextColor || '#fff'
  );
  document.documentElement?.style.setProperty(
    '--startNav-category-border-color',
    appParams?.styles?.colorVariables?.startCategoryBorderColor || appParams?.styles?.colorVariables?.buttonBorderColor || '#000'
  );

  document.documentElement?.style.setProperty('--input-bg-color', appParams?.styles?.colorVariables?.inputBgColor || appParams?.styles?.colorVariables?.secondaryBg || '#000');
  document.documentElement?.style.setProperty('--input-text-color', appParams?.styles?.colorVariables?.inputTextColor || appParams?.styles?.colorVariables?.secondaryBg || '#000');
  document.documentElement?.style.setProperty(
    '--input-border-color',
    appParams?.styles?.colorVariables?.inputBorderColor || appParams?.styles?.colorVariables?.secondaryBg || '#000'
  );
  document.documentElement?.style.setProperty(
    '--order-items-card-border-color',
    appParams?.styles?.colorVariables?.orderItemsCardBorderColor ||
      appParams?.styles?.colorVariables?.orderItemsCardBgColor ||
      appParams?.styles?.colorVariables?.orderItemsBgColor ||
      appParams?.styles?.colorVariables?.mainBgColor ||
      '#000'
  );
  // KUPONOK
  document.documentElement?.style.setProperty('--coupon-bg', appParams?.styles?.colorVariables?.couponBgColor || appParams?.styles?.colorVariables?.mainBgColor || '#000');
  document.documentElement?.style.setProperty('--coupon-text', appParams?.styles?.colorVariables?.couponTextColor || appParams?.styles?.colorVariables?.mainTextColor || '#000');
  document.documentElement?.style.setProperty('--coupon-nameText', appParams?.styles?.colorVariables?.couponNameText || appParams?.styles?.colorVariables?.mainTextColor || '#000');
  document.documentElement?.style.setProperty(
    '--coupon-detailsBg',
    appParams?.styles?.colorVariables?.couponDetailsBg || appParams?.styles?.colorVariables?.button2BgColor || '#000'
  );
  document.documentElement?.style.setProperty(
    '--coupon-detailsText',
    appParams?.styles?.colorVariables?.couponDetailsText || appParams?.styles?.colorVariables?.button2TextColor || '#000'
  );
  document.documentElement?.style.setProperty('--coupon-claimBg', appParams?.styles?.colorVariables?.couponClaimBg || appParams?.styles?.colorVariables?.buttonBgColor || '#000');
  document.documentElement?.style.setProperty(
    '--coupon-claimText',
    appParams?.styles?.colorVariables?.couponClaimText || appParams?.styles?.colorVariables?.buttonTextColor || '#000'
  );
  // HÍREK
  document.documentElement?.style.setProperty('--news-bg', appParams?.styles?.colorVariables?.newsBgColor || appParams?.styles?.colorVariables?.mainBgColor || '#000');
  document.documentElement?.style.setProperty('--news-text', appParams?.styles?.colorVariables?.newsTextColor || appParams?.styles?.colorVariables?.mainTextColor || '#000');
  document.documentElement?.style.setProperty('--news-buttonBg', appParams?.styles?.colorVariables?.newsButtonBg || appParams?.styles?.colorVariables?.buttonBgColor || '#000');
  document.documentElement?.style.setProperty(
    '--news-buttonText',
    appParams?.styles?.colorVariables?.newsButtonText || appParams?.styles?.colorVariables?.buttonTextColor || '#000'
  );

  //  NEXTUI
  document.documentElement?.style.setProperty('--nextui-foreground', paramsToRgb(appParams?.styles?.colorVariables?.mainTextColor) || '#000');
  document.documentElement?.style.setProperty('--nextui-default-foreground', paramsToRgb(appParams?.styles?.colorVariables?.mainTextColor) || '#000');
  document.documentElement?.style.setProperty('--nextui-default', paramsToRgb(appParams?.styles?.colorVariables?.secondaryBg) || '#000');
  document.documentElement?.style.setProperty('--nextui-background', paramsToRgb(appParams?.styles?.colorVariables?.mainBgColor) || '#fff');
  document.documentElement?.style.setProperty('--nextui-content1', paramsToRgb(appParams?.styles?.colorVariables?.mainBgColor) || '#fff');
  document.documentElement?.style.setProperty('--nextui-primary', paramsToRgb(appParams?.styles?.colorVariables?.buttonBgColor) || '#fff');
  document.documentElement?.style.setProperty('--nextui-primary-foreground', paramsToRgb(appParams?.styles?.colorVariables?.buttonTextColor) || '#fff');
  document.documentElement?.style.setProperty('--nextui-secondary', paramsToRgb(appParams?.styles?.colorVariables?.button2BgColor) || '#fff');
  document.documentElement?.style.setProperty('--nextui-secondary-foreground', paramsToRgb(appParams?.styles?.colorVariables?.button2TextColor) || '#fff');
  document.documentElement?.style.setProperty(
    '--nextui-default-100',
    paramsToRgb(appParams?.styles?.colorVariables?.secondaryBg) || appParams?.styles?.colorVariables?.mainBgColor || '#000'
  );
  document.documentElement?.style.setProperty(
    '--nextui-default-200',
    paramsToRgb(appParams?.styles?.colorVariables?.secondaryBg) || appParams?.styles?.colorVariables?.mainBgColor || '#000'
  );
  document.documentElement?.style.setProperty('--rdp-background-color', paramsToRgb(appParams?.styles?.colorVariables?.buttonBgColor) || '#fff');

  document.documentElement?.style.setProperty('--nextui-bottomInactiveIcon', paramsToRgb(appParams?.styles?.colorVariables?.bottomBarIconColor) || '#fff');
  document.documentElement?.style.setProperty('--nextui-bottomActiveIcon', paramsToRgb(appParams?.styles?.colorVariables?.bottomBarSelectedIconColor) || '#ff0000');
}
export {setProps};
