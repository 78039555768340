import LocationCard from "views/BasketModal/LocationCard";
import PaymentTypeSelector from "views/OrderPay/PaymentTypeSelector";
import InvoiceInfo from "./InvoiceInfo";
import SubmitOrderBtn from "views/BasketModal/Slides/Payment/SubmitOrderBtn";
import { useDictionary } from "@adamlip/okeoke.client";
import { useState, useContext } from "react";
import useBasket from "hooks/useBasket";
import AddCouponModal from "components/AddCouponModal";
import { Button } from "@nextui-org/react";
import { UserContext } from "contexts/UserContext";
import SelectTableModal from "components/SelectTableModal";
import { AppParamsContext } from "contexts/AppParamsContext";

export default function Payment({
  setOrderSubmitLoading,
  usableMoney,
  setIsOpen,
}) {
  const { appParams } = useContext(AppParamsContext);
  const { getValue } = useDictionary();

  const {
    setSelectedPayment,
    handleChangePaymentType,
    orderIdentifier,
    notes,
    tableInfo,
  } = useBasket();

  const { isVipBrand } = useContext(UserContext);

  const [payByVipMoney, setPayByVipMoney] = useState(false);
  const [addCouponModalOpen, setAddCouponModalOpen] = useState(false);
  const [selectTableOpen, setSelectTableOpen] = useState(false);

  const handleSelectPayment = (payment) => {
    if (payment != null) {
      setSelectedPayment(payment);
      handleChangePaymentType("Online");
    }
  };

  return (
    <>
      <SelectTableModal
        isOpen={selectTableOpen}
        setIsOpen={setSelectTableOpen}
      />
      <div className="h-full flex flex-col gap-2 grow pb-10">
        <div
          className={`${
            appParams?.styles?.fonts?.capitalize
              ? "font-extrabold uppercase"
              : ""
          } basket-slide-header  font-bold text-xl`}
        >
          {getValue("app_v2_basket_slider_payment")}
        </div>
        <div className="flex">
          <div className="mr-2">
            {getValue("app_v2_basket_orderIdentifier")}:
          </div>
          <div className="font-bold">{orderIdentifier}</div>
        </div>
        {notes?.length > 0 && (
          <div className="flex">
            <div className="mr-2">{getValue("app_v2_basket_orderNotes")}:</div>
            <div className="font-bold">{notes}</div>
          </div>
        )}
        {tableInfo?.name?.length > 0 && (
          <div className="flex flex-row gap-2 items-center justify-between">
            <div className="flex">
              <div className="mr-2">
                {getValue("app_v2_basket_selectedTable")}:
              </div>
              <div className="font-bold">{tableInfo?.name}</div>
            </div>
          </div>
        )}
        <div className="basket-slide-body grow flex flex-col overflow-hidden">
          <div className="basket-slide-body-content grow overflow-y-scroll">
            <InvoiceInfo />
            {appParams?.basketModal?.visibleBlocks?.locationsOnPayment && (
              <LocationCard />
            )}
            <PaymentTypeSelector
              onSelectPayment={handleSelectPayment}
              usableMoney={usableMoney}
              setPayByVipMoney={setPayByVipMoney}
              payByVipMoney={payByVipMoney}
            />
          </div>
          {isVipBrand &&
            appParams?.basketModal?.visibleBlocks?.addCouponCodeOnPayment && (
              <>
                <Button
                  color="secondary"
                  radius="full"
                  className="font-semibold shrink-0 mt-2"
                  onClick={() => setAddCouponModalOpen(true)}
                >
                  {getValue("app_v2_coupons_addCoupon")}
                </Button>
              </>
            )}
          <SubmitOrderBtn
            setIsOpen={setIsOpen}
            usableMoney={usableMoney}
            payByVipMoney={payByVipMoney}
            setOrderSubmitLoading={setOrderSubmitLoading}
          />
        </div>
      </div>
      {isVipBrand && (
        <AddCouponModal
          isOpen={addCouponModalOpen}
          setIsOpen={setAddCouponModalOpen}
        />
      )}
    </>
  );
}
