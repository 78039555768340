import {useClient, useItems, useOrderItem} from '@adamlip/okeoke.client';
import {Button, ButtonGroup} from '@nextui-org/react';
import BasketItemModal from 'views/BasketModal/Slides/BasketItems/BasketItemModal';
import {useState, useContext, useEffect} from 'react';
import LoadSvg from 'utils/LoadSvg-v3';
import {AppParamsContext} from 'contexts/AppParamsContext';

export default function BasketItem({orderItemUUID, orderItems}) {
  const {assembledItem, setCount, update} = useOrderItem(null, null, orderItemUUID);
  const {sizes} = useItems();
  const {getName} = useClient();
  const {appParams} = useContext(AppParamsContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(false);
  const [showSizeName, setShowSizeName] = useState(true);
  const increaseCount = () => {
    setCount(assembledItem.count + 1);
    update();
  };

  const decreaseCount = () => {
    setCount(assembledItem.count - 1);
    update();
  };

  useEffect(() => {
    switch (appParams?.basketModal?.items?.itemStyle) {
      case '2':
        setSelectedStyle('border-2 border-[var(--main-text-color)]');
        break;
      default:
        setSelectedStyle('shadow-sm bg-[var(--main-secondary-bg-color)]');
    }

    if (appParams?.basketModal?.items?.hideSizeName) {
      let haveMultipleItemsFromThis = orderItems.filter((item) => item?.itemUUID === assembledItem?.uuid)?.filter((item) => item?.count > 0)?.length > 1;
      let isThisItemHaveMultipleSizes = assembledItem?.visibleSizes > 1;
      setShowSizeName(haveMultipleItemsFromThis && isThisItemHaveMultipleSizes ? true : false);
    } else {
      setShowSizeName(true);
    }
  }, [appParams, orderItems, assembledItem]);
  if (assembledItem == null) return null;
  if (assembledItem?.count === 0) return null;

  return (
    <div
      className={`basketItem-root ${selectedStyle} w-full mb-2 ${assembledItem.valid ? 'valid' : assembledItem.count > 0 ? 'invalid' : 'valid'} ${
        assembledItem.count > 0 ? 'in-basket' : 'not-in-basket'
      } ${assembledItem?.availability?.visible === 1 ? 'visible' : 'not-visible'}`}
      onClick={() => {
        setIsOpen(true);
      }}>
      <BasketItemModal isOpen={isOpen} setIsOpen={setIsOpen} orderItemUUID={orderItemUUID} />
      <div className='basketItem-name-root grow'>
        <div className='flex flex-col items-start mb-2'>
          <div className={`${appParams?.styles?.fonts?.capitalize ? 'font-extrabold uppercase' : ''} basketItem-name  text-md`}>
            {getName(assembledItem.name)}
            {showSizeName && <> ( {getName(sizes?.[assembledItem?.selectedSizeUUID]?.name)} ) </>} {assembledItem.takeAway === 1 ? 'TA' : ''}
          </div>
          <div className='text-sm font-normal opacity-60'>{`${assembledItem.discountPrice} Ft`}</div>
        </div>
        {showSizeName && <div className='basketItem-size font-smallest'>{getName(assembledItem?.sizes[assembledItem.selectedSizeUUID]?.name)}</div>}
        <div className='basketItem-modifiers font-smallest'>
          {(assembledItem?.modifierGroups || [])
            ?.filter((group) => group?.modifiers?.some((modifier) => modifier.count > 0))
            .map((group) => (
              <div key={group.uuid} className={`basketItem-modifierGroup mb-2 text-xs ${group.valid ? 'valid' : assembledItem.count > 0 ? 'invalid' : 'valid'}`}>
                <p className='font-semibold'> {getName(group?.name)}:</p>
                {group.modifiers
                  .filter((modifier) => modifier.count > 0)
                  .map((modifier) => (
                    <div key={modifier.uuid} className={`basketItem-modifier ${modifier.valid ? 'valid' : assembledItem.count > 0 ? 'invalid' : 'valid'}`}>
                      {`${modifier.count == null || modifier.count === 1 ? '' : modifier.count} ${getName(modifier.name)}`}
                    </div>
                  ))}
              </div>
            ))}
          <div className='basketItem-modifier'>{assembledItem.notes}</div>
        </div>
      </div>
      <div className='flex justify-end items-end'>
        <ButtonGroup className='gap-1'>
          <Button size='sm' isIconOnly color='secondary' className='!rounded-[var(--order-button-border-radius)] text-[var(--button2-text-color)]' onClick={() => decreaseCount()}>
            <LoadSvg className='basket-item-svg' name='minusIcon' />
          </Button>
          <Button size='sm' isIconOnly className='!rounded-[var(--order-button-border-radius)] bg-[var(--)] font-bold'>
            {assembledItem?.count}
          </Button>
          <Button size='sm' isIconOnly color='secondary' className='!rounded-[var(--order-button-border-radius)]' onClick={() => increaseCount()}>
            <LoadSvg className='basket-item-svg' name='plusIcon' />
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
