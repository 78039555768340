import React from 'react'
import RaiffeisenPayParams from './RaiffeisenPayParams'
import SimplePayParams from './SimplePayParams'

function PaymentAgentParams(props) {

    const { data, selected } = props

    if(data == null) return null

    return(
        <div className={`payment-agent-params ${selected ? 'selected' : 'not-selected'}`}>
            {data.useOnline?.profileType === "simplePay" && <SimplePayParams selected={selected} />}
            {data.useOnline?.profileType === "raiffeisenPay" && <RaiffeisenPayParams selected={selected} agentID={data.paymentAgentID} />}
        </div>
    )
}

export default PaymentAgentParams