import {useDictionary, useLocation} from '@adamlip/okeoke.client';
import {Button} from '@nextui-org/react';
import VipReserveModal from 'components/VipReserveModal';
import {BasketContext} from 'contexts/BasketContext';
import {MainContext} from 'contexts/MainContext';
import {OpenReplayContext} from 'contexts/OpenReplayContext';
import {UserContext} from 'contexts/UserContext';
import useBasket from 'hooks/useBasket';
import moment from 'moment';
import {useContext, useEffect, useRef, useState} from 'react';
import {toast} from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import SelectTableModal from 'components/SelectTableModal';
import {AppParamsContext} from 'contexts/AppParamsContext';

export default function SubmitOrderBtn({setIsOpen, usableMoney, payByVipMoney, setOrderSubmitLoading}) {
  const {handleOrderSubmit, paymentsNeeded, tableInfo, canOrderFromSelectedLocation, canSubmit, validation, paymentType, setOrderIdentifier, invoiceInfo} = useBasket();

  const {selectedPayment, reserveVip, finalizeVip} = useContext(BasketContext);
  const {userData, isVipBrand, payOrder} = useContext(UserContext);
  const {tracker, trackerInstalled} = useContext(OpenReplayContext);
  const {closeAllModal} = useContext(MainContext);
  const {appParams} = useContext(AppParamsContext);
  const {getValue} = useDictionary();
  const navigate = useNavigate();
  const {cloudDevice, location} = useLocation();

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [selectTableOpen, setSelectTableOpen] = useState(false);
  const [reserveModalOpen, setReserveModalOpen] = useState(false);
  const [reserveData, setReserveData] = useState(null);
  const [reserveLoading, setReserveLoading] = useState(false);

  const loadingTimer = useRef();

  const onPressSubmit = () => {
    if (canSubmit) {
      let pos = userData?.email?.search('@');
      if (pos > 12) pos = 12;
      let shortEmail = userData?.email?.slice(0, pos);
      let savedIdentifier = localStorage['orderIdentifier'] != null && localStorage['orderIdentifier'] !== 'undefined' ? localStorage['orderIdentifier'] : null;
      let identifier = savedIdentifier || userData?.firstName || shortEmail || 'Anonymous';
      identifier = identifier.slice(0, 12);
      setOrderIdentifier(identifier);
      setBtnDisabled(true);
      startSubmit();
    } else {
      setBtnDisabled(false);
      runValidations();
    }
  };

  const startSubmit = () => {
    //console.log(selectedPayment, paymentType);
    if (selectedPayment != null || paymentType === 'Offline') {
      if (!isVipBrand) {
        submitOrder();
      } else {
        handleReserveVip();
      }
    } else {
      if (payByVipMoney) {
        if (usableMoney > paymentsNeeded) {
          if (!isVipBrand) {
            submitVipMoneyOrder();
          } else {
            handleReserveVip();
          }
        } else {
          toast.error(getValue('app_v2_not_enough_vip_money'));
          setBtnDisabled(false);
        }
      } else {
        toast.error(getValue('app_v2_invalidPaymentAgent'));
        setBtnDisabled(false);
      }
    }
  };

  const navigateToPayment = (orderUUID) => {
    let profileType = selectedPayment?.useOnline?.profileType;
    let paymentAgentID = selectedPayment?.paymentAgentID;
    if (profileType != null && paymentAgentID != null) {
      navigate(`/orderpay/${profileType.toLowerCase()}/${orderUUID}-${paymentAgentID}`);
    } else {
      toast.error(getValue('app_v2_invalidPaymentAgent'));
    }
  };

  const runValidations = () => {
    if (selectedPayment === null && paymentType !== 'Offline' && !payByVipMoney) {
      toast.error(`${getValue('app_v2_selectPaymentMethood')}`);
    }
    if (validation?.minimumPriceValid === false) {
      toast.error(`${getValue('app_v2_minimumBasketPrice')}: ${appParams?.basketModal?.minBasketValue} Ft`);
    }
    if (appParams?.order?.tableSelectionRequired && tableInfo == null) {
      toast.error(getValue('app_v2_tableSelectionRequired'));
      setSelectTableOpen(true);
    }
    if (validation?.invoiceValid === false) {
      toast.error(getValue('app_v2_invalidInvoice'));
    }
    if (validation?.itemVisibilityValid === false) {
      toast.error(getValue('app_v2_invalid_items'));
    }
  };

  const handleReserveVip = () => {
    setReserveLoading(true);
    reserveVip().then((res) => {
      setBtnDisabled(false);
      if (res.success) {
        setReserveData(res.data);
        setReserveModalOpen(true);
        loadingTimer.current = setTimeout(() => {
          setReserveLoading(false);
        }, 500);
      } else {
        toast.error(getValue('app_v2_vip_reserve_error'));
        setReserveLoading(false);
      }
      //console.log("Reserve response:", res);
    });
  };

  const onVipSuccess = (res) => {
    if (res?.sumPaymentsNeeded === 0) {
      finalizeVip().then((res) => {
        if (res?.success) {
          submitOrder(true);
        } else {
          toast.error(getValue('app_v2_vip_reserve_error'));
        }
      });
      return;
    }
    if (selectedPayment != null || paymentType === 'Offline') {
      const {sumPaymentsNeeded} = res;
      let skipPayment = sumPaymentsNeeded === 0;
      submitOrder(skipPayment);
    } else {
      if (usableMoney > paymentsNeeded) {
        submitVipMoneyOrder();
      } else {
        toast.error('app_v2_not_enough_vip_money');
        setBtnDisabled(false);
      }
    }
  };

  const submitVipMoneyOrder = () => {
    setOrderSubmitLoading(true);
    handleOrderSubmit().then((res) => {
      console.log('Order submit response:', res);
      setOrderSubmitLoading(false);
      if (res.success) {
        payOrder(res?.order?.uuid).then((res) => {
          console.log('Pay order res:', res);
        });
        if (res?.order?.orderNumber && tracker.current && trackerInstalled) {
          tracker?.current?.setMetadata('orderNumber', res?.order?.orderNumber);
        }
        if (tracker.current && trackerInstalled) {
          // addEvent('Order submit', res);

          tracker?.current?.setMetadata('orderUUID', res?.order?.uuid);
          tracker?.current?.setMetadata('insertTimestamp', moment(res?.order?.insertTimestamp).format('YYYY-MM-DD HH:mm:ss'));
        }
        if (appParams?.order?.dontTrackOrderState) {
          navigate(`/orderdone`);
        } else {
          navigate(`/offlinebackref/${res.order.uuid}`);
        }
        closeAllModal();
        setIsOpen(false);
      } else {
        toast.error(getValue('app_v2_toast_orderFailed'));
        setBtnDisabled(false);
        if (res?.code === 0) {
          navigate(`/orderview/${res.order.uuid}`);
        }
      }
    });
  };

  const submitOrder = (skipPayment) => {
    setOrderSubmitLoading(true);
    handleOrderSubmit().then((res) => {
      setOrderSubmitLoading(false);
      console.log('Order submit response:', res);
      if (res.success) {
        if (res?.data?.orderNumber && tracker.current && trackerInstalled) {
          tracker?.current?.setMetadata('orderNumber', res?.data?.orderNumber);
        }
        if (tracker.current && trackerInstalled) {
          // addEvent('Order submit', res);

          tracker?.current?.setMetadata('orderUUID', res?.order?.uuid);
          tracker?.current?.setMetadata('insertTimestamp', moment(res?.order?.insertTimestamp).format('YYYY-MM-DD HH:mm:ss'));
        }
        if (skipPayment) {
          if (appParams?.order?.dontTrackOrderState) {
            navigate(`/orderdone`);
          } else {
            navigate(`/orderview/${res.order.uuid}`);
          }
        } else {
          if (paymentType === 'Offline') {
            if (appParams?.order?.dontTrackOrderState) {
              navigate(`/orderdone`);
            } else {
              navigate(`/offlinebackref/${res.order.uuid}`);
            }
          } else {
            if (paymentsNeeded === 0) {
              navigate(`/orderview/${res.order.uuid}`);
            } else {
              if (typeof navigateToPayment === 'function') {
                navigateToPayment(res.order.uuid);
              } else {
                if (paymentsNeeded || paymentsNeeded > 0) {
                  navigate(`/orderpay/select/${res.order.uuid}`);
                }
              }
            }
          }
        }
        closeAllModal();
        setIsOpen(false);
      } else {
        toast.error(getValue('app_v2_toast_orderFailed'));
        setBtnDisabled(false);
        if (res?.code === 0) {
          navigate(`/orderview/${res.order.uuid}`);
        }
      }
    });
  };

  useEffect(() => {
    return () => clearTimeout(loadingTimer.current);
  }, []);

  // console.log(cloudDevice);
  // if ((cloudDevice?.connections < 1 && location?.useExternalOrderManager)) {
  //   return <div className="">123</div>;
  // }

  return (
    <>
      <VipReserveModal isOpen={reserveModalOpen} setIsOpen={setReserveModalOpen} data={reserveData} onSuccess={onVipSuccess} paymentsNeeded={paymentsNeeded} />
      <SelectTableModal isOpen={selectTableOpen} setIsOpen={setSelectTableOpen} />
      <Button
        fullWidth
        isDisabled={btnDisabled}
        onPress={onPressSubmit}
        color='primary'
        size='lg'
        className={`${appParams?.styles?.fonts?.capitalize ? 'font-extrabold uppercase' : ''} rounded-[var(--order-button-border-radius)] shrink-0 mt-2`}>
        {!reserveLoading && (
          <>
            {canOrderFromSelectedLocation && !payByVipMoney && (
              <>
                {appParams?.order?.tableSelectionRequired && (selectedPayment != null || paymentType === 'Offline') && (
                  <>
                    {tableInfo?.uuid == null && getValue('app_v2_noTableSelected')}
                    {tableInfo?.uuid != null && `${getValue('app_v2_basketModal_submitOrder')} ${paymentsNeeded == null ? '' : '• ' + paymentsNeeded + ' Ft'}`}
                  </>
                )}
                {selectedPayment != null || paymentType === 'Offline' ? (
                  !appParams?.order?.tableSelectionRequired && (
                    <>
                      {!isVipBrand && `${getValue('app_v2_basketModal_submitOrder')} ${paymentsNeeded == null ? '' : '• ' + paymentsNeeded + ' Ft'}`}
                      {isVipBrand && getValue('app_v2_basketModal_vip_reserve')}
                    </>
                  )
                ) : (
                  <>{getValue('app_v2_selectPaymentMethood')}</>
                )}
              </>
            )}
            {canOrderFromSelectedLocation && payByVipMoney && (
              <>
                {appParams?.order?.tableSelectionRequired && (
                  <>
                    {tableInfo?.uuid == null && getValue('app_v2_noTableSelected')}
                    {tableInfo?.uuid != null && (
                      <>
                        {!isVipBrand && `${getValue('app_v2_basketModal_submitOrder')} ${paymentsNeeded == null ? '' : '• ' + paymentsNeeded + ' Ft'}`}
                        {isVipBrand && getValue('app_v2_basketModal_vip_reserve')}
                      </>
                    )}
                  </>
                )}
                {!appParams?.order?.tableSelectionRequired && (
                  <>
                    {!isVipBrand && `${getValue('app_v2_basketModal_submitOrder')} ${paymentsNeeded == null ? '' : '• ' + paymentsNeeded + ' Ft'}`}
                    {isVipBrand && getValue('app_v2_basketModal_vip_reserve')}
                  </>
                )}
              </>
            )}
            {!canOrderFromSelectedLocation && `${getValue('app_v2_basketModal_selectedLocationClosed')}`}
          </>
        )}
        {reserveLoading && getValue('app_v2_basketModal_please_wait')}
      </Button>
    </>
  );
}
