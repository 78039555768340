import { useDroppable } from "@dnd-kit/core";

export default function Droppable({ children }) {
  const { isOver, setNodeRef } = useDroppable({
    id: "droppable",
  });

  return (
    <div className={"h-full w-full "} ref={setNodeRef}>
      {children}
    </div>
  );
}
