import packageJson from "../package.json"

const isProd = process.env.NODE_ENV === "production"

const config = {
  isProd,
  version: packageJson.version,
  baseUrl: isProd ? null : "https://pbz9fdc3.brands.barsoft.hu",
}

export default config

/*
Zazie
https://837p7q4q.brands.barsoft.hu

Szigliget
https://6d3511ht.brands.barsoft.hu

Puskás
https://5251snop.brands.barsoft.hu

Coruscant
https://9jtueotc.brands.barsoft.hu

FÜLIG:
https://pnrvqvmru.okeoke.io

VINYL:
https://vx09jwel.brands.barsoft.hu

DELICI:
https://jtxwescs.brands.barsoft.hu

OTP:
https://auhmtom3.brands.barsoft.hu

RAMENKA:
https://j2237wzb.brands.barsoft.hu

NEO:
https://v02is6be.brands.barsoft.hu

FRUIT:
https://fruit001.okeoke.io

SIMONS:
https://g9ha1p41.brands.barsoft.hu

MOZATA:
https://pbz9fdc3.brands.barsoft.hu

BARSOFT:
https://pkxdcb8h.brands.barsoft.hu

101:
https://hfa1g20y.brands.barsoft.hu

CSENGERY:
https://xkg0gps7.brands.okeoke.io

https://6w1anxw9.brands.okeoke.io
https://j2237wzb.brands.barsoft.hu
https://zing9191.okeoke.io
https://hfa1g20y.brands.barsoft.hu
*/
