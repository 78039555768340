import {useClient, useDictionary} from '@adamlip/okeoke.client';
import moment from 'moment';
import {NumericFormat} from 'react-number-format';

function SubmittedOrderHeader(props) {
  const {order, onClick} = props;

  const {locations} = useClient();
  const {getValue} = useDictionary();

  const handleClickOnHeader = () => {
    if (typeof onClick === 'function') onClick({orderUUID: order.uuid});
  };

  if (order == null) return null;

  return (
    <div className='orderHeader-root' onClick={handleClickOnHeader}>
      <div>
        {locations != null && (
          <div className='order-location font-large font-bold'>{locations?.[order?.locationUUID]?.name == null ? '' : locations?.[order?.locationUUID]?.name}</div>
        )}
        {order?.tableInfo != null && order?.tableInfo?.name && <div className='order-table-info font-small'>{order?.tableInfo?.name}</div>}
        {/*<div className="order-insertTimestamp font-smallest">{moment(statuses.filter(status => status.statusID === 100)[0]?.insertTimestamp).format('YYYY.MM.DD. HH:mm')}</div>*/}
        <div className='order-totalPrice font-large text-primary'>
          {<NumericFormat value={+order.discountPrice} decimalSeparator=',' suffix=' Ft' thousandSeparator='.' displayType='text' />}
        </div>
        <div className='order-msg font-smallest'>{moment(order?.insertTimestamp).format('YYYY-MM-DD HH:mm')}</div>
        {typeof onClick === 'function' && <div className='order-msg font-smallest'>{getValue('app_v2_submittedOrder_goToDetails')}</div>}
      </div>
      <div className='font-smallest'>
        <div className='order-orderState-label'>{getValue('app_v2_orderState_label')}</div>
        <div className='order-orderState'>{getValue(`app_v2_orderState_${order.orderState}`)}</div>
      </div>
    </div>
  );
}

export default SubmittedOrderHeader;
