import {useClient} from '@adamlip/okeoke.client';
import {BasketContext} from 'contexts/BasketContext';
import {useContext} from 'react';
import {Link} from 'react-scroll';

function CategoryElement({categoryUUID, categories, subCategories, setIsOpen}) {
  const {selectedSubCategory, setSelectedSubCategory, selectedCategory, setSelectedCategory} = useContext(BasketContext);
  const {getName} = useClient();
  const thisCategory = categories?.[categoryUUID];

  return (
    <Link
      activeClass='active'
      to={`orderItemList-byCategory_${categoryUUID}`}
      containerId='orderItemList-byCategory'
      smooth={true}
      duration={500}
      spy={true}
      className='w-full bg-[var(--main-secondary-bg-color)] p-2 rounded-[var(--general-border-radius)] flex flex-col gap-2'
      onClick={() => {
        setSelectedCategory(categoryUUID);
        setIsOpen(false);
      }}>
      <p className='font-bold text-lg'>{getName(thisCategory?.name)}</p>
      {subCategories?.length > 0 && (
        <div className='flex flex-col gap-2 pl-2'>
          {subCategories?.map((subCategory) => {
            let thisSubCategory = categories?.[subCategory.uuid];
            return (
              <Link
                activeClass='active'
                to={`orderItemList-byCategory_${categoryUUID}`}
                containerId='orderItemList-byCategory'
                smooth={true}
                duration={500}
                spy={true}
                key={thisSubCategory?.uuid}
                className='p-2 w-full bg-content1 rounded-[var(--general-border-radius)]'
                onClick={() => {
                  setSelectedCategory(categoryUUID);
                  setIsOpen(false);
                }}>
                <p className='font-semibold text-medium'>{getName(thisSubCategory?.name)}</p>
              </Link>
            );
          })}
        </div>
      )}
    </Link>
  );
}

export default CategoryElement;
