import {useClient, useDictionary} from '@adamlip/okeoke.client';
import {Button, Modal, ModalBody, ModalContent} from '@nextui-org/react';
import useStorage from 'hooks/useStorage';

function Confirm18PlusModal({isOpen, setIsOpen}) {
  const {setSelectedLocation, locations, appParams, selectedLocationUUID} = useClient();
  const {getValue} = useDictionary();
  const {removeSV, setSV} = useStorage();
  const handleOVer18 = () => {
    setSV('alreadyConfirmedAge', true);
    setIsOpen(false);
  };
  const handleUnder18 = () => {
    window.location.href = 'https://www.google.com';
  };
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => setIsOpen(!isOpen)}
      size='full'
      isDismissable={false}
      hideCloseButton={true}
      classNames={{
        base: 'overflow-hidden flex flex-col max-h-full m-0 max-w-[820px]',
        closeButton: 'z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]',
      }}>
      <ModalContent className={`${appParams?.styles?.fonts?.capitalize ? 'font-extrabold uppercase' : ''}`}>
        {(onClose) => (
          <>
            <ModalBody className='grow flex flex-col gap-2 overflow-hidden overflow-y-scroll items-center justify-center'>
              <h1 className='font-bold text-2xl text-center w-full'>{getValue('app_v2_Over18question')}</h1>
              <p className='text-center w-full'>{getValue('app_v2_Over18desc')}</p>
              <div className='flex flex-row gap-2'>
                <Button color='primary' onPress={() => handleOVer18()}>
                  {getValue('app_v2_yes')}
                </Button>
                <Button color='primary' variant='bordered' onPress={() => handleUnder18()}>
                  {getValue('app_v2_no')}
                </Button>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}

export default Confirm18PlusModal;
