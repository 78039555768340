import { useDictionary } from "@adamlip/okeoke.client";
import { Tab, Tabs } from "@nextui-org/react";
import DeliveryAddress from "components/DeliveryAddress";
import useBasket from "hooks/useBasket";
import { useState, useContext, useEffect } from "react";
import { AppParamsContext } from "contexts/AppParamsContext";
import CarPickUp from "./CarPickUp";
import useStorage from "hooks/useStorage";

export default function WhereTabs({
  setDelivery,
  setCarPickUp,
  carPickUp,
  onSelectTab,
}) {
  const { appParams } = useContext(AppParamsContext);
  const { getValue } = useDictionary();
  const { setTakeAway, setDeliveryInfo, setNotes } = useBasket();
  const { getSV } = useStorage();

  const [selectedKey, setSelectedKey] = useState("takeAway");

  const handleChangeTakeAway = (value) => {
    setTakeAway(value);
    setDeliveryInfo(null);
    setDelivery(false);
    setCarPickUp(false);
    setSelectedKey(value ? "takeAway" : "eatIn");
    if (carPickUp?.length > 0) {
      setNotes("");
    }
  };

  const handleSelectDelivery = () => {
    setDelivery(true);
    setTakeAway(true);
    setCarPickUp(false);
    setSelectedKey("delivery");
    if (carPickUp?.length > 0) {
      setNotes("");
    }
  };

  const handleSelectCarPickUp = () => {
    setDelivery(false);
    setCarPickUp(getSV("cardID"));
    setTakeAway(true);
    setSelectedKey("carPickUp");
  };

  const handleKey = (key) => {
    if (typeof onSelectTab === "function") onSelectTab(key);
    switch (key) {
      case "takeAway":
        handleChangeTakeAway(true);
        break;
      case "eatIn":
        handleChangeTakeAway(false);
        break;
      case "delivery":
        handleSelectDelivery();
        break;
      case "carPickUp":
        handleSelectCarPickUp();
        break;
    }
  };

  if (
    !(
      appParams?.basketModal?.whereWhen?.takeAway ||
      appParams?.basketModal?.whereWhen?.eatIn ||
      appParams?.basketModal?.whereWhen?.delivery ||
      appParams?.basketModal?.whereWhen?.carPickUp
    )
  )
    return null;

  useEffect(() => {
    setSelectedKey(
      appParams?.basketModal?.whereWhen?.defaultWhere || "takeAway"
    );
  }, []);

  return (
    <Tabs
      fullWidth
      selectedKey={selectedKey}
      classNames={{
        tab: appParams?.styles?.fonts?.capitalize
          ? "font-extrabold uppercase"
          : "",
      }}
      onSelectionChange={handleKey}
    >
      {appParams?.basketModal?.whereWhen?.takeAway && (
        <Tab
          key="takeAway"
          title={<div>{getValue("app_v2_basket_slider_take_away_btn")}</div>}
        ></Tab>
      )}
      {appParams?.basketModal?.whereWhen?.eatIn && (
        <Tab
          key="eatIn"
          title={<div>{getValue("app_v2_basket_slider_take_away_eat_in")}</div>}
        ></Tab>
      )}
      {appParams?.basketModal?.whereWhen?.delivery && (
        <Tab
          className="w-full"
          key="delivery"
          title={
            <div>{getValue("app_v2_basket_slider_take_away_delivery")}</div>
          }
        >
          <DeliveryAddress setInOrder={true} />
        </Tab>
      )}
      {appParams?.basketModal?.whereWhen?.carPickUp && (
        <Tab
          className="w-full"
          key="carPickUp"
          title={<div>{getValue("app_v2_basket_slider_take_away_car")}</div>}
        >
          <CarPickUp setCarPickUp={setCarPickUp} />
        </Tab>
      )}
    </Tabs>
  );
}
