import {OrderStates, useClient, useDictionary, useOrder, useOrders} from '@adamlip/okeoke.client';
import {CircularProgress, Modal, ModalBody, ModalContent} from '@nextui-org/react';
import OrderViewItems from 'components/OrderViewItems';

import SubmittedOrderHeaderV2 from 'components/SubmittedOrder/SubmittedOrderHeaderV2';
import {AppParamsContext} from 'contexts/AppParamsContext';
import moment from 'moment';
import {QRCodeSVG} from 'qrcode.react';
import {useContext, useEffect, useState} from 'react';

export default function OrderDetailsModal({isOpen, setIsOpen, orderUUID, lastOrder}) {
  const {appParams} = useContext(AppParamsContext);
  const {client, socketAuthenticated, locations} = useClient();
  const id = orderUUID;
  const pos = id == null ? -1 : id.search('-');
  const [uuid, setUuid] = useState(pos > 0 ? id.substring(0, pos) : id == null ? orderUUID : id);
  const {orders} = useOrders();
  const {paymentState, order, orderState, locationUUID, orderNumber, tableInfo, statuses} = useOrder(null, uuid);
  const {getValue} = useDictionary();
  const [lastPart, setLastPart] = useState(null);
  const [estPrepTime, setEstPrepTime] = useState(null);

  //const [active, setActive] = useState(false)

  useEffect(() => {
    if (lastOrder) {
      let ordersArray = Object.values(orders || {});
      let lastFromArray = ordersArray.length > 0 ? ordersArray[ordersArray.length - 1] : null;
      if (lastFromArray?.uuid != null) setUuid(lastFromArray?.uuid);
    }
  }, [orders, lastOrder]);

  const [orderItemsAssembled, setOrderItemsAssembled] = useState(null);
  const [serviceFee, setServiceFee] = useState(null);

  useEffect(() => {
    if (order?.order.uuid != null && socketAuthenticated) {
      client.getUserOrder(order?.order.uuid).then((res) => {
        if (res.success) {
          setOrderItemsAssembled(res.data.items);
          setServiceFee(res.data.serviceFee);
        }
      });
    }
    // eslint-disable-next-line
  }, [order?.order.uuid, socketAuthenticated]);

  useEffect(() => {
    if (statuses) {
      for (let status of statuses) {
        if (status?.statusID === 202) {
          setEstPrepTime(moment(status?.insertTimestamp).add(status?.notes?.minutes, 'minutes').format('HH:mm'));
        }
      }
    }
  }, [statuses]);
  const statusText = (state) => {
    switch (state) {
      case null:
        return '';
      case OrderStates.INSERTED:
        return getValue('app_v2_order_orderInserted');
      case OrderStates.SEEN:
        return getValue('app_v2_order_orderSeen');
      case OrderStates.SCHEDULED:
        return getValue('app_v2_order_orderSeen');
      case OrderStates.PREPARING:
        return getValue('app_v2_order_orderPreparing');
      case OrderStates.DONE:
        return getValue('app_v2_order_orderCollectable');
      case OrderStates.CLOSED_SUCCESS:
        return getValue('app_v2_order_orderDone');
      default:
        return '';
    }
  };
  const getProgressValue = () => {
    let value = 0;
    if (orderState >= OrderStates.SEEN) value = 25;
    if (orderState >= OrderStates.PREPARING) value = 50;
    if (orderState >= OrderStates.DONE) value = 75;
    if (orderState >= OrderStates.CLOSED_SUCCESS) value = 100;
    return value;
  };
  useEffect(() => {
    if (orderNumber) {
      const parts = orderNumber?.split('/');

      setLastPart(parts[parts?.length - 1]);
    }
  }, [orderNumber]);
  console.log('order', order?.order, tableInfo);
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => setIsOpen(!isOpen)}
      classNames={{
        base: 'max-h-[80vh]',
        body: '!p-0 overflow-y-scroll',
        closeButton: 'z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]',
      }}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody>
              <div className='flex flex-col gap-0 relative bg-[var(--main-secondary-bg-color)] overflow-y-scroll h-full w-full '>
                <div className='w-full h-[200px] bg-[var(--main-secondary-bg-color)] min-h-[200px]'></div>
                <div className='flex w-full relative items-center justify-center flex-col gap-2 bg-background  rounded-tl-[50px] rounded-tr-[50px] grow'>
                  <div className='p-[-5px] bg-background relative top-[-90px] rounded-full'>
                    {order?.order?.location?.fruitQrPrefix && order?.order?.location?.extOM === 1 && order?.order?.orderState === 5 ? (
                      <div className='w-[160px] h-[160px] overflow-hidden rounded-[20px]'>
                        <QRCodeSVG
                          value={`${order?.order?.uuid}${order?.order?.location?.fruitQrPrefix}`}
                          includeMargin={true}
                          renderAs='svg'
                          size={160}
                          level='H'
                          bgColor={appParams?.styles?.qrCode?.bgColor || '#FFFFFF'}
                          fgColor={appParams?.styles?.qrCode?.fgColor || '#000000'}
                        />
                      </div>
                    ) : (
                      <CircularProgress
                        aria-label='progress'
                        classNames={{
                          svg: 'w-full h-full drop-shadow-md ',
                          indicator: 'stroke-primary ',
                          track: 'stroke-white/10  ',
                          value: 'text-3xl font-semibold text-white',
                          base: 'w-full  ',
                        }}
                        value={getProgressValue()}
                        strokeWidth={4}
                        showValueLabel={true}
                        valueLabel={
                          <div className='flex flex-col items-center justify-center'>
                            <div className='text-primary text-6xl'>{lastPart} </div>
                            <div className='text-primary text-xl'>{tableInfo && tableInfo?.name && `(${tableInfo?.name})`}</div>
                            <div className='order-status-text text-sm text-foreground'>{statusText(orderState)}</div>
                            {orderState < 90 && (
                              <div className='text-tiny text-foreground max-w-[170px] text-center'>
                                {getValue('app_v2_order_expectedPrepTime')}
                                <br />
                                {estPrepTime == null && (
                                  <>
                                    {locations[locationUUID]?.defaultPrepTime || 20} {getValue('app_v2_order_expectedPrepTime_minutes')}
                                  </>
                                )}
                                {estPrepTime != null && <>{estPrepTime}</>}
                                {/* {getValue("app_v2_order_expectedPickup")}{" "}
                      {moment(order?.order?.insertTimestamp)
                        .add(
                          locations[locationUUID]?.defaultPrepTime || 20,
                          "minutes"
                        )
                        .format("HH:mm")}{" "} */}
                              </div>
                            )}
                          </div>
                        }
                      />
                    )}
                  </div>

                  <div className='top-[-90px] relative w-full p-2 h-full'>
                    <SubmittedOrderHeaderV2 order={order?.order} />
                    <div className='flex flex-col gap-2 mt-2'>
                      {Object.values(orderItemsAssembled || {})
                        ?.filter((item) => item?.count > 0)
                        ?.map((item) => {
                          return <OrderViewItems item={item} key={item?.orderItemUUID} />;
                        })}
                    </div>

                    {/* <div className="btn primary" onClick={navigateToOrderDetails}>{getValue("app_v2_orderview_goToDetails")}</div> */}
                    {/*orderState < 90 && <div className={`btn ${active ? "primary" : "disabled"}`} onClick={handleClickOnFollow}>{"Követem"}</div>*/}
                  </div>
                </div>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}

/*
<div className="order-view">
  <OrderStatus
    orderUUID={uuid}
    paymentInProgress={paymentState.paymentInProgress}
  />
  <div className="orderview-order-details">
    <SubmittedOrderHeaderV2 order={order?.order} />
    <SubmittedOrderItemListV2
      orderUUID={order?.order.uuid}
      details={true}
    />
  </div>
</div>
*/
