import {useDroppable} from '@dnd-kit/core';
import Icon from 'assets/icons';

export default function DroppableBin({children}) {
  const {isOver, setNodeRef} = useDroppable({
    id: 'bin',
  });

  return (
    <div className={'h-[140px] bottom-0  w-full absolute z-[60] bg-gradient-to-t from-background to-transparent'} ref={setNodeRef}>
      {children}
      <div className='w-full h-full flex items-center justify-center'>
        <div className='w-16 h-16'>
          <Icon name='orderStatusDelete' className={isOver ? 'stroke-primary' : 'stroke-white'} secondClassName={isOver ? 'fill-primary' : 'fill-white'} />
        </div>
      </div>
    </div>
  );
}
