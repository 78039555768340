import { useClient, useDictionary, useOrder } from "@adamlip/okeoke.client";
import PaymentAgentSelector from "components/PaymentAgentSelector";
import { AppParamsContext } from "contexts/AppParamsContext";
import useBasket from "hooks/useBasket";
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";

function PaymentTypeSelector({
  onSelectPayment,
  usableMoney,
  payByVipMoney,
  setPayByVipMoney,
}) {
  const { id } = useParams();
  const { locations, selectedLocationUUID } = useClient();
  const { appParams } = useContext(AppParamsContext);
  const { getValue } = useDictionary();
  const { paymentsNeeded, isReady, orderNumber, totalPrice, discountAmount } =
    useOrder(null, id);
  const navigate = useNavigate();
  const { handleChangePaymentType, selectedPayment, setSelectedPayment } =
    useBasket();

  const paymentAgents = locations?.[selectedLocationUUID]?.paymentAgents;

  const handleSelectPaymentAgent = (agent) => {
    handleChangePaymentType("Online");
    setSelectedPayment(agent);
    if (typeof onSelectPayment === "function") onSelectPayment(agent);
  };

  // const navigateToPayment = () => {
  //   let profileType = selectedPayment?.useOnline?.profileType;
  //   let paymentAgentID = selectedPayment?.paymentAgentID;
  //   console.log("navigateToPayment", profileType, paymentAgentID);
  //   if (profileType != null && paymentAgentID != null) {
  //     navigate(
  //       `/orderpay/${profileType.toLowerCase()}/${id}-${paymentAgentID}`
  //     );
  //   } else {
  //     toast.error("app_v2_invalidPaymentAgent");
  //   }
  // };

  if (!isReady) return null;
  // console.log(paymentAgents);

  // if (paymentAgents.filter((agent) => agent.agentType === 'online').length === 0) return <div className='no-payments'>{getValue('app_v2_noPaymentAgents')}</div>;

  if (isReady && paymentsNeeded === 0)
    return <div className="payed-text">{getValue("app_v2_orderPayed")}</div>;
  return (
    <div
      className={`${
        appParams?.styles?.fonts?.capitalize ? "font-extrabold uppercase" : ""
      } payment-select-container gap-2 mt-2 h-auto`}
    >
      <div className="px-2">
        {getValue("app_v2_basket_slider_payment_agents")}
      </div>
      <PaymentAgentSelector
        paymentAgents={paymentAgents.filter(
          (agent) => agent.agentType === "online"
        )}
        paymentsNeeded={paymentsNeeded}
        selectedID={selectedPayment?.paymentAgentID}
        selectPaymentAgent={handleSelectPaymentAgent}
        usableMoney={usableMoney}
        payByVipMoney={payByVipMoney}
        setPayByVipMoney={setPayByVipMoney}
      />
      <div className="start-payment">
        {orderNumber != null && (
          <div className="order-number">
            #{orderNumber} {getValue("app_v2_order")}
          </div>
        )}
        <div className="payment-row">
          <div className="label">{getValue("app_v2_payment_totalPrice")}:</div>
          <div className="value">
            <NumericFormat
              value={+totalPrice}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
        </div>
        <div className="payment-row">
          <div className="label">
            {getValue("app_v2_payment_paymentsNeeded")}:
          </div>
          <div className="value">
            <NumericFormat
              value={+paymentsNeeded}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentTypeSelector;
