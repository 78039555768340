import {
  OEAsset,
  useClient,
  useDictionary,
  useItem,
} from "@adamlip/okeoke.client"
import {
  Button,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ScrollShadow,
} from "@nextui-org/react"
import ChangeCountButton from "components/ChangeCountButton"
import UpsaleItem from "components/UpsaleItem"
import { useContext, useEffect, useRef, useState } from "react"
import toast from "react-hot-toast"
import { Element } from "react-scroll"
import LoadSvg from "utils/LoadSvg-v3"
import LoginModal from "../../../views/Login"
import ModifierGroupSelector from "./ModifierGroupSelector"
import SizeSelector from "./SizeSelector"
import { UserContext } from "contexts/UserContext"
import { Switch } from "@nextui-org/react"
import useBasket from "hooks/useBasket"
import { AppParamsContext } from "contexts/AppParamsContext"
import { motion } from "framer-motion"
function OrderItemModalV3({ isOpen, setIsOpen, itemUUID, readOnly, sizeUUID }) {
  const { getName } = useClient()
  const { appParams } = useContext(AppParamsContext)
  const { getValue } = useDictionary()
  const {
    assembledItem,
    setSize,
    setCount,
    setModifierCount,
    setTakeAway,
    setNotes,
    getUpsales,
  } = useItem(itemUUID, sizeUUID)
  const { addItem } = useBasket()
  const touchRef = useRef(null)
  //const { orders } = useOrders();

  const { userLoggedIn } = useContext(UserContext)

  const [itemNotes, setItemNotes] = useState("")
  const [allergens, setAllergens] = useState([])
  const [upsaleItems, setUpsaleItems] = useState([])
  const [tryedToAdd, setTryedToAdd] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [loginModal, setLoginModal] = useState(false)

  useEffect(() => {
    if (assembledItem != null) {
      let items = getUpsales()
      if (Array.isArray(items)) setUpsaleItems(items)
    }
    // eslint-disable-next-line
  }, [assembledItem])

  useEffect(() => {
    if (
      assembledItem != null &&
      typeof setCount === "function" &&
      (assembledItem.count < 1 || assembledItem.count == null)
    )
      setCount(1)

    if (assembledItem != null) {
      let allergenArray = []
      for (let [key, value] of Object.entries(assembledItem.allergens)) {
        if (value != null && value > 0) allergenArray.push(key)
      }
      setAllergens(allergenArray)
    }
    // eslint-disable-next-line
  }, [assembledItem])

  const addItemToBasket = () => {
    if (readOnly) return null
    //console.log("Add item", orderUUID);
    if (userLoggedIn) {
      if (assembledItem.count > 0 && assembledItem.valid) {
        let response = addItem(assembledItem)
        //Logger.add("Item added, id: " + response);
        //console.log("Item added, id: " + response);
        if (response != null) {
          toast.success(getValue("app_v2_toast_itemAdded"))
          setTryedToAdd(false)
          setIsOpen(false)
        } else {
          console.log(response)

          toast.error(getValue("app_v2_toast_itemFailed"))
        }
      } else {
        setTryedToAdd(true)
        // console.log(assembledItem);
        // let firstInvalidItem = assembledItem.modifierGroups.find(
        //   (group) => group.valid === false
        // )?.uuid;
        // setFirstInvalid(firstInvalidItem);
        // if (firstInvalidItem != null) {
        //   const options = {
        //     duration: 1500,
        //     delay: 100,
        //     smooth: true,
        //     containerId: "scrollContainer",
        //     offset: -40,
        //   };
        //   scroller.scrollTo("11EE6DD359DE1E00ACA58B0B80351133", options);
        // }

        toast.error(getValue("app_v2_toast_itemFailed"))
      }
    } else {
      setLoginModal(true)
    }
  }

  const changeCount = (count) => {
    setCount(count)
  }

  const handleChangeNotes = (e) => setItemNotes(e.target.value)

  const handleBlurNotes = (e) => setNotes(itemNotes?.slice(0, 100))

  const displayDescription = (description) => {
    const descriptionLength = description?.length
    if (descriptionLength > 250) {
      return (
        <div
          className={`px-4 text-sm opacity-60 w-full ${
            showMore ? "show-more-content show-more" : "show-more-content"
          } ${
            appParams?.styles?.fonts?.capitalize ? "font-bold !uppercase" : ""
          } ${renderExtraClass(itemDescStyle, true)}`}
        >
          {showMore ? description : description.slice(0, 120) + "..."}
          <span
            className="ml-2 text-primary font-bold"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore
              ? getValue("app_v2_showLess")
              : getValue("app_v2_showMore")}
          </span>
        </div>
      )
    } else {
      return (
        <div
          className={`px-4 text-sm opacity-60  text-clip w-full ${renderExtraClass(
            itemDescStyle,
            true
          )}}`}
        >
          {description}
        </div>
      )
    }
  }
  const itemNameStyle = appParams?.items?.itemModalStyle?.itemName
  const itemDescStyle = appParams?.items?.itemModalStyle?.itemDescription
  const addToBasketStyle = appParams?.items?.itemModalStyle?.addToBasketButton
  const changeCountStyle = appParams?.items?.itemModalStyle?.changeCountButton
  const renderExtraClass = (type, isDescription) => {
    let extraClass = ""
    extraClass += ` ${
      type?.fontSize || (isDescription ? "text-xs" : "text-sm")
    } ${type?.fontWeight || (isDescription ? "font-light" : "font-bold")}`
    if (type?.uppercase) {
      extraClass += ` !uppercase `
    }
    if (type?.fontColor) {
      extraClass += ` text-[${type?.fontColor}]`
    }
    if (type?.borderRadius) {
      extraClass += ` ${
        type?.borderRadius || "rounded-[var(--order-button-border-radius)]"
      }`
    }

    return extraClass
  }
  if (assembledItem == null) return null

  return (
    <>
      {loginModal && (
        <LoginModal
          isOpen={loginModal}
          setIsOpen={setLoginModal}
          canGuestLogin={true}
        />
      )}
      <Modal
        isOpen={isOpen}
        onOpenChange={() => {
          setIsOpen(!isOpen)
        }}
        size="full"
        className="bg-background"
        placement="bottom-center"
        scrollBehavior="inside"
        classNames={{
          base: "m-0 max-w-[820px] bottom-0 ",
          wrapper: "sm:items-end",
          closeButton:
            "z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]",
        }}
        motionProps={{
          initial: { y: 890 },
          animate: {
            y: 0,
            transition: { duration: 0.5, type: "spring", stiffness: 50 },
          },
          exit: { y: 890, transition: { duration: 0.5 } },
        }}
      >
        <ModalContent className="!rounded-tl-[var(--modal-radius)] !rounded-tr-[var(--modal-radius)] overflow-hidden">
          {(onClose) => (
            <>
              <ModalBody className="p-0">
                <ScrollShadow className="pb-16 realtive w-full grow overflow-x-hidden scrollArea !relative">
                  {assembledItem.availability.available === 0 && (
                    <div className="orderItem-modal-notAvailable font-small text-center mb-1">
                      {getValue("app_v2_orderItem_notAvailable")}
                    </div>
                  )}
                  <div className="orderItem-modal-body " id="scrollContainer">
                    {(appParams?.items?.showImage ||
                      appParams?.items?.showImage === undefined) &&
                      appParams?.assets?.defaultItemImg != null && (
                        <motion.div
                          className={`orderItemListItem-img-container ${
                            appParams?.styles?.items?.containItemModalImage
                              ? "bg-white"
                              : ""
                          }`}
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ duration: 0.7, delay: 0.1 }}
                          exit={{ scale: 0 }}
                        >
                          <OEAsset
                            uuid={assembledItem.attributes.appImage}
                            addClass={`w-full aspect-[4/3] max-h-[200px] flex flex-start overflow-hidden  ${
                              appParams?.styles?.items?.containItemModalImage
                                ? "img-contain object-contain"
                                : "object-cover"
                            }`}
                            size="l"
                          />
                        </motion.div>
                      )}
                  </div>
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.7, delay: 0.1 }}
                    className="z-50 sticky top-0 pt-4 bg-[var(--main-bg-color)]"
                    onTouchStart={(e) => {
                      touchRef.current = e?.touches?.[0]?.screenY
                    }}
                    onTouchEnd={(e) => {
                      // if (touchRef.current - e?.changedTouches?.[0]?.screenY < -150) {
                      //   setIsOpen(false);
                      // }
                    }}
                  >
                    <div className="px-4 pt-2 pb-2 flex flex-row gap-1 bg-[var(--main-bg-color)] items-center !relative z-50">
                      <div
                        id="desc"
                        className={`orderItem-modal-itemName font-extraLarge ${
                          appParams?.styles?.fonts?.capitalize
                            ? "font-extrabold uppercase"
                            : ""
                        } ${renderExtraClass(itemNameStyle)}`}
                        style={{
                          color: itemNameStyle?.fontColor || "",
                        }}
                      >
                        {getName(assembledItem.name)}
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.7, delay: 0.1 }}
                    className=""
                    style={{
                      color: itemDescStyle?.fontColor || "",
                    }}
                  >
                    {displayDescription(getName(assembledItem.description))}
                  </motion.div>
                  <motion.div
                    className="w-full flex flex-wrap gap-2 flex-row px-2"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.7, delay: 0.1 }}
                  >
                    {allergens.map((alle, index) => (
                      <div key={index} className="allergen-icon">
                        <LoadSvg key={index} name={alle} />
                      </div>
                    ))}
                  </motion.div>
                  <motion.div
                    className="px-2"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.7, delay: 0.1 }}
                  >
                    {allergens.length > 0 && (
                      <span className=" text-small">
                        <span className="title">
                          - {getValue("app_v2_allergens")}:{" "}
                        </span>
                        {allergens.map((alle, index) => (
                          <span key={index} className="allergen">
                            {getValue("app_v2_allergens_" + alle)}
                            {allergens.length !== index + 1 && ", "}
                          </span>
                        ))}
                      </span>
                    )}
                    <div className="item-tags mt-2">
                      {Array.isArray(assembledItem.tags) &&
                        assembledItem.tags.includes("meat") && (
                          <LoadSvg name={"meat"} />
                        )}
                      {Array.isArray(assembledItem.tags) &&
                        assembledItem.tags.includes("lactoseFree") && (
                          <LoadSvg name={"milk"} />
                        )}
                      {Array.isArray(assembledItem.tags) &&
                        assembledItem.tags.includes("vegan") && (
                          <LoadSvg name={"vegan"} />
                        )}
                      {Array.isArray(assembledItem.tags) &&
                        assembledItem.tags.includes("glutenFree") && (
                          <LoadSvg name={"glutenFree"} />
                        )}
                    </div>
                  </motion.div>
                  {sizeUUID == null && assembledItem.sizes?.length > 1 && (
                    <motion.div
                      className="orderItem-modal-itemSizes z-10"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ duration: 0.7, delay: 0.1 }}
                    >
                      {/* <OrderItemSizeSelector sizes={assembledItem.sizes} setSize={setSize} selectedSizeUUID={assembledItem.selectedSizeUUID} /> */}
                      <SizeSelector
                        sizes={assembledItem.sizes}
                        setSize={setSize}
                        selectedSizeUUID={assembledItem.selectedSizeUUID}
                      />
                    </motion.div>
                  )}
                  <motion.div
                    className=" px-4 flex flex-col gap-2 w-full z-0 !relative "
                    initial={{ y: 500 }}
                    animate={{ y: 0 }}
                    transition={{ duration: 0.7, delay: 0.1 }}
                  >
                    {assembledItem.modifierGroups
                      ?.filter((group) => group?.availability?.visible === 1)
                      .sort((a, b) => (a?.min > b?.min ? 1 : -1))
                      .sort((a, b) => (a?.idx > b?.idx ? 1 : -1))
                      // .filter(group => (group.availability.available === 1 && group.availability.visible === 1))
                      .map((group, index) => {
                        return (
                          <Element key={group.uuid} name={group.uuid}>
                            <ModifierGroupSelector
                              key={group.uuid}
                              firstInvalid={null}
                              group={group}
                              setModifierCount={setModifierCount}
                              readOnly={readOnly}
                              tryedToAdd={tryedToAdd}
                              name={group?.uuid}
                            />
                            {/* <OrderItemModifierGroupSelector key={group.uuid} firstInvalid={firstInvalid} group={group} setModifierCount={setModifierCount} readOnly={readOnly} tryedToAdd={tryedToAdd} name={group?.uuid} /> */}
                            {index !==
                              assembledItem.modifierGroups?.length - 1 && (
                              <Divider className="my-4 bg-[var(--main-border-color)]" />
                            )}
                          </Element>
                        )
                      })}
                    {!readOnly &&
                      !(appParams?.items?.takeAwayHidden === true) && (
                        <div className="my-4">
                          <Switch
                            isSelected={assembledItem.takeAway}
                            onValueChange={() =>
                              setTakeAway(assembledItem.takeAway ? 0 : 1)
                            }
                          >
                            {getValue("app_v2_orderItem_takeAway")}
                          </Switch>
                        </div>
                      )}

                    {!readOnly && !(appParams?.items?.notesHidden === true) && (
                      <div className="orderItemModifierGroup-modifier-root orderItem-modal-notes font-small">
                        <Divider className="my-4 bg-[var(--main-border-color)]" />
                        <Input
                          aria-label="input"
                          type="text"
                          classNames={{
                            inputWrapper:
                              "bg-[var(--input-bg-color)] text-[var(--input-text-color)]",
                            label: `${
                              appParams?.styles?.fonts?.capitalize
                                ? "font-bold uppercase"
                                : ""
                            } group-data-[filled-within=true]:text-[var(--input-text-color)] text-[var(--input-text-color)] group-data-[filled-within=true]:text-[12px]`,
                          }}
                          name="itemNotes"
                          label={getValue("app_v2_orderItem_notes")}
                          value={itemNotes}
                          fullWidth={true}
                          onChange={handleChangeNotes}
                          onBlur={handleBlurNotes}
                        />
                      </div>
                    )}
                    {!readOnly &&
                      upsaleItems != null &&
                      upsaleItems.length > 0 && (
                        <>
                          <div className="text-center mb-05 mt-2 font-bold">
                            {getValue("app_v2_upsellTitle")}
                          </div>
                          <div className="upsell-items-container">
                            {upsaleItems.map((item, index) => (
                              <UpsaleItem
                                key={index}
                                itemUUID={item.itemUUID}
                              />
                            ))}
                          </div>
                        </>
                      )}
                  </motion.div>
                </ScrollShadow>
                <motion.div
                  className="w-full flex flex-row  justify-between px-4 pb-4  gap-4 z-10"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.7, delay: 0.1 }}
                >
                  {!readOnly && (
                    <ChangeCountButton
                      count={assembledItem.count}
                      changeCount={changeCount}
                      type="stackableMultiple"
                      changeCountStyle={changeCountStyle}
                    />
                  )}

                  <Button
                    onPress={addItemToBasket}
                    color="primary"
                    style={{
                      borderRadius:
                        addToBasketStyle?.borderRadius ||
                        "var(--order-button-border-radius)",
                    }}
                    size="lg"
                    className={`grow flex rounded-[var(--order-button-border-radius)] font-bold flex-row justify-between max-w-[200px] ${
                      assembledItem?.count > 0 && assembledItem?.valid
                        ? ""
                        : "opacity-50"
                    }  ${
                      appParams?.styles?.fonts?.capitalize
                        ? "font-bold !uppercase"
                        : ""
                    } ${renderExtraClass(addToBasketStyle)}`}
                  >
                    <span className="font-semibold">
                      {readOnly
                        ? getValue("app_v2_item_price")
                        : getValue("app_v2_addToBasket")}
                    </span>
                    <span className="total-price">{`${
                      assembledItem?.totalPrice || 0
                    } Ft`}</span>
                  </Button>
                </motion.div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

export default OrderItemModalV3
