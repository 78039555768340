import "style.scss";
import "./index.css";

import {
  OkeFrameProvider,
  OrderEngineProvider,
  BillingProvider,
  OkeVipCustomerProvider,
  OkeMessagingProvider,
} from "@adamlip/okeoke.client";
import LoadingScreen from "components/LoadingScreen";
import { Suspense, lazy } from "react";
import { isMobile } from "react-device-detect";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "reportWebVitals";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import ErrorFallback from "utils/ErrorHandler-v2";
//const testToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VVVUlEIjoiIiwicGxheWVySUQiOm51bGwsInVzZXJVVUlEIjoiMTFFQTlDM0YyMEE5QzBEMDhGMEMxMTYyMkEwRUU4NjkiLCJ0b2tlblR5cGUiOiJ1c2VyIiwidG9rZW5VVUlEIjoiMTFlYzM4MDBkZDk0OTM2MDgzMjAxZGVhMjA5ZjExMjUiLCJpYXQiOjE2MzU0MzM2OTEsImV4cCI6MTYzODAyNTY5MX0.n-f-UGpNoXZQUqkVIVmsgdI4AEqyG0S5OvaIfAR2IgE"
import { NextUIProvider } from "@nextui-org/react";
import appConfig from "appConfig";
import { BasketContextProvider } from "contexts/BasketContext";
import { FloatingOpenOrdersContextProvider } from "contexts/FloatingOrdersContext";
//import { IntercomContextProvider } from "contexts/IntercomContext";
import { MainContextProvider } from "contexts/MainContext";
import { OpenReplayContextProvider } from "contexts/OpenReplayContext";
import { UserContextProvider } from "contexts/UserContext";
import { AppParamsContextProvider } from "contexts/AppParamsContext";

const App = lazy(() => import("App"));

const container = document.getElementById("root");
const root = createRoot(container);
const pathName = window.location.pathname;
// if (pathName !== '/brandApp' && pathName !== '/brandApp/') {
//   window.location.href = '/brandApp';
// }
root.render(
  <BrowserRouter basename={"/brandApp"}>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <OkeFrameProvider>
        <OrderEngineProvider
          baseUrl={appConfig.baseUrl}
          app={"brandApp"}
          token={
            localStorage[
              `brandAppV2-${
                appConfig.baseUrl == null ? "prod" : appConfig.baseUrl
              }-apiToken`
            ]
          }
        >
          <OkeMessagingProvider>
            <BillingProvider>
              <OkeVipCustomerProvider>
                <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                  <Suspense
                    fallback={
                      <LoadingScreen
                        loading={true}
                        message={"App loading..."}
                      />
                    }
                  >
                    {/* <IntercomContextProvider> */}
                    <AppParamsContextProvider>
                      <UserContextProvider>
                        <BasketContextProvider>
                          <FloatingOpenOrdersContextProvider>
                            <OpenReplayContextProvider>
                              <MainContextProvider>
                                <NextUIProvider>
                                  <App />
                                </NextUIProvider>
                              </MainContextProvider>
                            </OpenReplayContextProvider>
                          </FloatingOpenOrdersContextProvider>
                        </BasketContextProvider>
                      </UserContextProvider>
                    </AppParamsContextProvider>
                    {/* </IntercomContextProvider> */}
                  </Suspense>
                </DndProvider>
              </OkeVipCustomerProvider>
            </BillingProvider>
          </OkeMessagingProvider>
        </OrderEngineProvider>
      </OkeFrameProvider>
    </ErrorBoundary>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
