export const Helpers = {
    replaceAll: function (str, mapObj){
        if(str == null) return null
        let re = new RegExp(Object.keys(mapObj).join("|"),"gi")
    
        return str.replace(re, function(matched){
            return mapObj[matched.toLowerCase()]
        })
    },
    sendMessageToWebView: function (payload){
        try {
            if ((window.ReactNativeWebView) && (typeof window.ReactNativeWebView.postMessage === "function")) { 
              window.ReactNativeWebView.postMessage(payload)
            }
            if (window.Android != null) {
              window.Android.postMessage(payload)
            }
            if (window.webkit?.messageHandlers?.IOS != null ) {
              window.webkit.messageHandlers.IOS.postMessage(payload)
            }
          } catch (e) {
            console.error(e)
          }
    }
}