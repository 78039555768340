import { useClient, useDictionary } from "@adamlip/okeoke.client"
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ScrollShadow,
} from "@nextui-org/react"
import SelectTableModal from "components/SelectTableModal"
import { BasketContext } from "contexts/BasketContext"
import useBasket from "hooks/useBasket"
import L from "leaflet"
import { useContext, useEffect, useState } from "react"
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet"
import { AppParamsContext } from "contexts/AppParamsContext"
import { isDesktop } from "react-device-detect"

export default function SelectLocationModal({
  isOpen,
  setIsOpen,
  isDismissable,
}) {
  const { setSelectedLocation, locations, selectedLocationUUID } = useClient()
  const { appParams } = useContext(AppParamsContext)
  const { setIsUserSelectedLocation } = useContext(BasketContext)

  const { getValue } = useDictionary()
  const { resetTable, savedTable } = useBasket()
  const [centerCoords, setCenterCoords] = useState(null)
  const [map, setMap] = useState(null)
  const [selectTableOpen, setSelectTableOpen] = useState(false)
  const [selectedStyle, setSelectedStyle] = useState("")
  const [selectedLocalLocationUUID, setSelectedLocalLocationUUID] =
    useState(null)

  const handleLocationChange = (uuid) => {
    if (uuid !== savedTable?.locationUUID) {
      onClickResetTable()
    }
    setSelectedLocalLocationUUID(uuid)
    //console.log("Local location set", uuid);
    setIsUserSelectedLocation(true)
    map &&
      map.flyTo(
        [
          parseFloat(locations[uuid].geolocation?.lat ?? 0),
          parseFloat(locations[uuid].geolocation?.lng ?? 0),
        ],
        16
      )
  }

  const openSelectTableModal = () => {
    setSelectTableOpen(true)
  }

  const onClickResetTable = () => {
    resetTable()
  }

  const LocationMarkers = () => {
    const map = useMap()

    const iconURL =
      appParams?.assets?.mapMarker ||
      "https://cdn.okeoke.io/global/assets/marker.png"

    console.log(iconURL)

    const markerIcon = new L.Icon({
      iconUrl: iconURL,
      iconSize: [69, 69],
    })

    let markers = Object.values(locations || {})
      .filter((location) => location.enabled)
      .map((location) => (
        <Marker
          key={location.uuid}
          position={[
            parseFloat(location.geolocation?.lat ?? 0),
            parseFloat(location.geolocation?.lng ?? 0),
          ]}
          eventHandlers={{
            click: (e) => {
              handleLocationChange(location.uuid)
              setTimeout(() => {
                map &&
                  map.flyTo(
                    [
                      parseFloat(location.geolocation?.lat ?? 0),
                      parseFloat(location.geolocation?.lng ?? 0),
                    ],
                    16
                  )
              }, 600)
            },
          }}
          icon={markerIcon}
        />
      ))

    return markers
  }

  const handleOpenChange = () => {
    if (isDismissable) setIsOpen(false)
  }

  const onSave = () => {
    setIsOpen(false)
    setIsUserSelectedLocation(true)
    setSelectedLocation(selectedLocalLocationUUID)
  }

  useEffect(() => {
    if (
      locations?.[selectedLocationUUID]?.geolocation?.lat &&
      locations?.[selectedLocationUUID]?.geolocation?.lng
    ) {
      setCenterCoords([
        locations?.[selectedLocationUUID]?.geolocation?.lat,
        locations?.[selectedLocationUUID]?.geolocation?.lng,
      ])
    }
  }, [])

  useEffect(() => {
    if (
      locations?.[selectedLocalLocationUUID]?.geolocation?.lat &&
      locations?.[selectedLocalLocationUUID]?.geolocation?.lng
    ) {
      setCenterCoords([
        locations?.[selectedLocalLocationUUID]?.geolocation?.lat,
        locations?.[selectedLocalLocationUUID]?.geolocation?.lng,
      ])
    }
  }, [selectedLocalLocationUUID])

  useEffect(() => {
    switch (appParams?.order?.locationSelectorType) {
      case "2":
        setSelectedStyle("border-2 border-[var(--main-text-color)]")
        break
      default:
        setSelectedStyle("shadow-sm bg-[var(--main-secondary-bg-color)]")
    }
  }, [appParams])

  if (locations == null) return null

  return (
    <>
      <SelectTableModal
        isOpen={selectTableOpen}
        setIsOpen={setSelectTableOpen}
        onClose={() => setIsOpen(false)}
      />
      <Modal
        isOpen={isOpen}
        isDismissable={isDismissable}
        onOpenChange={handleOpenChange}
        size="full"
        hideCloseButton={!isDismissable}
        classNames={{
          base: "overflow-hidden flex flex-col max-h-full m-0 max-w-[820px] max-h-[900px]",
          closeButton:
            "z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]",
        }}
      >
        <ModalContent
          className={`${
            appParams?.styles?.fonts?.capitalize
              ? "font-extrabold uppercase"
              : ""
          }`}
        >
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {getValue("app_v2_selectLocation")}
              </ModalHeader>
              <ModalBody className="grow flex flex-col gap-2 overflow-hidden overflow-y-scroll">
                <div className="location-selector-title font-large">
                  {getValue("app_v2_locationSelector_title")}
                </div>
                {!appParams?.styles?.global?.hideMap &&
                  centerCoords != null && (
                    <div
                      className="map-container shrink-0"
                      style={{ height: isDesktop ? "400px" : "200px" }}
                    >
                      <MapContainer
                        center={centerCoords}
                        zoom={15}
                        id="map-container"
                        whenCreated={(map) => {
                          setMap(map)
                        }}
                      >
                        <TileLayer
                          attribution=""
                          url={
                            appParams?.styles?.mapBox?.api ||
                            "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          }
                        />
                        <LocationMarkers />
                      </MapContainer>
                    </div>
                  )}
                <div className="flex flex-col gap-2 w-full items-center justify-center">
                  {appParams?.order?.showTableSelector && (
                    <div className="location-selector-location-block w-full flex gap-2 bg-[var(--main-secondary-bg-color)] overflow-auto">
                      <Button
                        className="shrink-0 max-w-[200px]"
                        fullWidth
                        color="primary"
                        onClick={openSelectTableModal}
                      >
                        {savedTable?.name == null && (
                          <div className="location-name select-table-text">
                            {getValue("app_v2_selectTable")}
                          </div>
                        )}
                        {savedTable?.name != null && (
                          <div className="location-address font-smallest">{`${
                            "kiválasztva: " + savedTable?.name
                          }`}</div>
                        )}
                      </Button>
                      {savedTable?.name != null && (
                        <Button
                          className="location-openState shrink-0"
                          onClick={onClickResetTable}
                        >
                          {savedTable?.name != null && (
                            <div className={""}>
                              {getValue("app_v2_resetTable")}
                            </div>
                          )}
                        </Button>
                      )}
                    </div>
                  )}
                  <ScrollShadow className="w-full">
                    {Object.values(locations || {}).filter(
                      (location) => location.enabled === 1
                    ).length < 1 && (
                      <div>{getValue("app_v2_no_locations")}</div>
                    )}
                    {Object.values(locations || {})
                      .filter((location) => location.enabled === 1)
                      .map((location) => (
                        <div
                          key={location.uuid}
                          className={`${
                            appParams?.styles?.fonts?.capitalize
                              ? "font-extrabold uppercase"
                              : ""
                          } location-selector-location-block ${selectedStyle} gap-1 ${
                            location.uuid === selectedLocalLocationUUID
                              ? "selected bg-[var(--main-text-color)] text-[var(--main-bg-color)]"
                              : "not-selected"
                          } w-full`}
                          onClick={() => {
                            handleLocationChange(location.uuid)
                          }}
                        >
                          <div className="text-current w-[calc(100%-83px)]">
                            <div className="location-name">{location.name}</div>
                            <div className="location-address font-smallest">{`${
                              location.address?.city !== ""
                                ? location.address?.city + ", "
                                : ""
                            } ${location.address.zip} ${
                              location.address.street
                            } ${location.address.streetNumber}`}</div>
                            {location?.openState?.forceClosedUntil != null && (
                              <div className="location-address font-smallest">{`${getValue(
                                `app_v2_location_pause_reason_${location?.openState?.forceClosedUntilReason}`
                              )} ${getValue("app_v2_location_pause_text")} ${
                                location?.openState?.forceClosedUntil
                              }`}</div>
                            )}
                          </div>
                          <div className="location-openState">
                            {location?.openState?.forceClosedUntil != null && (
                              <div className={`openState-btn closed`}>
                                {getValue("app_v2_location_pause")}
                              </div>
                            )}
                            {location?.openState?.forceClosedUntil == null && (
                              <div
                                className={`openState-btn ${
                                  location?.openState?.open ? "open" : "closed"
                                }`}
                              >
                                {location?.openState?.open
                                  ? getValue("app_v2_location_open")
                                  : getValue("app_v2_location_closed")}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                  </ScrollShadow>
                </div>
              </ModalBody>
              <ModalFooter className="flex items-center justify-center">
                {isDismissable && (
                  <Button
                    color="danger"
                    variant="light"
                    onPress={() => setIsOpen(false)}
                    style={{
                      borderRadius: "var(--general-border-radius)",
                    }}
                    className={`${
                      appParams?.styles?.fonts?.capitalize
                        ? "font-extrabold uppercase"
                        : ""
                    }`}
                  >
                    {getValue("app_v2_locationSelector_cancel")}
                  </Button>
                )}
                <Button
                  color="primary"
                  onPress={() => onSave()}
                  isDisabled={selectedLocalLocationUUID == null}
                  style={{
                    borderRadius: "var(--general-border-radius)",
                  }}
                  className={`${
                    appParams?.styles?.fonts?.capitalize
                      ? "font-extrabold uppercase"
                      : ""
                  }`}
                  fullWidth
                >
                  {`${
                    locations?.[selectedLocalLocationUUID]?.name ||
                    getValue("app_v2_select_location")
                  }`}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
