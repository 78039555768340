import { useDictionary, useClient } from "@adamlip/okeoke.client"
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@nextui-org/react"
import useStorage from "hooks/useStorage"
import { useEffect, useState, useContext, useRef } from "react"
import { toast } from "react-hot-toast"
import { generatePin, loginAnonymous } from "utils/User-v2"
import Reg from "./Reg"
import EmailField from "./EmailField"
import PinHandler from "./PinHandler"
import Header from "./Header"
import { UserContext } from "contexts/UserContext"
import AnoIdentifier from "./AnoIdentifier"
import { AppParamsContext } from "contexts/AppParamsContext"

export default function Login({
  isOpen,
  setIsOpen,
  forceVisible,
  canGuestLogin,
}) {
  const { appParams } = useContext(AppParamsContext)
  const { getValue } = useDictionary()
  const { setSV, removeSV, getSV } = useStorage()
  const { setToken } = useClient()
  const { setUserLoggedIn } = useContext(UserContext)

  const [loginType, setLoginType] = useState("reg")
  const [acceptedPolicy, setAcceptedPolicy] = useState(false)

  const [email, setEmail] = useState("")
  const [emailSaved, setEmailSaved] = useState(false)
  const [emailInvalid, setEmailInvalid] = useState(false)
  const [firstNameInvalid, setFirstNameInvalid] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const timer = useRef(null)

  const handleSetLoginType = (value) => {
    setLoginType(value)
  }

  const handleChangeEmail = (e) => {
    let value = e.target.value
    setEmail(value)
    value === "" ? removeSV("login_email") : setSV("login_email", value)
    if (emailInvalid) checkEmailAddress(value)
  }

  const handleBlurEmail = (e) => {
    checkEmailAddress(e.target.value)
  }

  const handleClickOnSubmit = () => {
    if (emailSaved) {
      setEmailSaved(false)
    } else {
      switch (loginType) {
        case "login":
          handleLogin()
          break
        case "reg":
          handleRegSubmit()
          break
        case "guest":
          handleGuestLogin()
          break
        default:
          toast.error("app_v2_error")
      }
    }
  }

  const handleLogin = () => {
    let emailValid = checkEmailAddress(email)
    if (!emailValid) {
      toast.error(getValue("app_v2_invalid_email"))
      return null
    }
    setEmailSaved(true)
    generatePin(email)
  }

  const handleRegSubmit = () => {
    let emailValid = checkEmailAddress(email)
    let loginType = appParams?.login?.shouldCheckBoxBeChecked

    if (!emailValid) {
      toast.error(getValue("app_v2_invalid_email"))
      return null
    }
    if (firstNameInvalid) {
      toast.error(getValue("app_v2_invalid_name"))
      return null
    }
    if (loginType) {
      if (!acceptedPolicy) {
        toast.error(getValue("app_v2_login_policyError"))
        return null
      }
    }
    setEmailSaved(true)
    generatePin(email)
  }

  const handleGuestLogin = () => {
    loginAnonymous(anoLoginCallback)
  }

  const anoLoginCallback = (res) => {
    if (res.success) {
      setToken(res.data.token)
      setUserLoggedIn(true)
      setSV("anoLoginUUID", res.data?.data?.uuid)
      setSV("apiToken", res.data.token)
      setSV("apiTokenExpires", res.data.expires)
      onSuccess()
    } else {
      toast.error(getValue("app_v2_toast_ano_error"))
    }
  }

  const checkEmailAddress = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const valid = re.test(String(email).toLowerCase())
    setEmailInvalid(!valid)
    return valid
  }

  const onSuccess = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      timer.current = setTimeout(() => {
        setLoaded(true)
      }, 300)
    } else {
      clearTimeout(timer.current)
      setLoaded(false)
    }
  }, [isOpen])

  useEffect(() => {
    setEmail(getSV("login_email"))
  }, [])

  // useEffect(() => {
  //   const handleKeyPress = (event) => {
  //     if (event.key === "Enter") {
  //       if (
  //         !forceVisible &&
  //         (userLoggedIn || location.pathname.startsWith("/menu"))
  //       )
  //         return;
  //       handleClickOnSubmit();
  //     }
  //   };
  //   window.addEventListener("keydown", handleKeyPress);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, [email]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      size="full"
      placement="bottom-center"
      radius="none"
      hideCloseButton={forceVisible}
      classNames={{
        base: "m-0 max-w-[420px] max-h-[600px]",
        wrapper: "sm:items-end md:items-center",
        closeButton:
          "z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]",
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              <h2 className="text-left">
                {!forceVisible && getValue("app_v2_need_to_login")}
                {forceVisible && getValue("app_v2_welcome")}
              </h2>
              <div className="font-normal text-sm mb-2">
                {!forceVisible && getValue("app_v2_need_to_login_desc")}
                {forceVisible && getValue("app_v2_welcome_desc")}
              </div>
              {!emailSaved && (
                <ButtonGroup>
                  <Button
                    color={`${loginType === "reg" ? "primary" : "default"}`}
                    onPress={() => handleSetLoginType("reg")}
                  >
                    {getValue("app_v2_login_reg")}
                  </Button>
                  <Button
                    color={`${loginType === "login" ? "primary" : "default"}`}
                    onPress={() => handleSetLoginType("login")}
                  >
                    {getValue("app_v2_login_login")}
                  </Button>
                  {appParams?.login?.anoLoginEnabled &&
                    !getSV("anoLoginUUID") &&
                    canGuestLogin && (
                      <Button
                        color={`${
                          loginType === "guest" ? "warning" : "default"
                        }`}
                        onPress={() => handleSetLoginType("guest")}
                      >
                        {getValue("app_v2_login_guest")}
                      </Button>
                    )}
                </ButtonGroup>
              )}
            </ModalHeader>
            <ModalBody className="overflow-auto">
              <Header loginType={loginType} emailSaved={emailSaved} />
              <EmailField
                handleChangeEmail={handleChangeEmail}
                handleBlurEmail={handleBlurEmail}
                emailInvalid={emailInvalid}
                email={email}
                visible={loginType !== "guest" && !emailSaved}
              />
              {loaded && (
                <Reg
                  firstNameInvalid={firstNameInvalid}
                  setFirstNameInvalid={setFirstNameInvalid}
                  visible={loginType === "reg" && !emailSaved}
                  acceptedPolicy={acceptedPolicy}
                  setAcceptedPolicy={setAcceptedPolicy}
                />
              )}
              {loginType !== "guest" && emailSaved && (
                <PinHandler email={email} onSuccess={onSuccess} />
              )}
              {loginType === "guest" && (
                <>
                  <div>{getValue("app_v2_guest_login_disclaimer")}</div>
                  <AnoIdentifier />
                </>
              )}
              <Button
                color="primary"
                fullWidth
                className={`btn py-3 h-auto !rounded-[var(--general-border-radius)] primary`}
                onPress={handleClickOnSubmit}
              >
                {!emailSaved && getValue("app_v2_login_loginBtn")}
                {emailSaved && (
                  <div>{`${email} ${getValue(
                    "app_v2_login_loginBtn_modify"
                  )}`}</div>
                )}
              </Button>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
