import { useDictionary } from "@adamlip/okeoke.client";
import useBasket from "hooks/useBasket";
import { useEffect, useState, useContext } from "react";
import { Input } from "@nextui-org/react";
import { AppParamsContext } from "contexts/AppParamsContext";

export default function ServiceFee() {
  const { getValue } = useDictionary();
  const {} = useBasket();
  const { appParams } = useContext(AppParamsContext);
  const { setNotes, notes } = useBasket();

  const [orderNotes, setOrderNotes] = useState("");

  const handleChangeNotes = (e) => setOrderNotes(e.target.value);

  const handleBlurNotes = () => setNotes(orderNotes?.slice(0, 100));

  useEffect(() => {
    if (notes) setOrderNotes(notes);
  }, []);

  if (
    appParams?.basketModal?.items?.orderNotes == null ||
    appParams?.basketModal?.items?.orderNotes === false
  )
    return null;

  return (
    <div className="my-2">
      <Input
        aria-label="input"
        type="text"
        classNames={{
          inputWrapper:
            "group-data-[focus=true]:bg-[var(--input-bg-color)] border-1 rounded-[var(--general-border-radius)] bg-[var(--input-bg-color)] border-[var(--input-border-color)] text-[var(--input-text-color)]",
          label: appParams?.styles?.fonts?.capitalize
            ? "font-bold uppercase"
            : "",
        }}
        name="orderNotes"
        label={getValue("app_v2_order_notes")}
        value={orderNotes}
        fullWidth={true}
        onChange={handleChangeNotes}
        onBlur={handleBlurNotes}
      />
    </div>
  );
}
