import {useClient, useDictionary} from '@adamlip/okeoke.client';
import {Chip} from '@nextui-org/react';
import Icon from 'assets/icons';
import {AppParamsContext} from 'contexts/AppParamsContext';
import {useContext} from 'react';

export default function ItemHeader({banned, assembledItem, forceHideDesc, headerClass, nameClass, readOnly}) {
  const {getName} = useClient();
  const {appParams} = useContext(AppParamsContext);
  const {getValue} = useDictionary();

  const itemPriceColor =
    appParams?.styles?.colorVariables?.orderItemsPriceColor || appParams?.styles?.colorVariables?.orderItemsTextColor || appParams?.styles?.colorVariables?.mainTextColor || '#000';
  const itemStyle = appParams?.items?.itemStyle?.itemName;
  const itemDescStyle = appParams?.items?.itemStyle?.itemDescription;
  const itemPriceStyle = appParams?.items?.itemStyle?.itemPrice;

  const renderExtraClass = (type) => {
    let extraClass = '';
    extraClass += ` ${type?.fontSize || 'text-sm'} ${type?.fontWeight || 'font-bold'}`;
    if (type?.uppercase) {
      extraClass += ` !uppercase `;
    }
    if (type?.fontColor) {
      extraClass += ` text-[${type?.fontColor}]`;
    }
    return extraClass;
  };
  return (
    <>
      {!readOnly && banned && <div className='bann-layer'>{getValue('app_v2_min18')}</div>}
      <div className={`orderItemListItem-header ${headerClass || ''}`}>
        <div
          className={`overflow-hidden w-full ${appParams?.items?.truncateName ? 'truncate' : '!overflow-visible'} ${
            appParams?.styles?.fonts?.capitalize ? 'font-extrabold uppercase' : ''
          } ${nameClass || ''} ${renderExtraClass(itemStyle)} pb-[4px]`}
          style={{
            color: itemStyle?.fontColor || '',
          }}>
          {getName(assembledItem.name)}
        </div>
        {!forceHideDesc && !appParams?.items?.hideDescription && (
          <div
            className={`orderItemListItem-description grow font-smallest ${renderExtraClass(itemDescStyle)} `}
            style={{
              color: itemDescStyle?.fontColor || '',
            }}>{`${getName(assembledItem.description)}`}</div>
        )}
        <div className='flex flex-row gap-2 items-end justify-start'>
          <div
            className={`orderItemListItem-header-price text-base ${renderExtraClass(itemPriceStyle)}`}
            style={{color: itemPriceStyle?.fontColor || itemPriceColor || ''}}>{`${parseInt(assembledItem.totalPriceUnit)} Ft`}</div>
          {assembledItem?.tags && assembledItem?.tags?.some((tag) => tag === 'popular') && (
            <Chip color='primary' size='sm' variant='flat' className='mb-[6px]'>
              <p className='!text-tiny'>{getValue('app_v2_popular')}</p>
            </Chip>
          )}
        </div>
        <div className='flex justify-end h-[20px] absolute bottom-2 right-2 gap-1 opacity-70'>
          {assembledItem?.tags
            ?.filter((tag) => ['dairyFree', 'vegan', 'glutenFree', 'vegetarian'].includes(tag))
            .map((tag, index) => (
              <Icon key={index} name={tag} className={'fill-white stroke-white'} secondClassName={'fill-primary/80'} />
            ))}
        </div>
      </div>
    </>
  );
}
