import { useDictionary, useItems } from "@adamlip/okeoke.client"
import { Modal, ModalBody, ModalContent, ModalHeader } from "@nextui-org/react"
import CategoryElement from "components/Modals/CategoryListModal/CategoryElement"
import { AppParamsContext } from "contexts/AppParamsContext"
import { useContext } from "react"

function CategoryListModal(props) {
  const { isOpen, setIsOpen } = props
  const { appParams } = useContext(AppParamsContext)
  const { categoryTree, categories } = useItems()

  const { getValue } = useDictionary()
  const filterFunction = (category) => {
    return (
      categories?.[category.uuid]?.enabled &&
      (category.availability?.availableItems > 0 ||
        category.availability?.availableSubItems > 0) &&
      (category.availability?.visibleItems > 0 ||
        category.availability?.visibleSubItems > 0)
    )
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={() => setIsOpen(!isOpen)}
        size="full"
        classNames={{
          base: "m-0 max-w-[760px] max-h-[600px]",
          closeButton:
            "z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 w-full text-left">
                {getValue("app_v2_categoriesModal_title")}
              </ModalHeader>
              <ModalBody className="grow flex flex-col overflow-hidden px-2">
                <div className="grow  overflow-y-scroll flex flex-col gap-2">
                  {categoryTree.filter(filterFunction).map((category) => {
                    const subCategories =
                      category.categories.filter(filterFunction)
                    return (
                      <CategoryElement
                        key={category?.uuid}
                        categoryUUID={category?.uuid}
                        categories={categories}
                        subCategories={subCategories}
                        setIsOpen={setIsOpen}
                      />
                    )
                  })}
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
export default CategoryListModal
