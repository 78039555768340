import moment from 'moment'

export const Logger = {
    add: function (msg) {
        if(localStorage['brandAppV2-consoleTrackOn'] === "true") {
            try {
                if(localStorage['brandAppV2-consoleLog'] != null) {
                    let log = JSON.parse(localStorage['brandAppV2-consoleLog'])
                    log.push({timestamp: moment().format("H:mm:ss"), msg})
                    localStorage['brandAppV2-consoleLog'] = JSON.stringify(log)
                } else {
                    localStorage['brandAppV2-consoleLog'] = JSON.stringify([{timestamp: moment().format("H:mm:ss"), msg}])
                }
            } catch(e) {
                console.log(e)
            }
        }
    },
    read: function () {
        let response = []
        if(localStorage['brandAppV2-consoleLog'] != null) {
            try {
                response = JSON.parse(localStorage['brandAppV2-consoleLog'])
            } catch(e) {
                console.log(e)
            }
        }
        return response
    },
    clear: function () {
        localStorage.removeItem('brandAppV2-consoleLog')
    }
}