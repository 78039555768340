import { useClient, useItem } from "@adamlip/okeoke.client";
import { NumericFormat } from "react-number-format";

function OrderViewItems({ item }) {
  const { getName } = useClient();
  const { assembledItem } = useItem(item?.itemUUID, null);

  return (
    <div className="w-full rounded-md flex flex-col justify-between items-center  bg-[var(--main-secondary-bg-color)]">
      <div className="w-full rounded-md flex flex-row justify-between items-center p-2 bg-[var(--main-secondary-bg-color)]">
        <div className="basketItem-name  text-md">
          {item?.count}x {`${getName(item?.name)}`}
        </div>
        {/* <NumericFormat
          value={+item.discountPrice}
          decimalSeparator=","
          suffix=" Ft"
          thousandSeparator="."
          displayType="text"
        /> */}
      </div>
      {item?.modifiers?.filter((mod) => mod?.count > 0)?.length > 0 && (
        <div className="w-full flex flex-col pl-4 text-sm pb-2">
          {item?.modifiers
            ?.filter((mod) => mod?.count > 0)
            ?.map((mod) => {
              let modGroupData = assembledItem?.modifierGroups?.find(
                (modGroup) => modGroup?.uuid === mod?.modifierGroupUUID
              );
              return (
                <div
                  className="w-full flex flex-col"
                  key={mod?.orderModifierUUID}
                >
                  <p className="text-sm opacity-80 mb-1 ">
                    {getName(modGroupData?.name)}
                  </p>
                  <div className="w-full rounded-md flex pl-2 flex-row justify-between items-center  bg-[var(--main-secondary-bg-color)]">
                    <div className="grow truncate text-md">
                      {mod?.count}x {`${getName(mod?.name)}`}
                    </div>
                    <div className="min-w-[15%] text-right pr-2">
                      <NumericFormat
                        value={+mod.price}
                        decimalSeparator=","
                        suffix=" Ft"
                        thousandSeparator="."
                        displayType="text"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default OrderViewItems;
