import CustomInput from "components/CustomInput";
import { useDictionary } from "@adamlip/okeoke.client";
import { CircularProgress } from "@nextui-org/react";
import IconComponent from "components/IconComponent";

export default function Inputs({
  userData,
  userDataHelperText,
  handleChange,
  hasTaxNumber,
  taxIsLoading,
  checkTaxNumber,
}) {
  const { getValue } = useDictionary();

  return (
    <>
      <CustomInput
        type="text"
        name="addressLabel"
        label={getValue("app_v2_address_label")}
        value={userData.addressLabel}
        fullWidth={true}
        onChange={handleChange}
        errorMessage={userDataHelperText.addressLabel}
        isInvalid={userDataHelperText.addressLabel != null}
        isRequired
      />
      <div className="flex items-center">
        <CustomInput
          type="text"
          name="taxNumber"
          label={getValue("app_v2_address_taxNumber")}
          value={userData.taxNumber}
          fullWidth={true}
          onChange={handleChange}
          onBlur={(e) => checkTaxNumber(e.target.value)}
          errorMessage={hasTaxNumber ? userDataHelperText.taxNumber : null}
          isInvalid={hasTaxNumber ? userDataHelperText.taxNumber != null : null}
          mask={hasTaxNumber ? "99999999" : null}
        />
        {taxIsLoading && (
          <div className="w-full items-center justify-center flex">
            <CircularProgress color="primary" aria-label="aria label" />
          </div>
        )}
        <div
          className="h-[20px] w-[20px] ml-3 mr-1"
          onClick={() =>
            handleChange({ target: { name: "taxNumber", value: "" } })
          }
        >
          <IconComponent iconCode="cancel2" />
        </div>
      </div>
      <CustomInput
        type="text"
        name="name"
        label={getValue("app_v2_address_name")}
        value={userData.name}
        fullWidth={true}
        onChange={handleChange}
        errorMessage={userDataHelperText.name}
        isInvalid={userDataHelperText.name != null}
        isRequired
      />
      <div className="flex flex-row gap-2">
        <CustomInput
          type="text"
          name="zip"
          label={getValue("app_v2_address_zip")}
          value={userData.zip}
          fullWidth={true}
          onChange={handleChange}
          errorMessage={userDataHelperText.zip}
          isInvalid={userDataHelperText.zip != null}
          isRequired
        />
        <CustomInput
          type="text"
          name="state"
          label={getValue("app_v2_address_state")}
          value={userData.state}
          fullWidth={true}
          onChange={handleChange}
          errorMessage={userDataHelperText.state}
          isInvalid={userDataHelperText.state != null}
          isRequired
        />
      </div>

      <CustomInput
        type="text"
        name="city"
        label={getValue("app_v2_address_city")}
        value={userData.city}
        fullWidth={true}
        onChange={handleChange}
        errorMessage={userDataHelperText.city}
        isInvalid={userDataHelperText.city != null}
        isRequired
      />
      <div className="flex flex-row gap-2">
        <CustomInput
          type="text"
          name="street"
          label={getValue("app_v2_address_street")}
          value={userData.street}
          fullWidth={true}
          onChange={handleChange}
          errorMessage={userDataHelperText.street}
          isInvalid={userDataHelperText.street != null}
          isRequired
        />
        <CustomInput
          type="text"
          name="streetNumber"
          label={getValue("app_v2_address_streetNumber")}
          value={userData.streetNumber}
          fullWidth={true}
          maxLength={30}
          onChange={handleChange}
          errorMessage={userDataHelperText.streetNumber}
          isInvalid={userDataHelperText.streetNumber != null}
          isRequired
        />
      </div>
      <CustomInput
        type="text"
        name="telNumber"
        label={getValue("app_v2_address_phone")}
        value={(userData.telNumber || "+36").replace(
          /(\+\d{2})(\d{2})(\d{3})(\d{4})/,
          "$1 $2 $3 $4"
        )}
        fullWidth={true}
        onChange={handleChange}
        errorMessage={userDataHelperText.telNumber}
        isInvalid={userDataHelperText.telNumber != null}
        mask={"+36 99 999 9999"}
      />
    </>
  );
}
