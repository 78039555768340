import { useDictionary, useClient } from "@adamlip/okeoke.client";
import { useState, useContext } from "react";
import { UserContext } from "contexts/UserContext";
import useStorage from "hooks/useStorage";
import PinInput from "components/PinInput";
import { validatePin } from "utils/User-v2";
import { toast } from "react-hot-toast";

export default function PinHandler({ email, onSuccess }) {
  const { getValue } = useDictionary();
  const { setSV, removeSV } = useStorage();
  const { setToken } = useClient();
  const { setUserLoggedIn } = useContext(UserContext);

  const [pinInputStatus, setPinInputStatus] = useState(false);

  const resetPinStatus = () => {
    setPinInputStatus(false);
  };

  const handlePinCheck = (pinCode) => {
    validatePin(email, pinCode, pinCallback);
  };

  const pinCallback = (e) => {
    if (e.success) {
      removeSV("anoLoginUUID");
      setSV("apiToken", e.data.token);
      setSV("apiTokenExpires", e.data.expires);
      setSV("realUser", true);

      setToken(e.data.token);

      setPinInputStatus("success");
      setUserLoggedIn(true);

      let dataToSendToWebView = {
        type: "userUUID",
        data: e.data.uuid,
      };
      let emailDataToSendToWebView = {
        type: "email",
        data: e.data.email,
      };
      window.ReactNativeWebView?.postMessage(
        JSON.stringify(emailDataToSendToWebView)
      );
      window.ReactNativeWebView?.postMessage(
        JSON.stringify(dataToSendToWebView)
      );

      onSuccess();
    } else {
      setPinInputStatus("failed");
      toast.error(getValue("app_v2_toast_loginInvalid"));
    }
  };

  return (
    <>
      <PinInput
        className="login-pin"
        onPinDone={handlePinCheck}
        status={pinInputStatus}
        resetPinStatus={resetPinStatus}
      />
    </>
  );
}
