import { OEAsset, useClient, useItem } from "@adamlip/okeoke.client";
import UpSaleItemModal from "components/UpsaleItem/UpSaleItemModal";
import { useContext, useState } from "react";
import { AppParamsContext } from "contexts/AppParamsContext";

function UpsaleItem(props) {
  const { itemUUID } = props;

  const { assembledItem } = useItem(itemUUID);
  const { getName } = useClient();
  const { appParams } = useContext(AppParamsContext);
  const [isOpen, setIsOpen] = useState(false);
  const openItemModal = () => {
    setIsOpen(true);
  };

  if (assembledItem == null) return null;

  return (
    <>
      <UpSaleItemModal
        itemUUID={itemUUID}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <div className="upsale-item-container" onClick={openItemModal}>
        {appParams?.assets?.defaultItemImg != null && (
          <div className="upsale-item-img-container">
            <OEAsset
              uuid={assembledItem.attributes.appImage}
              addClass={"upsale-item-img"}
              size="l"
            />
          </div>
        )}
        <div className="item-name">{getName(assembledItem.name)}</div>
      </div>
    </>
  );
}
export default UpsaleItem;
