import { AuthAPI } from "apis/AuthApi";
import appConfig from "appConfig";
import { toast } from "react-hot-toast";

function checkToken(callback) {
  console.log("Checking user token...");

  AuthAPI.refreshJWTToken()
    .then((response) => {
      if (typeof callback === "function") callback(response.data);
      if (response.data.success) {
        console.log("Token ok!");
      } else {
        console.log("Invalid token.");
      }
    })
    .catch((e) => {
      console.error(e);
    });
}

function generatePin(email) {
  console.log("Generate pin.");

  AuthAPI.generatePin(email)
    .then((response) => {
      if (response.data.success) {
        console.log("Pin code generated.");
        toast.success("Pinkód sikeresen elküldve!");
      } else {
        toast.error("Pinkód küldés sikertelen!");
        console.log("Error, pin code not generated");
      }
    })
    .catch((e) => {
      console.error(e);
    });
}

function validatePin(email, pinCode, callback) {
  if (email == null) {
    console.log("No email for validatePin!");
    return false;
  }

  if (pinCode == null) {
    console.log("No pin code for validatePin!");
    return false;
  }

  if (typeof callback !== "function") {
    console.log("No callback function for validatePin!");
    return false;
  }

  AuthAPI.validatePin(email, pinCode)
    .then((response) => {
      response.data.success
        ? callback({ success: true, data: response.data })
        : callback({ success: false, data: null });
    })
    .catch((e) => {
      callback({ success: false, data: null });
      console.error(e);
    });
}

function loginAnonymous(callback) {
  AuthAPI.loginAnonymous(
    localStorage[
      `brandAppV2-${
        appConfig.baseUrl == null ? "prod" : appConfig.baseUrl
      }-anoLoginUUID`
    ]
  )
    .then((response) => {
      response.data.success
        ? callback({ success: true, data: response.data })
        : callback({ success: false, data: null });
    })
    .catch((e) => {
      callback({ success: false, data: null });
      console.error(e);
    });
}

export { checkToken, generatePin, validatePin, loginAnonymous };
