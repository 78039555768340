import { useState, useContext } from "react";
import { Input, cn } from "@nextui-org/react";
import { useDictionary } from "@adamlip/okeoke.client";
import { AppParamsContext } from "contexts/AppParamsContext";

export default function AnoIdentifier() {
  const { appParams } = useContext(AppParamsContext);
  const { getValue } = useDictionary();

  const [identifier, setIdentifier] = useState("");

  const handleFirstNameBlur = (e) => {
    localStorage["orderIdentifier"] = e?.target?.value || "undefined";
  };

  const handleChangeFirstName = (e) => {
    setIdentifier(e.target.value);
  };

  return (
    <>
      <div>{getValue("app_v2_guest_login_disclaimer2")}</div>
      <Input
        aria-label="input"
        type="text"
        classNames={{
          inputWrapper:
            "shadow-none	rounded-[var(--general-border-radius)] bg-[var(--main-secondary-bg-color)]",
          input: cn(
            "shadow-none	 bg-[var(--main-secondary-bg-color)] autofill:!bg-[var(--main-secondary-bg-color)] focus:!bg-[var(--main-secondary-bg-color)]"
          ),
          label: `${
            appParams?.styles?.fonts?.capitalize ? "font-bold uppercase" : ""
          } group-data-[filled-within=true]:text-foreground-500`,
        }}
        name="firstName"
        label={getValue("app_v2_login_firstName")}
        value={identifier || ""}
        onChange={handleChangeFirstName}
        onBlur={handleFirstNameBlur}
      />
    </>
  );
}
