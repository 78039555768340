import { useDictionary, useItems } from "@adamlip/okeoke.client"
import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@nextui-org/react"
import SearchItem from "components/Modals/SearchModal/SearchItem"
import { AppParamsContext } from "contexts/AppParamsContext"
import { useContext, useEffect, useState } from "react"

function SearchModal(props) {
  const { isOpen, setIsOpen } = props
  const { appParams } = useContext(AppParamsContext)
  const { items } = useItems()
  const { getValue } = useDictionary()
  const [searchString, setSearchString] = useState("")
  const [filteredItems, setFilteredItems] = useState([])

  useEffect(() => {
    if (items) {
      let filtered = Object.values(items)
      filtered = filtered?.filter((item) => item?.enabled === 1)
      if (searchString.length > 0) {
        filtered = filtered?.filter(
          (item) =>
            item?.name?.hu
              ?.toLowerCase()
              .includes(searchString.toLowerCase()) ||
            item?.name?.en?.toLowerCase().includes(searchString.toLowerCase())
        )
      }
      setFilteredItems(filtered)
    }
  }, [items, searchString])
  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={() => setIsOpen(!isOpen)}
        size="full"
        classNames={{
          base: "m-0 max-w-[760px] max-h-[600px]",
          closeButton:
            "z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 w-full text-left">
                {getValue("app_v2_searchModal_title")}
              </ModalHeader>
              <ModalBody className="grow flex flex-col overflow-hidden px-2">
                <div className="">
                  <Input
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    label={getValue("app_v2_searchModal_searchLabel")}
                    autoFocus
                  />
                </div>
                {searchString.length < 1 && (
                  <div className="text-sm text-gray-500 w-full grow flex items-center justify-center">
                    {getValue("app_v2_searchModal_searchInfo")}
                  </div>
                )}
                {searchString.length > 1 && (
                  <div className="grow  overflow-y-scroll">
                    {filteredItems?.map((item) => {
                      return (
                        <SearchItem key={item.uuid} itemUUID={item?.uuid} />
                      )
                    })}
                  </div>
                )}
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
export default SearchModal
