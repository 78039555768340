import { CircularProgress } from "@nextui-org/react";
import { useEffect, useRef, useState } from "react";

function LoadingScreen({ loading, message }) {
  const timer = useRef(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    clearTimeout(timer.current);
    if (loading) {
      setVisible(true);
    } else {
      timer.current = setTimeout(() => {
        setVisible(false);
      }, 300);
    }
  }, [loading]);

  return (
    <div
      className={`fixed w-full h-full bg-gray flex flex-col items-center justify-center z-[99999] ${
        visible ? "visible" : "fade-out"
      }`}
    >
      <CircularProgress size="lg" color="primary" aria-label="aria label" />
      {message != null && <p>{message}</p>}
    </div>
  );
}

export default LoadingScreen;
