import { Helpers } from 'utils/Helpers-v1'

function generetaCheckSum(accountNumber) {
    return accountNumber.split("").reduce((accumulator, currentValue) => ((accumulator*10) + parseInt(currentValue, 10)) % 97);
}

export const IBAN = {
    generate: function (accountNumber) {
        let checksum = 98 - generetaCheckSum(accountNumber.toString() + '173000')
        if(checksum < 10) checksum = "0"+checksum
        let number = `HU${checksum}${accountNumber}`
    
        return number
    },
    validate: function (accountNumber) {
        let checksum = accountNumber[2]+accountNumber[3]
        let number = accountNumber.substring(4)
        let controlChecksum = 98 - generetaCheckSum(number.toString() + '173000')
        if(controlChecksum < 10) controlChecksum = "0"+controlChecksum
        return (parseInt(checksum) === parseInt(controlChecksum))
    },
    validateBank: function (accountNumber) {

        let formattedNumber = Helpers.replaceAll(accountNumber, { '-': '', '_': '', ' ': ''})

        let isIban = formattedNumber != null && formattedNumber !== "" && formattedNumber[0].toLowerCase() === 'h'
        let bankCode = ""

        if(isIban && formattedNumber.length >= 7) {
            bankCode = formattedNumber.substring(4, 7)
        }
        if(!isIban && formattedNumber.length >= 3) {
            bankCode = formattedNumber.substring(0, 3)
        }

        if(bankCode === "") return ""

        switch(bankCode) {
            case "120": return "Raiffeisen Bank"
            case "101": return "Budapest Bank"
            case "116": return "Erste Bank"
            case "121": return "Gránit Bank"
            case "117": return "OTP Bank"
            case "115": return "Takarékbank"
            default: return "NO"
        }
    },
    validateAccount: function (accountNumber) {
        if(accountNumber.length < 16) return false

        let checksum = 9*accountNumber[0]+7*accountNumber[1]+3*accountNumber[2]+1*accountNumber[3]+9*accountNumber[4]+7*accountNumber[5]+3*accountNumber[6]+1*accountNumber[7]

        if(checksum%10 === 0) return true

        return false
    }
}