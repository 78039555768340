import { useClient } from "@adamlip/okeoke.client"
import axios from "axios"
import { useEffect, useState } from "react"

function IconComponent({ iconCode, className }) {
  const [icon, setIcon] = useState(null)
  const [svgString, setSvgString] = useState(null)
  const { brandID } = useClient()
  const fetchIcon = async () => {
    const response = await axios.get(
      `https://assets.app.okeoke.io/api/v1/assets/icon/${iconCode}`,
      {
        headers: {
          "Content-Type": "application/json",
          "okeoke-appid": brandID,
          "okeoke-appname": "brands",
        },
      }
    )
    if (response.data.success) {
      setIcon(response.data.data)
    }
  }

  useEffect(() => {
    fetchIcon()
  }, [iconCode, className])

  useEffect(() => {
    if (icon) {
      const modifiedSvgString = icon?.iconData
        ?.replace(/replace-stroke/g, "stroke-" + className)
        .replace(/replace-fill/g, "fill-" + className)
      setSvgString(modifiedSvgString)
    }
  }, [className, icon, iconCode])
  if (!icon) {
    return null
  }
  return (
    <div
      dangerouslySetInnerHTML={{ __html: svgString }}
      className="w-full h-full"
    />
  )
}

export default IconComponent
