import {
  OEAsset,
  useClient,
  useDictionary,
  useOrderItem,
} from "@adamlip/okeoke.client";
import {
  Button,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ScrollShadow,
} from "@nextui-org/react";
import ChangeCountButton from "components/ChangeCountButton";
import OrderItemModalV3 from "components/Modals/OrderItemModalV3";
import ModifierGroupSelector from "components/Modals/OrderItemModalV3/ModifierGroupSelector";
import SizeSelector from "components/Modals/OrderItemModalV3/SizeSelector";
import { useEffect, useState, useContext } from "react";
import { Element } from "react-scroll";
import LoadSvg from "utils/LoadSvg-v3";
import { Switch } from "@nextui-org/react";
import { AppParamsContext } from "contexts/AppParamsContext";

function BasketItemModal({ isOpen, setIsOpen, orderItemUUID }) {
  const { getName, tokenData } = useClient();
  const { appParams } = useContext(AppParamsContext);
  const { getValue } = useDictionary();

  const {
    assembledItem,
    setCount,
    update,
    setNotes,
    setTakeAway,
    setSize,
    setModifierCount,
  } = useOrderItem(null, null, orderItemUUID);
  const [firstInvalid, setFirstInvalid] = useState(null);
  const [itemNotes, setItemNotes] = useState("");
  const [allergens, setAllergens] = useState([]);
  const [tryedToAdd, setTryedToAdd] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [itemModalOpen, setItemModalOpen] = useState(false);
  useEffect(() => {
    if (
      assembledItem != null &&
      typeof setCount === "function" &&
      (assembledItem.count < 1 || assembledItem.count == null)
    )
      setCount(1);

    if (assembledItem != null) {
      let allergenArray = [];
      for (let [key, value] of Object.entries(assembledItem.allergens)) {
        if (value != null && value > 0) allergenArray.push(key);
      }
      setAllergens(allergenArray);
    }
    // eslint-disable-next-line
  }, [assembledItem]);
  const changeCount = (count) => {
    setCount(count);
  };
  const addItemToBasket = () => {
    update();
    setIsOpen(false);
  };
  const handleChangeNotes = (e) => setItemNotes(e.target.value);

  const handleBlurNotes = (e) => setNotes(itemNotes?.slice(0, 100));
  const handleTakeAway = (params) => {
    setTakeAway(params.takeAway);
  };
  const handleAddAnotherClick = () => {
    setItemModalOpen(true);

    setIsOpen(false);
  };
  const displayDescription = (description) => {
    const descriptionLength = description?.length;
    if (descriptionLength > 250) {
      return (
        <div
          className={`px-4 text-sm opacity-60 w-full ${
            showMore ? "show-more-content show-more" : "show-more-content"
          } ${
            appParams?.styles?.fonts?.capitalize ? "font-bold !uppercase" : ""
          }`}
        >
          {showMore ? description : description.slice(0, 120) + "..."}
          <span
            className="ml-2 text-primary font-bold"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore
              ? getValue("app_v2_showLess")
              : getValue("app_v2_showMore")}
          </span>
        </div>
      );
    } else {
      return (
        <div className="px-4 text-sm opacity-60  text-clip w-full ">
          {description}
        </div>
      );
    }
  };

  if (assembledItem == null) return null;

  return (
    <>
      <OrderItemModalV3
        isOpen={itemModalOpen}
        setIsOpen={setItemModalOpen}
        itemUUID={assembledItem.uuid}
        readOnly={false}
      />
      <Modal
        isOpen={isOpen}
        onOpenChange={() => {
          setIsOpen(false);
        }}
        size="full"
        className="bg-background"
        placement="bottom-center"
        scrollBehavior="inside"
        classNames={{
          base: "m-0 max-w-[820px] bottom-0 ",
          wrapper: "sm:items-end",
          closeButton:
            "z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]",
        }}
      >
        <ModalContent className="!rounded-tl-[var(--modal-radius)] !rounded-tr-[var(--modal-radius)] overflow-hidden">
          {(onClose) => (
            <>
              <ModalBody className="p-0">
                <ScrollShadow className="pb-16 realtive w-full grow overflow-x-hidden scrollArea !relative">
                  {assembledItem.availability.available === 0 && (
                    <div className="orderItem-modal-notAvailable font-small text-center mb-1">
                      {getValue("app_v2_orderItem_notAvailable")}
                    </div>
                  )}
                  <div className="orderItem-modal-body " id="scrollContainer">
                    {(appParams?.items?.showImage ||
                      appParams?.items?.showImage === undefined) &&
                      appParams?.assets?.defaultItemImg != null && (
                        <div className="orderItemListItem-img-container">
                          <OEAsset
                            uuid={assembledItem.attributes.appImage}
                            addClass={`w-full aspect-[4/3] max-h-[200px] flex flex-start overflow-hidden object-cover  ${
                              appParams?.features?.imagesContain
                                ? "img-contain"
                                : ""
                            }`}
                            size="l"
                          />
                        </div>
                      )}
                  </div>
                  <div className="z-50 sticky top-0 pt-4 bg-[var(--main-bg-color)]">
                    <div className="px-4 pt-2 pb-2 flex flex-row gap-1 bg-[var(--main-bg-color)] items-center !relative z-50">
                      <div
                        id="desc"
                        className="orderItem-modal-itemName font-extraLarge"
                      >
                        {getName(assembledItem.name)}
                      </div>
                    </div>
                  </div>
                  <div className="uppercase">
                    {displayDescription(getName(assembledItem.description))}
                  </div>
                  <div className="w-full flex flex-wrap gap-2 flex-row px-2">
                    {allergens.map((alle, index) => (
                      <div key={index} className="allergen-icon">
                        <LoadSvg key={index} name={alle} />
                      </div>
                    ))}
                  </div>
                  <div className="px-2">
                    {allergens.length > 0 && (
                      <span className=" text-small">
                        <span className="title">
                          - {getValue("app_v2_allergens")}:{" "}
                        </span>
                        {allergens.map((alle, index) => (
                          <span key={index} className="allergen">
                            {getValue("app_v2_allergens_" + alle)}
                            {allergens.length !== index + 1 && ", "}
                          </span>
                        ))}
                      </span>
                    )}
                    <div className="item-tags mt-2">
                      {Array.isArray(assembledItem.tags) &&
                        assembledItem.tags.includes("meat") && (
                          <LoadSvg name={"meat"} />
                        )}
                      {Array.isArray(assembledItem.tags) &&
                        assembledItem.tags.includes("lactoseFree") && (
                          <LoadSvg name={"milk"} />
                        )}
                      {Array.isArray(assembledItem.tags) &&
                        assembledItem.tags.includes("vegan") && (
                          <LoadSvg name={"vegan"} />
                        )}
                      {Array.isArray(assembledItem.tags) &&
                        assembledItem.tags.includes("glutenFree") && (
                          <LoadSvg name={"glutenFree"} />
                        )}
                    </div>
                  </div>
                  {assembledItem.sizes?.length > 1 && (
                    <div className="orderItem-modal-itemSizes z-10">
                      {/* <OrderItemSizeSelector sizes={assembledItem.sizes} setSize={setSize} selectedSizeUUID={assembledItem.selectedSizeUUID} /> */}
                      <SizeSelector
                        sizes={assembledItem.sizes}
                        setSize={setSize}
                        selectedSizeUUID={assembledItem.selectedSizeUUID}
                      />
                    </div>
                  )}
                  <div className=" px-4 flex flex-col gap-2 w-full z-0 !relative ">
                    {assembledItem.modifierGroups
                      .sort((a, b) => (a?.min > b?.min ? 1 : -1))
                      .sort((a, b) => (a?.idx > b?.idx ? 1 : -1))
                      // .filter(group => (group.availability.available === 1 && group.availability.visible === 1))
                      .map((group, index) => {
                        return (
                          <Element key={group.uuid} name={group.uuid}>
                            <ModifierGroupSelector
                              key={group.uuid}
                              firstInvalid={firstInvalid}
                              group={group}
                              setModifierCount={setModifierCount}
                              readOnly={false}
                              tryedToAdd={tryedToAdd}
                              name={group?.uuid}
                            />
                            {/* <OrderItemModifierGroupSelector key={group.uuid} firstInvalid={firstInvalid} group={group} setModifierCount={setModifierCount} readOnly={readOnly} tryedToAdd={tryedToAdd} name={group?.uuid} /> */}
                            {index !==
                              assembledItem.modifierGroups.length - 1 && (
                              <Divider className="my-4 bg-[var(--main-border-color)]" />
                            )}
                          </Element>
                        );
                      })}
                    {!(appParams?.items?.takeAwayHidden === true) && (
                      <div className="orderItemModifierGroup-root">
                        <Switch
                          isSelected={assembledItem.takeAway}
                          onValueChange={() =>
                            setTakeAway(assembledItem.takeAway ? 0 : 1)
                          }
                        >
                          {getValue("app_v2_orderItem_takeAway")}
                        </Switch>
                      </div>
                    )}

                    {!(appParams?.items?.notesHidden === true) && (
                      <div className="orderItemModifierGroup-modifier-root orderItem-modal-notes font-small">
                        <Input
                          aria-label="input"
                          type="text"
                          classNames={{
                            inputWrapper:
                              "group-data-[focus=true]:bg-[var(--input-bg-color)] border-1 rounded-[var(--general-border-radius)] bg-[var(--input-bg-color)] border-[var(--input-border-color)] text-[var(--input-text-color)]",
                            label: appParams?.styles?.fonts?.capitalize
                              ? "font-bold uppercase"
                              : "",
                          }}
                          name="itemNotes"
                          label={getValue("app_v2_orderItem_notes")}
                          value={itemNotes}
                          fullWidth={true}
                          onChange={handleChangeNotes}
                          onBlur={handleBlurNotes}
                        />
                      </div>
                    )}
                  </div>
                </ScrollShadow>
                <div className="w-full flex flex-row  justify-between px-4 pb-4  gap-4 z-10">
                  <ChangeCountButton
                    count={assembledItem.count}
                    changeCount={changeCount}
                    type="stackableMultiple"
                  />
                  <Button
                    onPress={() => {
                      setCount(0);
                      addItemToBasket();
                    }}
                    color="danger"
                    size="lg"
                    className={`grow flex rounded-[var(--order-button-border-radius)] font-bold flex-row justify-between ${
                      assembledItem?.count > 0 &&
                      assembledItem?.valid &&
                      tokenData?.valid
                        ? ""
                        : "opacity-50"
                    }  ${
                      appParams?.styles?.fonts?.capitalize
                        ? "font-bold !uppercase"
                        : ""
                    }`}
                  >
                    <span className="font-semibold">
                      {getValue("app_v2_removeFromBasket")}
                    </span>
                    <span className="total-price">{`${
                      assembledItem?.totalPrice || 0
                    } Ft`}</span>
                  </Button>
                  {/* <div className={`btn ${assembledItem.count > 0 && assembledItem.valid && tokenData?.valid != null ? 'primary' : 'disabled'}`} onClick={addItemToBasket}>
                          <span className='text'>{getValue('app_v2_addToBasket')}</span>
                          <span className='total-price'>{`${assembledItem?.totalPrice || 0} Ft`}</span>
                        </div> */}
                </div>
                {appParams?.basketModal?.addItemWithDifferentOptions &&
                  (assembledItem?.sizes?.length > 1 ||
                    assembledItem?.modifierGroups?.length > 0) && (
                    <div>
                      <Button
                        onClick={handleAddAnotherClick}
                        fullWidth
                        radius="none"
                        className="w-full flex flex-row  items-center justify-center !pt-3 !pb-5 !h-auto z-10 bg-[var(--main-secondary-bg-color)] 
                rounded-tl-[var(--general-border-radius)]
                rounded-tr-[var(--general-border-radius)]
                "
                      >
                        <p className="text-medium text-primary">
                          {getValue("app_v2_addItemWithDifferentOption")}
                        </p>
                      </Button>
                    </div>
                  )}
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default BasketItemModal;
