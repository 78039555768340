import {useClient, OrderStates, useDictionary} from '@adamlip/okeoke.client';
import appConfig from 'appConfig';
import update from 'immutability-helper';
import {createContext, useCallback, useEffect, useRef, useState, useContext} from 'react';
import {useDrop} from 'react-dnd';
import {Toaster} from 'react-hot-toast';
import {createPortal} from 'react-dom';
import SwiperCore from 'swiper';
import {Autoplay, Pagination} from 'swiper/modules';
import 'swiper/css';
import BasketModalV2 from 'views/BasketModal';
import moment from 'moment';
import {toast} from 'react-hot-toast';
import SelectTableModal from 'components/SelectTableModal';
import useBasket from 'hooks/useBasket';
import SelectedTableModal from 'components/SelectedTableModal';
import Confirm18PlusModal from 'components/Confirm18Plus';
import useStorage from 'hooks/useStorage';
import {AppParamsContext} from './AppParamsContext';
import SearchModal from 'components/Modals/SearchModal';
import CategoryListModal from 'components/Modals/CategoryListModal';
import SelectLanguageModal from 'components/SelectLanguageModal';
SwiperCore.use([Pagination, Autoplay]);

const MainContext = createContext();

function MainContextProvider(props) {
  const {brandID, setLanguage, client, setSelectedLocation} = useClient();
  const {appParams} = useContext(AppParamsContext);
  const {getValue} = useDictionary();
  const {itemCount, orderState, savedTable, savedTableTimestamp, setTable, isUserConfirmedTable} = useBasket();

  const {getSV, removeSV} = useStorage();

  const [pingLogin, setPingLogin] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [draggableOrders, setDraggableOrders] = useState({});
  const [openModals, setOpenModals] = useState([]);
  const [showBug, setShowBug] = useState(false);
  const [categoryPageLoaded, setCategoryPageLoaded] = useState(false);
  const draggableOrdersRef = useRef({});
  const openModalsRef = useRef([]);
  const [isWebView, setIsWebView] = useState(false);
  const [basketOpen, setBasketOpen] = useState(false);
  const [selectTableOpen, setSelectTableOpen] = useState(false);
  const [showAgeConfirm, setShowAgeConfirm] = useState(false);
  const [selectedTableModalOpen, setSelectedTableModalOpen] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showAllCategoriesModal, setShowAllCategoriesModal] = useState(false);
  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const handleClickCart = () => {
    if (itemCount > 0 && orderState === OrderStates.BASKET) {
      setBasketOpen(true);
    } else {
      toast.error(getValue('app_v2_your_basket_is_empty'));
    }
  };
  const checkSavedTable = () => {
    let invalidateTableAfter = +appParams?.order?.invalidateTableAfterXSeconds || 10;
    let savedTableValid = savedTable?.uuid && savedTableTimestamp && moment().diff(savedTableTimestamp) < 1000 * 60 * invalidateTableAfter;
    if (appParams?.order?.tableSelectionRequired) {
      console.log('Table select required');
      if (savedTableValid) {
        handleTableSet();
      } else {
        toast.error(getValue('app_v2_tableSelectionRequired'));
        setSelectTableOpen(true);
      }
    } else {
      if (savedTableValid) {
        handleTableSet();
        console.log('Table select not required');
      } else {
        console.log('Table select not required but not saved');
        setTable(null);
        removeSV('savedTable');
        removeSV('savedTableTimestamp');
      }
    }
  };

  const handleTableSet = () => {
    let response = client.getTableByCode(savedTable?.shortID);
    console.log('Table response', response);
    if (response?.success) {
      setTable(response.data.uuid);
      console.log('MainContextLocation');
      setSelectedLocation(response.data.locationUUID);
      if (!isUserConfirmedTable && !appParams?.general?.hideTableConfirmation) {
        setSelectedTableModalOpen(true);
      } else {
        console.log('Table modal not opened because it is already confirmed or AppParams made it hidden');
      }
    } else {
      toast.error('Asztal választás hiba');
    }
  };

  const onSelectTableModalClose = (res) => {
    console.log('onSelectTableModalClose', res);
    if (res) {
      setTable(res.uuid);
      setSelectedLocation(res.locationUUID);
    }
  };

  useEffect(() => {
    if (appParams?.general?.only18PlusCanAccess) {
      let alreadyConfirmedAge = getSV('alreadyConfirmedAge');
      if (!alreadyConfirmedAge) {
        setShowAgeConfirm(true);
      }
    }
  }, [appParams]);
  useEffect(() => {
    let isUserSelectedLanguage = localStorage['isUserSelectedLanguage'];

    if (appParams?.general?.mustSelectLanguageFirst && !isUserSelectedLanguage) {
      setLanguageModalOpen(true);
    }
  }, [appParams]);
  useEffect(() => {
    draggableOrdersRef.current = draggableOrders;
  }, [draggableOrders]);

  useEffect(() => {
    openModalsRef.current = openModals;
  }, [openModals]);

  const moveBox = useCallback(
    (orderUUID, top, right) => {
      setDraggableOrders(
        update(draggableOrders, {
          [orderUUID]: {
            $merge: {top, right},
          },
        })
      );
    },
    [draggableOrders, setDraggableOrders]
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'orderBox',
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const right = delta != null ? Math.round(item.right - delta.x) : 0;
        const top = delta != null ? Math.round(item.top + delta.y) : 0;
        moveBox(item.orderUUID, top, right);
        return undefined;
      },
    }),
    [moveBox]
  );

  const handlePingLogin = () => setPingLogin(new Date());

  const popDraggableOrder = (orderUUID) => {
    let newDraggableObject = {...draggableOrdersRef.current};
    newDraggableObject[orderUUID].visible = 0;
    setDraggableOrders(newDraggableObject);
    setIsDragging(false);
  };

  const addDraggableOrder = (orderUUID, top, right) => {
    let newDraggableObject = {...draggableOrders};
    newDraggableObject[orderUUID] = {orderUUID, top, right, visible: 1};
    setDraggableOrders(newDraggableObject);
  };

  const closeAllModal = () => setOpenModals([]);

  const popModal = () => {
    openModalsRef.current.pop();
    setOpenModals([...openModalsRef.current]);
  };

  const addModal = (modal) => {
    openModalsRef.current.push(modal);
    setOpenModals([...openModalsRef.current]);
  };

  useEffect(() => {
    let lang = localStorage['selectedLanguage'];
    if (lang) setLanguage(lang);
    // eslint-disable-next-line
  }, []);

  return (
    <MainContext.Provider
      value={{
        pingLogin,
        handlePingLogin,
        addModal,
        popModal,
        closeAllModal,
        addDraggableOrder,
        popDraggableOrder,
        draggableOrders,
        setShowBug,
        setIsDragging,
        showBug,
        isDragging,
        anyModalOpen: openModalsRef.current.length > 0,
        isProd: appConfig.isProd,
        categoryPageLoaded,
        setCategoryPageLoaded,
        isWebView,
        setIsWebView,
        handleClickCart,
        checkSavedTable,
        showSearchModal,
        setShowSearchModal,
        showAllCategoriesModal,
        setShowAllCategoriesModal,
      }}>
      {openModals != null && openModals}
      {createPortal(
        <Toaster
          position='top-center'
          reverseOrder={false}
          toastOptions={{
            style: {
              background: 'var(--main-secondary-bg-color)',
              color: 'var(--main-text-color)',
              borderRadius: 'var(--general-border-radius)',
            },
            custom: {
              duration: 200000,
              icon: '⚠️',
              className: 'bg-[var(--main-secondary-bg-color)] text-[var(--main-text-color)]',
              style: {
                background: 'var(--main-secondary-bg-color)',
                color: 'var(--main-text-color)',
                borderRadius: 'var(--general-border-radius)',
              },
            },
          }}
        />,
        document.body
      )}
      <main ref={drop} className={`${isDragging ? 'dragging-on' : 'dragging-off'} ${brandID}`}>
        {showSearchModal && <SearchModal isOpen={showSearchModal} setIsOpen={setShowSearchModal} />}
        {showAllCategoriesModal && <CategoryListModal isOpen={showAllCategoriesModal} setIsOpen={setShowAllCategoriesModal} />}
        <Confirm18PlusModal isOpen={showAgeConfirm} setIsOpen={setShowAgeConfirm} />
        <SelectTableModal isOpen={selectTableOpen} setIsOpen={setSelectTableOpen} onClose={(res) => onSelectTableModalClose(res)} />
        <SelectedTableModal isOpen={selectedTableModalOpen} setIsOpen={setSelectedTableModalOpen} onSelect={(res) => onSelectTableModalClose(res)} />
        <BasketModalV2 isOpen={basketOpen} setIsOpen={setBasketOpen} />
        <SelectLanguageModal isOpen={languageModalOpen} onOpenChange={setLanguageModalOpen} saveToLocalStorage={true} />
        {props.children}
      </main>
    </MainContext.Provider>
  );
}

export {MainContext, MainContextProvider};
