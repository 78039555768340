import {useDictionary} from '@adamlip/okeoke.client';
import useBasket from 'hooks/useBasket';
import useStorage from 'hooks/useStorage';
import PaymentAgentHeader from './PaymentAgentHeader';
import PaymentAgentParams from './PaymentAgentParams';
import {useEffect, useContext, useState} from 'react';
import {AppParamsContext} from 'contexts/AppParamsContext';

function PaymentAgentSelector(props) {
  const {paymentAgents, selectedID, selectPaymentAgent, paymentsNeeded, usableMoney, payByVipMoney, setPayByVipMoney} = props;
  const {getValue} = useDictionary();
  const {appParams} = useContext(AppParamsContext);
  const {paymentType, handleChangePaymentType} = useBasket();
  const {getSV} = useStorage();
  const [offlineVisible, setOfflineVisible] = useState();
  const [selectedStyle, setSelectedStyle] = useState('');
  const handleChangeOffline = () => {
    selectPaymentAgent(null);
    setPayByVipMoney(false);
    handleChangePaymentType('Offline');
  };

  const handleSelectVipMoney = () => {
    setPayByVipMoney(true);
    selectPaymentAgent(null);
  };
  useEffect(() => {
    switch (appParams?.basketModal?.paymentAgentStyle) {
      case '2':
        setSelectedStyle('border-2 border-[var(--main-text-color)]');
        break;
      default:
        setSelectedStyle('shadow-sm bg-[var(--main-secondary-bg-color)]');
    }
  }, [appParams]);
  useEffect(() => {
    if (appParams?.basketModal?.offlinePayment == null) {
      setOfflineVisible(appParams?.basketModal?.paymentTypes?.offline);
    } else {
      setOfflineVisible(appParams?.basketModal?.offlinePayment);
    }
  }, [appParams]);

  useEffect(() => {
    if (selectedID) setPayByVipMoney(false);
  }, [selectedID]);

  return (
    <div className='payment-agent-selector-root'>
      {/* <div className="title">{getValue("app_v2_paymentAgents")}</div> */}
      {offlineVisible && (
        <div className='payment-agent-container'>
          <div className={`payment-agent-header  shadow-sm ${selectedStyle}`} onClick={handleChangeOffline}>
            <div className='payment-agent-name'>{getValue(`app_v2_paymentTypes_Offline`)}</div>
            <div className='radio-root'>
              <div className={`radio-select-circle ${paymentType === 'Offline' ? 'selected' : 'not-selected'}`}></div>
            </div>
          </div>
        </div>
      )}
      {!getSV('anoLoginUUID') && (
        <>
          {usableMoney > 0 && (
            <div className='payment-agent-container '>
              <div className={`payment-agent-header  shadow-sm ${selectedStyle}`} onClick={() => handleSelectVipMoney()}>
                <div className='payment-agent-name'>
                  {getValue(`app_v2_paymentTypes_vipMoney`)}
                  {` (${usableMoney})`}
                </div>
                <div className='radio-root'>
                  <div className={`radio-select-circle ${payByVipMoney ? 'selected' : 'not-selected'}`}></div>
                </div>
              </div>
            </div>
          )}
          {!offlineVisible && paymentAgents?.filter((agent) => agent.agentType === 'online').length === 0 && (
            <div className='payment-agent-container'>
              <div className='payment-agent-name'>{getValue(`app_v2_noPaymentAgents`)}</div>
            </div>
          )}
          {paymentAgents
            ?.filter((agent) => agent.agentType === 'online')
            .map((agent) => (
              <div key={agent.paymentAgentID} className='payment-agent-container '>
                <PaymentAgentHeader selectPaymentAgent={selectPaymentAgent} selected={+selectedID === +agent.paymentAgentID} data={agent} />
                <PaymentAgentParams selected={+selectedID === +agent.paymentAgentID} data={agent} />
              </div>
            ))}
        </>
      )}
    </div>
  );
}

export default PaymentAgentSelector;
