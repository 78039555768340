import { useDictionary } from "@adamlip/okeoke.client";
import { Button, Select, SelectItem } from "@nextui-org/react";
import EditDeliveryAddressModal from "components/DeliveryAddress/EditDeliveryAddressModal";
import { UserContext } from "contexts/UserContext";
import useBasket from "hooks/useBasket";
import { useContext, useEffect, useState } from "react";
import DeliveryInfo from "./DeliveryInfo";
import useStorage from "hooks/useStorage";

export default function DeliveryAddress({ setInOrder }) {
  const { deliveryAddresses } = useContext(UserContext);
  const { getValue } = useDictionary();
  const { getSV, setSV } = useStorage();
  const { setDeliveryInfo } = useBasket();
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
  const [selectedDeliveryAddressID, setSelectedDeliveryAddressID] =
    useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [newModalOpen, setNewModalOpen] = useState(false);

  const handleDeleteAddress = () => {
    setSelectedDeliveryAddressID(null);
    if (setInOrder) {
      setDeliveryInfo(null);
      setSV("deliveryAddressID", "");
    }
  };

  useEffect(() => {
    if (deliveryAddresses != null) {
      let obj = {};
      for (let address of deliveryAddresses) {
        obj[address.id] = address;
      }
      if (selectedDeliveryAddressID) {
        setSelectedDeliveryAddress(obj?.[selectedDeliveryAddressID]);
        if (setInOrder) {
          setDeliveryInfo(obj?.[selectedDeliveryAddressID]);
          setSV("deliveryAddressID", selectedDeliveryAddressID);
        }
      } else {
        setSelectedDeliveryAddress(null);
        if (setInOrder) {
          setDeliveryInfo(null);
        }
      }
    }
  }, [deliveryAddresses, selectedDeliveryAddressID]);

  useEffect(() => {
    let id = getSV("deliveryAddressID");
    if (id?.length > 0) {
      setSelectedDeliveryAddressID(id);
    }
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <EditDeliveryAddressModal
        data={selectedDeliveryAddress}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        onSave={setSelectedDeliveryAddressID}
        onDelete={() => handleDeleteAddress()}
      />
      <EditDeliveryAddressModal
        data={null}
        isOpen={newModalOpen}
        setIsOpen={setNewModalOpen}
        onSave={setSelectedDeliveryAddressID}
      />
      {!setInOrder && (
        <div className="text-lg">{getValue("app_v2_delivery_addresses")}</div>
      )}
      {deliveryAddresses?.length <= 0 && (
        <div className="mt-4 text-sm text-center">
          {getValue("app_v2_delivery_addresses_no_address")}
        </div>
      )}
      {deliveryAddresses?.length > 0 && (
        <Select
          aria-label="12"
          label={getValue("app_v2_address_selectAddress")}
          disallowEmptySelection
          onChange={(e) => setSelectedDeliveryAddressID(e.target.value)}
          selectedKeys={
            selectedDeliveryAddress?.id
              ? [selectedDeliveryAddress?.id.toString()]
              : []
          }
        >
          {deliveryAddresses.map((address) => (
            <SelectItem
              key={address?.id}
              value={address?.id}
              aria-label={address?.id}
            >
              {address.addressLabel}
            </SelectItem>
          ))}
        </Select>
      )}
      {
        <DeliveryInfo
          setInOrder={setInOrder}
          selectedDeliveryAddress={selectedDeliveryAddress}
        />
      }
      <div className="flex flex-row gap-2 items-center justify-center mt-2">
        {selectedDeliveryAddress != null && (
          <Button color="secondary" onClick={() => setModalOpen(true)}>
            {getValue("app_v2_address_edit")}
          </Button>
        )}
        <Button color="primary" onClick={() => setNewModalOpen(true)}>
          {getValue("app_v2_address_addNew")}
        </Button>
      </div>
    </div>
  );
}
