import { Modal, ModalBody, ModalContent, Button } from "@nextui-org/react";
import { useDictionary } from "@adamlip/okeoke.client";
import CustomInput from "components/CustomInput";
import { useState } from "react";
import { UserContext } from "contexts/UserContext";
import { useContext } from "react";
import toast from "react-hot-toast";

export default function AddCouponModal({ isOpen, setIsOpen }) {
  const { activateCoupon, refreshCoupons } = useContext(UserContext);
  const [couponCode, setCouponCode] = useState("");
  const [activateInProgress, setActivateInProgress] = useState("");
  const { getValue } = useDictionary();

  const handleActivateCoupon = () => {
    if (activateInProgress) return null;
    setActivateInProgress(true);

    activateCoupon(couponCode).then((response) => {
      if (response?.success) {
        toast.success(getValue("app_v2_coupons_activated"));
        setCouponCode("");
        refreshCoupons();
        setIsOpen(false);
      } else {
        toast.error(getValue("app_v2_coupons_activate_failed"));
      }
      setActivateInProgress(false);
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => setIsOpen(!isOpen)}
      size="2xl"
      scrollBehavior="inside"
      classNames={{
        closeButton:
          "z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]",
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody>
              <div className="">{getValue("app_v2_coupon_code_title")}</div>
              <CustomInput
                label={getValue("app_v2_coupon_code")}
                value={couponCode || ""}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <Button color="primary" onClick={() => handleActivateCoupon()}>
                {!activateInProgress && getValue("app_v2_coupon_code_add")}
                {activateInProgress &&
                  getValue("app_v2_coupon_code_add_inProgress")}
              </Button>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
