import { useClient } from "@adamlip/okeoke.client"
import { BasketContext } from "contexts/BasketContext"
import { useContext } from "react"
import { AppParamsContext } from "contexts/AppParamsContext"

export default function useBasket() {
  const { appParams } = useContext(AppParamsContext)
  const { activeBasketUUID, ordersInited } = useClient()

  const {
    selectedPayment,
    setSelectedPayment,
    startNewOrder,
    requestedReadyTime,
    setRequestedReadyTime,
    setPaymentType,
    orderItems,
    sumDiscountServiceFee,
    serviceFee,
    orderLocation,
    canOrderFromSelectedLocation,
    canSubmit,
    submit,
    paymentsNeeded,
    paymentType,
    itemCount,
    orderState,
    discountPrice,
    setInvoiceNeeded,
    invoiceNeeded,
    invoiceInfo,
    sumDiscountPrice,
    validation,
    setOrderIdentifier,
    setTakeAway,
    takeAway,
    orderIdentifier,
    tableInfo,
    isUserConfirmedTable,
    setIsUserConfirmedTable,
    isUserSelectedLocation,
    setIsUserSelectedLocation,
    vipPoints,
    setNotes,
    notes,
    deliveryCost,
    handleTableSet,
    setDeliveryInfo,
    addItem,
    orderUUID,
    tableUUID,
    deliveryInfo,
    reserveVip,
    setInvoiceInfo,
    updateValidationObject,
    setTable,
    savedTable,
    savedTableTimestamp,
  } = useContext(BasketContext)

  const handleStartNewOrder = (params) => {
    console.log("New order started, params:", params)
    let res = startNewOrder(params)
    console.log(res)
  }

  const resetTable = () => {
    handleTableSet(null)
  }

  const handleRequestedReadyTime = (value) => {
    if (value != null) {
      setRequestedReadyTime(value)
    }
  }

  const resetRequestedReadyTime = () => {
    console.log("reset")
    setRequestedReadyTime("")
  }

  const handleChangePaymentType = (type) => {
    //console.log('Set payment type:', type || 'Online');
    setPaymentType(type || "Online")
  }

  const handleOrderSubmit = () => {
    let response = submit()
    return response
  }

  const handleInvoiceNeeded = (value) => {
    let newValue = appParams?.order?.forceInvoiceNeeded ? 1 : value
    setInvoiceNeeded(newValue)
  }

  return {
    handleTableSet,
    resetTable,
    handleStartNewOrder,
    activeBasketUUID,
    ordersInited,
    requestedReadyTime,
    handleRequestedReadyTime,
    resetRequestedReadyTime,
    handleChangePaymentType,
    orderItems,
    sumDiscountServiceFee,
    serviceFee,
    orderLocation,
    canOrderFromSelectedLocation,
    handleOrderSubmit,
    paymentsNeeded,
    canSubmit,
    paymentType,
    itemCount,
    orderState,
    discountPrice,
    handleInvoiceNeeded,
    invoiceNeeded,
    invoiceInfo,
    sumDiscountPrice,
    validation,
    setOrderIdentifier,
    setTakeAway,
    takeAway,
    selectedPayment,
    setSelectedPayment,
    orderIdentifier,
    tableInfo,
    isUserConfirmedTable,
    setIsUserConfirmedTable,
    isUserSelectedLocation,
    setIsUserSelectedLocation,
    vipPoints,
    setRequestedReadyTime,
    setNotes,
    deliveryCost,
    setDeliveryInfo,
    addItem,
    orderUUID,
    tableUUID,
    deliveryInfo,
    notes,
    reserveVip,
    setInvoiceInfo,
    updateValidationObject,
    setTable,
    savedTable,
    savedTableTimestamp,
  }
}
