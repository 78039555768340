import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

function TextField(props) {
  const {
    name,
    type,
    value,
    onChange,
    onBlur,
    onPaste,
    className,
    fullWidth,
    disabled,
    label,
    helperText,
    error,
    mask,
    helperText2,
    error2,
    // disableFloat,
  } = props;

  const [shrinkLabel, setShrinkLabel] = useState(false);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    focused ? setShrinkLabel(true) : setShrinkLabel(value != null && value !== '');
  }, [value, focused]);

  const handleChange = (e) => {
    if (typeof onChange === 'function') onChange(e);
  };

  const handleFocus = () => {
    setShrinkLabel(true);
    setFocused(true);
  };

  const handleBlur = (e) => {
    if (value === '') setShrinkLabel(false);
    if (typeof onBlur === 'function') onBlur(e);
    setFocused(false);
  };

  const handlePaste = (e) => {
    if (typeof onPaste === 'function') onPaste(e);
  };

  const rootClasses = `${className != null ? className : ''} ${fullWidth != null ? 'fullWidth' : ''} ${disabled ? 'disabled' : ''}`;

  return (
    <>
      {/* {window.webkit?.messageHandlers?.IOS == null && <>
            {!disableFloat && focused && 
                <div className="floating-field">
                    <div className="textField-root">
                        <div className="textField-base">
                            <label className={`textField-label ${shrinkLabel ? 'shrink' : ''}`}>{label}</label>
                            <div className="textField-input">{value}
                        </div>
                    </div>
                </div>
            </div>}
        </>} */}
      <div className={`textField-root ${rootClasses} ${focused ? 'focused' : ''}`}>
        {label != null && <label className={`textField-label ${shrinkLabel ? 'shrink' : ''}`}>{label}</label>}
        <div className='textField-base w-full'>
          {mask == null && <input name={name} type={type} className='textField-input !w-full' value={value} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} disabled={disabled} onPaste={handlePaste} />}
          {mask != null && <InputMask mask={mask} name={name} type={type} className='textField-input' value={value} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} disabled={disabled} onPaste={handlePaste} />}
        </div>
        {helperText != null && helperText !== '' && <p className={`textField-helperText ${error ? 'helperTextError' : ''}`}>{helperText}</p>}
        {helperText2 != null && helperText2 !== '' && <p className={`textField-helperText ${error2 ? 'helperTextError' : ''}`}>{helperText2}</p>}
      </div>
    </>
  );
}

export default TextField;
