import appConfig from "appConfig";

export default function useStorage() {
  const baseUrl = appConfig.baseUrl == null ? "prod" : appConfig.baseUrl;

  const getSV = (key, parse) => {
    let value = localStorage[`brandAppV2-${baseUrl}-${key}`];
    if (value != null && parse) {
      try {
        value = JSON.parse(localStorage[`brandAppV2-${baseUrl}-${key}`]);
      } catch (e) {
        console.log(e);
      }
    }
    return value;
  };

  const setSV = (key, value) => {
    localStorage[`brandAppV2-${baseUrl}-${key}`] = value;
  };

  const removeSV = (key) => {
    localStorage.removeItem(`brandAppV2-${baseUrl}-${key}`);
  };

  const clearS = () => localStorage.clear();

  return {
    getSV,
    setSV,
    removeSV,
    clearS,
  };
}
