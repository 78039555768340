import { useClient } from "@adamlip/okeoke.client";
import { AppParamsContext } from "contexts/AppParamsContext";
import { useContext, useState, useEffect } from "react";

function PaymentAgentHeader(props) {
  const { data, selectPaymentAgent, selected } = props;

  const { getName } = useClient();
  const { appParams } = useContext(AppParamsContext);
  const [selectedStyle, setSelectedStyle] = useState("");

  if (data == null) return null;

  // const getTypeLogo = () => {
  //     switch(data.agentType) {
  //         case "online":
  //             switch(data.useOnline?.profileType) {
  //                 case "simplePay": return <img src={`https://cdn.okeoke.io/brands/global/payments/simplepay_vertical2.png`} alt="logo" />
  //                 case "raiffeisenPay": return <img src={`https://cdn.okeoke.io/brands/global/payments/rafi_pay.png`} alt="logo"/>
  //                 case "vivaSmartCheckout": return <img src={`https://cdn.okeoke.io/brands/global/payments/card2.png`} alt="logo"/>
  //                 default: return <img src={`https://cdn.okeoke.io/brands/global/default/default_img.jpg`} alt="logo"/>
  //             }
  //         case "cash": return <img src={`https://cdn.okeoke.io/brands/global/payments/cash.png`} alt="logo"/>
  //         default: return <img src={`https://cdn.okeoke.io/brands/global/default/default_img.jpg`} alt="logo"/>
  //     }
  // }

  useEffect(() => {
    switch (appParams?.basketModal?.paymentAgentStyle) {
      case "2":
        setSelectedStyle("border-2 border-[var(--main-text-color)]");
        break;
      default:
        setSelectedStyle("shadow-sm bg-[var(--main-secondary-bg-color)]");
    }
  }, [appParams]);

  return (
    <div
      className={`payment-agent-header ${selectedStyle}`}
      onClick={() => selectPaymentAgent(data)}
    >
      <div className="payment-agent-name">
        {data.agentType !== "online1" &&
        data.useOnline?.profileType === "vivaSmartCheckout" ? (
          <>
            {getName(data.name)}
            <img
              src="https://cdn.okeoke.io/brands/global/payments/Viva-Wallet.png"
              height="26px"
              alt="viva"
            />
          </>
        ) : (
          getName(data.name)
        )}
        {data.agentType === "online1" && (
          <div className="payment-logos">
            <img
              src={`https://cdn.okeoke.io/brands/global/payments/mastercard.png`}
              alt="logo"
            />
            <img
              src={`https://cdn.okeoke.io/brands/global/payments/visa.png`}
              alt="logo"
            />
            <img
              src={`https://cdn.okeoke.io/brands/global/payments/apple.png`}
              alt="logo"
            />
            <img
              src={`https://cdn.okeoke.io/brands/global/payments/googlepay.png`}
              alt="logo"
            />
            <img
              src={`https://cdn.okeoke.io/brands/global/payments/samsungpay.png`}
              alt="logo"
            />
          </div>
        )}
      </div>
      <div className="radio-root">
        <div
          className={`radio-select-circle ${
            selected ? "selected" : "not-selected"
          }`}
        ></div>
      </div>
    </div>
  );
}

export default PaymentAgentHeader;
