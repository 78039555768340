import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/modal';
import {useDictionary} from '@adamlip/okeoke.client';
import {Accordion, AccordionItem, Button, Divider} from '@nextui-org/react';
import {useEffect, useState} from 'react';
import {NumericFormat} from 'react-number-format';

export default function VipReserveModal({isOpen, setIsOpen, onSuccess, onCancel, data, paymentsNeeded}) {
  const {getValue} = useDictionary();

  const [items, setItems] = useState([]);
  const [reservedMoney, setReservedMoney] = useState(0);
  const [reservedPoints, setReservedPoints] = useState(0);
  const [totalCardDiscount, setTotalCardDiscount] = useState(0);
  const [totalCouponDiscount, setTotalCouponDiscount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [coupons, setCoupons] = useState([]);

  const handleClose = () => {
    reset();
    setIsOpen(!isOpen);
  };

  const handleOk = () => {
    if (typeof onSuccess === 'function') onSuccess({sumPaymentsNeeded: paymentsNeeded - totalDiscount});
    handleClose();
  };

  const handleCancel = () => {
    if (typeof onCancel === 'function') onCancel();
    handleClose();
  };

  const reset = () => {
    setItems([]);
    setReservedMoney(0);
    setReservedPoints(0);
    setTotalCardDiscount(0);
    setTotalCouponDiscount(0);
    setTotalDiscount(0);
    setCoupons([]);
  };

  useEffect(() => {
    if (data) {
      setItems(data.items);
      setReservedMoney(data.reservedMoney);
      setReservedPoints(data.reservedPoints);
      setTotalCardDiscount(data.totalCardDiscount);
      setTotalCouponDiscount(data.totalCouponDiscount);
      setTotalDiscount(data.totalDiscount);
      setCoupons(data.coupons);
    }
  }, [data]);
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => {
        handleClose();
      }}
      radius='none'
      className='bg-background'
      size='2xl'
      classNames={{
        base: 'm-0 max-w-[820px] flex flex-col',
        wrapper: 'sm:items-end',
        closeButton: 'z-50 bg-[var(--button-bg-color)]  text-[var(--button-text-color)]',
      }}
      scrollBehavior='inside'>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>
              <div className='font-bold text-xl'>{getValue('app_v2_vip_reserve_modal_title')}</div>
            </ModalHeader>
            <ModalBody className='pb-[80px]'>
              {totalDiscount <= 0 && getValue('app_v2_vip_reserve_modal_no_discount')}
              {totalDiscount > 0 && (
                <>
                  <>
                    <div className='flex items-center justify-between'>
                      <div className='font-light'>{getValue('app_v2_vip_reserve_modal_totalPrice_noDiscount')}</div>
                      <div className='font-light'>{paymentsNeeded}</div>
                    </div>
                  </>
                  <div className='flex flex-col gap-1 p-2 bg-default/40 rounded-md'>
                    {reservedMoney > 0 && (
                      <>
                        <div className='flex items-center justify-between'>
                          <div className=''>{getValue('app_v2_vip_reserve_modal_reserved_money')}</div>
                          <div className=''>{reservedMoney}</div>
                        </div>
                      </>
                    )}
                    {reservedPoints > 0 && (
                      <>
                        <div className='flex items-center justify-between'>
                          <div className=''>{getValue('app_v2_vip_reserve_modal_reserved_points')}</div>
                          <div className=''>{reservedPoints}</div>
                        </div>
                      </>
                    )}
                    {totalCardDiscount > 0 && (
                      <>
                        <div className='flex items-center justify-between'>
                          <div className=''>{getValue('app_v2_vip_reserve_modal_total_card_discount')}</div>
                          <div className=''>{totalCardDiscount}</div>
                        </div>
                      </>
                    )}

                    {totalCouponDiscount > 0 && (
                      <>
                        <div className='flex items-center justify-between'>
                          <div className=''>{getValue('app_v2_vip_reserve_modal_total_coupon_discount')}</div>
                          <div className=''>{totalCouponDiscount}</div>
                        </div>
                      </>
                    )}
                    {
                      <>
                        <div className='flex items-center justify-between'>
                          <div className='font-bold'>{getValue('app_v2_vip_reserve_modal_total_discount')}</div>
                          <div className='font-bold'>{totalDiscount}</div>
                        </div>
                      </>
                    }
                  </div>
                  <Accordion
                    variant='splitted'
                    className='px-0'
                    classNames={{
                      base: 'px-0',
                    }}>
                    {coupons.length > 0 && (
                      <AccordionItem
                        key={'coupons'}
                        title={getValue('app_v2_vip_reserve_modal_coupons')}
                        classNames={{
                          base: 'group-[.is-splitted]:bg-default/50',
                        }}>
                        <div className='flex flex-col gap-2'>
                          {coupons
                            ?.filter((coupon) => coupon?.success)
                            ?.map((coupon) => {
                              return (
                                <div key={coupon.data.couponCode} className='mr-1 mb-1 flex flex-row gap-2 items-center w-full justify-between bg-background p-2 rounded-md'>
                                  <p className='font-medium'>{coupon.data.coupon.name}</p>
                                  <NumericFormat value={coupon?.data?.couponValue} displayType={'text'} thousandSeparator={' '} suffix={' Ft'} />
                                </div>
                              );
                            })}
                        </div>
                      </AccordionItem>
                    )}

                    <AccordionItem
                      key={'items'}
                      title={getValue('app_v2_vip_reserve_modal_items')}
                      classNames={{
                        base: 'group-[.is-splitted]:bg-default/50',
                      }}>
                      {items.length > 0 && (
                        <>
                          <div className='flex flex-col gap-1'>
                            {items.map((item) => {
                              return (
                                <div key={item.uid} className='odd:bg-default/50 p-2 rounded-[var(--general-border-radius)]'>
                                  <div className=''>{item.name}</div>
                                  <div className='flex gap-1 items-center text-sm opacity-70'>
                                    <div className=''>{getValue('app_v2_vip_reserve_modal_item_price')}:</div>
                                    <div className='grow text-right flex justify-end gap-2'>
                                      {+item?.price !== +item?.reducedPrice && <div className='line-through'>{item?.price} Ft</div>}

                                      <div className=' text-right'>{item.reducedPrice} Ft</div>
                                    </div>
                                  </div>
                                  {item?.packageDiscountPrice !== 0 && (
                                    <div className='flex gap-1 items-center text-sm opacity-70'>
                                      <div className=''>{getValue('app_v2_vip_reserve_modal_package_discountPrice')}:</div>
                                      <div className='grow text-right flex justify-end gap-2'>
                                        {item?.packagePrice !== item?.packageDiscountPrice && <div className='line-through'>{item?.packagePrice} Ft</div>}
                                        <div className=''>{item?.packageDiscountPrice} Ft</div>
                                      </div>
                                    </div>
                                  )}
                                  {Object.values(item?.coupons)?.length > 0 && (
                                    <div>
                                      <Divider className='my-1 bg-default' />
                                      <h1 className=' font-medium text-sm'>{getValue('app_v2_vip_reserve_modal_item_coupons')}:</h1>
                                      {Object.values(item?.coupons)?.map((coupon) => {
                                        return (
                                          <div key={coupon?.code} className='flex gap-1 items-center text-sm opacity-70'>
                                            <div className=''>{coupons?.find((c) => c?.data?.couponCode === coupon?.code)?.data?.coupon?.name}</div>
                                            <div className='grow text-right'>{coupon?.value} Ft</div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                  <div className='flex font-medium gap-1 items-center text-sm opacity-85'>
                                    <div className=''>{getValue('app_v2_vip_reserve_modal_item_discount')}:</div>
                                    <div className='grow text-right'>{item.price - item.reducedPrice} Ft</div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </AccordionItem>
                  </Accordion>
                </>
              )}
              <div
                className='absolute bottom-[72px] bg-primary text-primary-foreground flex justify-between p-2 rounded-[var(--general-border-radius)] font-bold'
                style={{width: 'calc(100% - 3rem)'}}>
                <div className=''>{getValue('app_v2_vip_reserve_modal_payments_needed')}</div>
                <div className='mr-4 '>{paymentsNeeded - totalDiscount} Ft</div>
              </div>
            </ModalBody>
            <ModalFooter className='flex w-full justify-between gap-2 items-center'>
              <Button color='danger' variant='light' onPress={() => handleCancel()}>
                {getValue('app_v2_vip_reserve_modal_close')}
              </Button>
              <Button color='success' onPress={() => handleOk()}>
                {getValue('app_v2_vip_reserve_modal_ok')}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
