import { useDictionary } from "@adamlip/okeoke.client";
import { useEffect, useRef, useState, useContext } from "react";
//import Numpad from "./Numpad";
import PinInputComponent from "./PinInputComponent";
import { CircularProgress } from "@nextui-org/react";
import { AppParamsContext } from "contexts/AppParamsContext";

function PinInput({ className, onPinDone, status, resetPinStatus }) {
  const { getValue } = useDictionary();
  const { appParams } = useContext(AppParamsContext);

  const [pin, setPin] = useState(["", "", "", ""]);
  //const [selected, setSelected] = useState(0);
  const [pinValid, setPinValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const timer = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  // useEffect(() => {
  //   if (!tokenData?.valid) resetPinInput();
  //   // eslint-disable-next-line
  // }, [tokenData]);

  useEffect(() => {
    if (pinValid && !status) {
      if (typeof onPinDone === "function") {
        setLoading(true);
        timer.current = setTimeout(() => {
          onPinDone(pin[0] + pin[1] + pin[2] + pin[3]);
        }, 1500);
      }
    }
  }, [pinValid, pin, onPinDone, status]);

  useEffect(() => {
    if (status) setLoading(false);
  }, [status]);

  const resetPinInput = () => {
    if (typeof resetPinStatus === "function") resetPinStatus();
    setPin(["", "", "", ""]);
    //setSelected(0);
    setPinValid(false);
    setLoading(false);
  };

  // const cutSelectedNumber = () => {
  //   let newPin = pin.map((value, index) => {
  //     if (index === selected - 1) return "";
  //     return value;
  //   });
  //   setPin(newPin);
  // };

  // const handleKeyPressed = (e) => {
  //   if (selected - 1 === pin.length) return null;
  //   if (pinValid) return null;
  //   if (e === "del") {
  //     cutSelectedNumber();
  //     setSelected(selected - 1 < 0 ? 0 : selected - 1);
  //   } else {
  //     let newPin = pin.map((value, index) => {
  //       if (index === selected) return e;
  //       return value;
  //     });
  //     setPin(newPin);
  //     selected < 4 && setSelected(selected + 1);
  //     validatePin(newPin);
  //   }
  // };

  // const validatePin = (pin) => {
  //   let filteredPin = pin.filter((value) => value === "");
  //   let valid = filteredPin.length < 1;
  //   setPinValid(valid);
  // };

  const handleComplete = (value) => {
    setPinValid(true);
    setPin(value);
  };

  // const handleSelectBox = (id) => {
  //   if (pinValid) return null;
  //   setSelected(id);
  // };

  const handleClickAgain = () => resetPinInput();

  return (
    <div
      className={`pinInput-root ${
        appParams?.features?.loginModal ? "" : className
      }`}
    >
      <PinInputComponent
        length={4}
        onComplete={(value) => handleComplete(value)}
        className="mb-8 w-full justify-between"
        focusOnLoad={true}
        type="numeric"
      />
      {/* {!appParams?.features?.loginModal && (
        <div className={`pinInput-boxes`}>
          <div
            className={`pin-box pin-box0 ${
              selected === 0 ? "selected-box" : "not-selected-box"
            }`}
            onClick={() => {
              handleSelectBox(0);
            }}
          >
            {selected === 0 ? (
              <span className="blinking-cursor-white">|</span>
            ) : (
              pin[0]
            )}
          </div>
          <div
            className={`pin-box pin-box1 ${
              selected === 1 ? "selected-box" : "not-selected-box"
            }`}
            onClick={() => {
              handleSelectBox(1);
            }}
          >
            {selected === 1 ? (
              <span className="blinking-cursor-white">|</span>
            ) : (
              pin[1]
            )}
          </div>
          <div
            className={`pin-box pin-box2 ${
              selected === 2 ? "selected-box" : "not-selected-box"
            }`}
            onClick={() => {
              handleSelectBox(2);
            }}
          >
            {selected === 2 ? (
              <span className="blinking-cursor-white">|</span>
            ) : (
              pin[2]
            )}
          </div>
          <div
            className={`pin-box pin-box3 ${
              selected === 3 ? "selected-box" : "not-selected-box"
            }`}
            onClick={() => {
              handleSelectBox(3);
            }}
          >
            {selected === 3 ? (
              <span className="blinking-cursor-white">|</span>
            ) : (
              pin[3]
            )}
          </div>
        </div>
      )} */}

      {/* <PinInputComponent length={6} onComplete={() => console.log('Activate')} className='mb-8 w-full justify-between' focusOnLoad={true} type='numeric' /> */}
      {/* {appParams?.features?.loginModal && !pinValid ? (
        <PinInputComponent
          length={4}
          onComplete={(value) => handleComplete(value)}
          className="mb-8 w-full justify-between"
          focusOnLoad={true}
          type="numeric"
        />
      ) : !pinValid && !appParams?.features?.loginModal ? (
        <Numpad
          onKeyPressed={handleKeyPressed}
          className={`pinInput-numpad ${
            pinValid ? "numpad-disabled fade-out" : ""
          }`}
        />
      ) : (
        <></>
      )} */}
      {/* {!pinValid && <Numpad onKeyPressed={handleKeyPressed} className={`pinInput-numpad ${pinValid ? 'numpad-disabled fade-out' : ''}`} />} */}
      {loading && (
        <div className="pinInput-loading hidden-content appear">
          <div className="loader">
            <CircularProgress
              size="lg"
              color="primary"
              aria-label="aria label"
            />
          </div>
        </div>
      )}
      {!loading && status === "success" && (
        <div className="pinInput-img verified">
          <img
            src={`https://cdn.okeoke.io/brands/global/default/verified.gif`}
            alt="verified"
          />
        </div>
      )}
      {!loading && status === "failed" && (
        <>
          <div className="pinInput-img failed">
            <img
              src={`https://cdn.okeoke.io/brands/global/default/failed.gif`}
              alt="verified"
            />
          </div>
          {
            <button
              className="btn secondary hidden-content appear"
              onClick={handleClickAgain}
            >
              {getValue("app_v2_pinInput_again")}
            </button>
          }
        </>
      )}
    </div>
  );
}

export default PinInput;
