import { forwardRef, useRef, useState } from "react";

const InputElement = forwardRef((props, ref) => {
  const {
    initialValue,
    onBackspace,
    onChange,
    autoSelect,
    onPaste,
    type,
    regexCriteria = /^[a-zA-Z0-9]+$/,
    disabled,
    index,
  } = props;

  const [value, setValue] = useState(handleValidate(initialValue));
  const [focus, setFocus] = useState();
  const inputTimeout = useRef(null);

  const onKeyDown = (e) => {
    if (e.keyCode === 8 && (!value || !value.length)) {
      onBackspace();
    }
  };

  const update = (updatedValue, isPasting = false) => {
    let newValue = handleValidate(updatedValue);
    if (value === newValue && !isPasting) return;

    if (newValue.length < 2) {
      setValue(newValue);

      inputTimeout.current = setTimeout(() => {
        onChange(newValue, isPasting);
      }, 0);
    }
  };

  const handleChange = (e) => {
    update(e.target.value);
  };

  const onFocus = (e) => {
    if (autoSelect) {
      e.target.select();
    }
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  const handlePaste = (e) => {
    if (typeof onPaste !== "function") {
      return;
    }

    const value = e.clipboardData.getData("text");
    onPaste(value);
  };

  function handleValidate(value) {
    if (type === "numeric") {
      const numCode = value.charCodeAt(0);
      const isInteger =
        numCode >= "0".charCodeAt(0) && numCode <= "9".charCodeAt(0);
      return isInteger ? value : "";
    }
    if (regexCriteria.test(value)) {
      return value.toUpperCase();
    }

    return "";
  }

  const inputType = type === "numeric" ? "tel" : type || "text";
  const inpMode = type === "numeric" ? "numeric" : "text";
  return (
    <input
      ref={ref}
      disabled={disabled ? "disabled" : null}
      className={`h-14 w-14 rounded-md border bg-[var(--input-bg-color)] border-[var(--input-border-color)] text-center focus:border-2 focus:border-primary focus:outline-none`}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      placeholder={value}
      aria-label={value}
      maxLength="1"
      autoComplete="off"
      type={inputType}
      autoFocus={index === 0}
      inputMode={inpMode}
      pattern={type === "numeric" ? "[0-9]*" : "^[a-zA-Z0-9]+$"}
      onFocus={onFocus}
      onBlur={onBlur}
      onPaste={handlePaste}
      value={value}
    />
  );
});

export default InputElement;
