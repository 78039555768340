import { AppParamsContext } from "contexts/AppParamsContext";
import { useContext } from "react";

export default function ItemWrapper({
  children,
  assembledItem,
  onClick,
  banned,
  className,
}) {
  const { appParams } = useContext(AppParamsContext);

  const handleClick = () => {
    if (typeof onClick === "function" && !banned) onClick();
  };

  return (
    <div
      className={`${appParams?.items?.showImage ? "with-img" : "no-img"} ${
        banned ? "banned" : ""
      } ${appParams?.items?.imageFirst ? "flex-row-reverse" : ""} ${
        className || ""
      }`}
      id={assembledItem?.uuid}
      onClick={handleClick}
    >
      {children}
    </div>
  );
}
