import {useItem} from '@adamlip/okeoke.client';
import OrderItemModalV3 from 'components/Modals/OrderItemModalV3';
import {AppParamsContext} from 'contexts/AppParamsContext';
import {UserContext} from 'contexts/UserContext';
import useBasket from 'hooks/useBasket';
import {useContext, useState} from 'react';
import toast from 'react-hot-toast';
import SwipeableItem from 'views/Order/ItemList/Item/ItemLayouts/SwipeableItem';

function SearchItem({itemUUID}) {
  const {addItem} = useBasket();
  const {userLoggedIn} = useContext(UserContext);
  const {appParams} = useContext(AppParamsContext);
  const {assembledItem} = useItem(itemUUID);
  const [itemModalOpen, setItemModalOpen] = useState(false);
  const addItemToBasket = () => {
    setCount(1);
    if (userLoggedIn) {
      if (assembledItem.count > 0 && assembledItem.valid) {
        let response = addItem(assembledItem);
        if (response != null) {
          toast.success(getValue('app_v2_toast_itemAdded'));
        } else {
          console.log(response);
          toast.error(getValue('app_v2_toast_itemFailed'));
        }
      } else {
        toast.error(getValue('app_v2_toast_itemFailed'));
      }
    } else {
      toast.error(getValue('app_v2_toast_needLogin'));
    }
  };

  if (assembledItem?.visibleSizes === 0 || !assembledItem) return null;
  return (
    <div className={`orderItemListItem-placeholder relative overflow-hidden ${appParams?.items?.showImage ? 'with-img' : 'no-img'} ${appParams?.styles?.gaps?.itemGap || 'mb-4'}`}>
      <OrderItemModalV3 isOpen={itemModalOpen} setIsOpen={setItemModalOpen} itemUUID={assembledItem?.uuid} readOnly={false} />
      <SwipeableItem assembledItem={assembledItem} addItemToBasket={addItemToBasket} banned={false} readOnly={false} setItemModalOpen={setItemModalOpen} />
    </div>
  );
}

export default SearchItem;
