import { Link, useNavigate } from 'react-router-dom';
import './style.scss';
function ErrorFallback({ error, resetErrorBoundary }) {
  let navigate = useNavigate();

  let title = 'Hiba történt'; //"Error"
  let message = 'valami itt nem stimmel...'; //"Something went wrong..."
  let tryAgain = 'Újra próbálom'; //"Try again"
  let back = 'Vissza'; //"Back"
  let reset = 'App újraindítása'; //"Reset app"

  console.error(error);

  const handleClickTryAgain = () => {
    resetErrorBoundary();
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleClickReset = () => {
    navigate('/');
    window.location.reload();
  };

  return (
    <div className='error-handler'>
      <div className='error-handler-title'>{title}</div>
      <div className='error-handler-message'>{message}</div>
      <div className='error-handler-actions'>
        <div className='error-handler-action try-again' onClick={handleClickTryAgain}>
          {tryAgain}
        </div>
        <div className='error-handler-action go-back' onClick={handleClickBack}>
          {back}
        </div>
        <Link to='/'>
          <div className='error-handler-action reset-app' onClick={handleClickReset}>
            {reset}
          </div>
        </Link>
      </div>
    </div>
  );
}

export default ErrorFallback;
