import {useClient, useDictionary} from '@adamlip/okeoke.client';
import IconComponent from 'components/IconComponent';
import {AppParamsContext} from 'contexts/AppParamsContext';
import {useContext} from 'react';

export default function Languages({close, saveToLocalStorage}) {
  const {getValue} = useDictionary();
  const {setLanguage, languages} = useClient();
  const {appParams} = useContext(AppParamsContext);
  const selectLanguage = (code) => {
    if (saveToLocalStorage) {
      localStorage.setItem('isUserSelectedLanguage', true);
    }
    if (typeof close === 'function') close();
    setLanguage(code);
  };
  return (
    <div className={`flex flex-col gap-4 grow overflow-hidden ${appParams?.styles?.fonts?.capitalize ? 'font-extrabold uppercase' : ''}`}>
      <div className='font-bold text-xl'>{getValue('app_v2_language_accordionTitle')}</div>
      <div className='flex flex-col mb-12'>
        {Object.values(languages || {}).map((lang) => (
          <div
            key={lang.code}
            className={`language-select-btn shadow-sm p-2 mt-4 ${lang.code} ${'bg-[var(--main-secondary-bg-color)]'}`}
            onClick={() => {
              selectLanguage(lang.code);
            }}>
            {appParams?.styles?.languages?.[lang?.flagCode] ? (
              <div className={'h-[36px] mr-4'}>
                <IconComponent iconCode={appParams?.styles?.languages?.[lang?.flagCode]} />
              </div>
            ) : (
              <img src={`https://cdn.okeoke.io/global/flags/${lang?.flagCode || 'hu'}.svg`} alt='flag' className='h-[32px] mr-4' />
            )}

            <span className={appParams?.styles?.fonts?.capitalize ? 'font-bold uppercase lang-name' : 'lang-name'}>{lang.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
