import {useClient, useLocation} from '@adamlip/okeoke.client';
import {createContext, useEffect, useState} from 'react';
import merge from 'lodash.merge';
import cloneDeep from 'clone-deep';
import {setProps} from 'utils/SetProps';
import {Helpers} from 'utils/Helpers-v1';

const AppParamsContext = createContext();

const AppParamsContextProvider = ({children}) => {
  const {appParams, brandID} = useClient();
  const {location} = useLocation();

  const [localAppParams, setLocalAppParams] = useState({});
  const [appParamsLoaded, setAppParamsLoaded] = useState(false);

  useEffect(() => {
    if (location != null && appParams != null) {
      if (location?.attributes?.locationParams?.brandApp != null && appParams?.general?.appParamsByLocations === true) {
        let params = cloneDeep(appParams);
        let newValues = merge(params, location?.attributes?.locationParams?.brandApp);
        setLocalAppParams({...newValues});
      } else {
        setLocalAppParams(appParams);
      }
    }
  }, [location, appParams]);
  useEffect(() => {
    if (localAppParams != null) {
      if (localAppParams?.navigation?.defaultUrl != null && localAppParams?.navigation?.defaultUrl !== '' && location.pathname === '/') {
        navigate(localAppParams?.navigation?.defaultUrl);
      }
      setProps(localAppParams);
      Helpers.sendMessageToWebView(JSON.stringify({action: 'appParams', data: localAppParams}));
      //Mozata font
      if (brandID === 'pbz9fdc3') {
        document.documentElement?.style.setProperty('--body-font', 'Gotham');
      }
      setAppParamsLoaded(true);
    }
  }, [localAppParams]);

  return <AppParamsContext.Provider value={{appParams: localAppParams, appParamsLoaded}}>{Object.keys(localAppParams).length !== 0 && children}</AppParamsContext.Provider>;
};

export {AppParamsContext, AppParamsContextProvider};
