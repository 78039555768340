import { useClient, useDictionary } from "@adamlip/okeoke.client"
import { Card, CardBody } from "@nextui-org/react"
import { useEffect, useState } from "react"

function SizeSelector({ sizes, setSize, selectedSizeUUID }) {
  const { getName } = useClient()
  const { getValue } = useDictionary()
  const [sizeArray, setSizeArray] = useState([])

  const handleClickOnSize = (sizeUUID) => {
    setSize(sizeUUID)
  }

  useEffect(() => {
    const visibleSizes = sizes.filter(
      (size) => size?.availability?.visible === 1
    )
    setSizeArray(visibleSizes)
    if (visibleSizes?.length === 1) {
      setSize(visibleSizes[0].uuid)
    }
  }, [sizes])

  if (sizeArray?.length < 2) return null
  //console.log('sizeArray', sizeArray);
  return (
    <div className="flex flex-row gap-2 items-center justify-center my-2 flex-wrap p-2">
      {sizeArray
        ?.sort((a, b) => (a?.idx > b?.idx ? 1 : -1))
        ?.map((size) => {
          return (
            <Card
              key={size?.uuid}
              isPressable
              onPress={() => handleClickOnSize(size?.uuid)}
              className={`w-[80vw] ${
                selectedSizeUUID === size?.uuid
                  ? "bg-[var(--button-bg-color)] text-[var(--button-text-color)]"
                  : "bg-[var(--main-secondary-bg-color)]"
              } `}
            >
              <CardBody>
                <div className="text-lg font-bold">{getName(size?.name)}</div>

                <div
                  className={`text-sm w-full text-right ${
                    selectedSizeUUID === size?.uuid
                      ? "text-current"
                      : "text-primary"
                  } `}
                >
                  {size?.price != null ? size?.price + " Ft" : ""}
                </div>
              </CardBody>
            </Card>
          )
        })}
    </div>
  )
}

export default SizeSelector
