import { Input } from "@nextui-org/react";
import IconComponent from "components/IconComponent";
import { AppParamsContext } from "contexts/AppParamsContext";
import { useContext } from "react";

export default function CustomInput(props) {
  const { appParams } = useContext(AppParamsContext);

  const handleCancel = () => {
    if (typeof props.onCancel === "function") props.onCancel();
  };

  return (
    <div className="flex items-center">
      <Input
        aria-label="input"
        classNames={{
          inputWrapper:
            "bg-[var(--input-bg-color)] text-[var(--input-text-color)]",
          label: `${
            appParams?.styles?.fonts?.capitalize ? "font-bold uppercase" : ""
          } group-data-[filled-within=true]:text-[var(--input-text-color)] text-[var(--input-text-color)] group-data-[filled-within=true]:text-[12px]`,
        }}
        fullWidth={true}
        type="text"
        {...props}
      />
      {props.type === "date" && (
        <div className="h-[20px] w-[20px] ml-3 mr-1" onClick={handleCancel}>
          <IconComponent iconCode="cancel2" />
        </div>
      )}
    </div>
  );
}
