import { useClient, useDictionary } from "@adamlip/okeoke.client";
import { Button } from "@nextui-org/react";
import useBasket from "hooks/useBasket";

export default function Footer({ goToNext }) {
  const { orderItems } = useBasket();
  const { appParams } = useClient;
  const { getValue } = useDictionary();
  const { paymentsNeeded } = useBasket();

  return (
    <div className="basket-slide-footer">
      <Button
        isDisabled={orderItems?.filter((item) => item?.count > 0)?.length === 0}
        size="lg"
        fullWidth
        color="primary"
        className={`${
          appParams?.styles?.fonts?.capitalize ? "font-extrabold uppercase" : ""
        } rounded-[var(--order-button-border-radius)]`}
        onClick={goToNext}
      >
        {`${getValue(
          "app_v2_basket_slider_order_btn"
        )} ( ${paymentsNeeded} Ft )`}
      </Button>
    </div>
  );
}
