import ClipLoader from 'react-spinners/ClipLoader';
import LoadSvg from 'utils/LoadSvg-v3';

function ChangeCountButton(props) {
  const {count, changeCount, increaseCount, decreaseCount, onClick, className, type, reset, params, min, max, isLoading, changeCountStyle} = props;
  const handleIncrease = () => {
    if (isLoading) return null;
    if (count === 1 && type !== 'stackableMultiple') return null;
    if (typeof changeCount === 'function') {
      if (max != null && max !== 0 && parseInt(count) === parseInt(max)) {
        return null;
      } else {
        count == null ? changeCount(1) : changeCount(parseInt(count) + 1);
      }
    }
    if (typeof increaseCount === 'function') {
      params == null ? increaseCount() : increaseCount(params);
    }
  };

  const handleDecrease = () => {
    if (isLoading) return null;
    if (typeof changeCount === 'function') {
      if (min != null && parseInt(count) === parseInt(min)) {
        return null;
      } else {
        if (type === 'stackableMultiple') {
          if (count > 0) changeCount(count - 1);
        } else {
          if (count > 1) changeCount(count - 1);
        }
      }
    }
    if (typeof decreaseCount === 'function') {
      if (type === 'stackableMultiple' && count > 0) {
        params == null ? decreaseCount() : decreaseCount(params);
      } else {
        if (count > 1) {
          params == null ? decreaseCount() : decreaseCount(params);
        }
      }
    }
  };

  const handleReset = () => {
    if (isLoading) return null;
    if (typeof changeCount === 'function') changeCount(min == null ? 0 : min);
    if (typeof reset === 'function') {
      params == null ? reset() : reset(params);
    }
  };

  const handleClick = () => {
    if (isLoading) return null;
    if (typeof onClick === 'function') {
      params == null ? onClick() : onClick(params);
    }
  };

  return (
    <div className={`change-count-button-outer-container ${isLoading ? 'loading-on' : 'loading-off'}`}>
      {/* {!isLoading && reset && count > 0 && (
        <div
          className="hidden-content appear-quick change-count-button-reset"
          onClick={handleReset}
        >
          <LoadSvg name="setTrash" />
        </div>
      )} */}
      <div
        className={`change-count-button-root ${type != null ? type : ''} ${className != null ? className : ''} ${count > 0 ? 'selected' : 'not-selected'}`}
        style={{
          height: changeCountStyle?.height === 'fullHeight' ? '100%' : '',
          backgroundColor: changeCountStyle?.bgColor || '',
          width: changeCountStyle?.width || '',
        }}
        onClick={handleClick}>
        {!isLoading && (
          <>
            {type === 'multiple' && (
              <>
                {(count == null || count === 0) && (
                  <div className='empty hidden-content appear-quick flex-center' onClick={handleIncrease}>
                    <LoadSvg name='plusIcon' />
                  </div>
                )}
                {count > 0 && (
                  <div className='empty hidden-content appear-quick flex-center' onClick={count > 1 ? handleDecrease : handleReset}>
                    <LoadSvg name='setCheck' />
                  </div>
                )}
              </>
            )}
            {type === 'stackableMultiple' && (
              <>
                {(count == null || count === 0) && (
                  <div className='empty hidden-content appear-quick flex-center' onClick={handleIncrease}>
                    <LoadSvg name='plusIcon' />
                  </div>
                )}
                {count > 0 && (
                  <div className='stackable-multiple hidden-content appear-quick'>
                    <div
                      style={{
                        backgroundColor: changeCountStyle?.fontColor || '',
                      }}
                      className={`icon-container minus ${count === 0 || (min != null && min !== 0 && count <= min) ? 'disabled' : 'enabled'}`}
                      onClick={handleDecrease}>
                      <LoadSvg name='minusIcon' />
                    </div>
                    <div
                      className='change-count-button-count'
                      style={{
                        color: changeCountStyle?.fontColor || '',
                      }}>
                      {count}
                    </div>
                    <div
                      style={{
                        backgroundColor: changeCountStyle?.fontColor || '',
                      }}
                      className={`icon-container plus ${max != null && max !== 0 && count >= max ? 'disabled' : 'enabled'}`}
                      onClick={handleIncrease}>
                      <LoadSvg name='plusIcon' />
                    </div>
                  </div>
                )}
              </>
            )}
            {type === 'radio' && (
              <>
                {(count == null || count === 0) && (
                  <div className='empty' onClick={handleIncrease}>
                    <LoadSvg name='plusIcon' />
                  </div>
                )}
                {count > 0 && (
                  <div className='hidden-content appear-quick flex-center' onClick={handleDecrease}>
                    <LoadSvg name='setCheck' />
                  </div>
                )}
              </>
            )}
          </>
        )}
        {isLoading && (
          <div className='change-count-button-loading hidden-content appear flex-center'>
            <ClipLoader color={'#fff'} size={20} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ChangeCountButton;
