import {ScrollShadow} from '@nextui-org/react';
import Footer from './Footer';
import {useDictionary, useItems} from '@adamlip/okeoke.client';
import useBasket from 'hooks/useBasket';
import BasketItem from './BasketItem';
import Delivery from './Delivery';
import Notes from './Notes';
import ServiceFee from './ServiceFee';
import {useContext, useEffect, useState} from 'react';
import {AppParamsContext} from 'contexts/AppParamsContext';

export default function BasketItems({goToNext}) {
  const {appParams} = useContext(AppParamsContext);

  const {getValue} = useDictionary();
  const {orderItems, validation} = useBasket();
  const {assembledItems} = useItems();

  const [nutritionalValues, setNutritionalValues] = useState({});
  const [sumNutritionalValues, setSumNutritionalValues] = useState({});

  useEffect(() => {
    let values = {};

    for (let item of orderItems) {
      if (assembledItems?.[item.itemUUID]) {
        if (Object.keys(assembledItems?.[item.itemUUID]?.nutritionalValues)?.length > 0) {
          values = {
            ...values,
            [item.orderItemUUID]: {
              count: item.count,
              ...assembledItems?.[item.itemUUID]?.nutritionalValues,
            },
          };
        }
      }
    }

    setNutritionalValues(values);
  }, [orderItems, assembledItems]);

  useEffect(() => {
    if (nutritionalValues != null) {
      let sumValues = {
        carbohydrates: 0,
        carbohydratesSugars: 0,
        energykcal: 0,
        energykj: 0,
        fats: 0,
        fatsSaturates: 0,
        fibre: 0,
        protein: 0,
        salt: 0,
      };
      for (let item of Object.values(nutritionalValues)) {
        for (let [key, value] of Object.entries(item)) {
          if (key !== 'count') {
            sumValues = {
              ...sumValues,
              [key]: sumValues?.[key] + item.count * +value,
            };
          }
        }
      }
      setSumNutritionalValues(sumValues);
    }
  }, [nutritionalValues]);

  if (orderItems == null) return null;
  return (
    <div className='h-full flex flex-col gap-2 grow pb-10'>
      <div className={`${appParams?.styles?.fonts?.capitalize ? 'font-extrabold uppercase' : ''} basket-slide-header  font-bold text-xl`}>
        {getValue('app_v2_basket_slider_order')}
      </div>
      <div className='grow overflow-hidden'>
        <ScrollShadow className='h-full'>
          <div className='h-full overflow-hidden overflow-y-scroll touch-pan-y	'>
            {validation?.minimumPriceValid === false && (
              <div className='min-price-text'>{`${getValue('app_v2_minimumBasketPrice')}: ${appParams?.basketModal?.minBasketValue} Ft`}</div>
            )}
            {orderItems.map((item) => (
              <BasketItem key={item.orderItemUUID} orderItemUUID={item.orderItemUUID} orderItems={orderItems} />
            ))}
            <Delivery />
            <ServiceFee />
            {appParams?.basketModal?.showNutritionalValues && (
              <div className='p-2'>
                {Object.entries(sumNutritionalValues)
                  .filter((value) => Number.isInteger(value[1]) && value[1] > 0)
                  .map((value) => (
                    <div className='flex gap-2' key={value[0]}>
                      <div className=''>{getValue(`app_v2_${value[0]}`)}:</div>
                      <div className=''>{value[1]}</div>
                    </div>
                  ))}
              </div>
            )}

            <Notes />
          </div>
        </ScrollShadow>
      </div>
      <Footer goToNext={goToNext} />
    </div>
  );
}
