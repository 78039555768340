import { useBilling, useClient, useDictionary } from "@adamlip/okeoke.client";
import InvoiceAddresses from "components/InvoiceAddresses";
import useBasket from "hooks/useBasket";
import { useEffect, useContext } from "react";
import { Switch } from "@nextui-org/react";
import { AppParamsContext } from "contexts/AppParamsContext";

export default function InvoiceInfo() {
  const { getValue } = useDictionary();
  const { handleInvoiceNeeded, invoiceNeeded } = useBasket();

  const { features } = useClient();
  const { appParams } = useContext(AppParamsContext);
  const handleLoading = (e) => {};
  const { subscriptions } = useBilling();

  useEffect(() => {
    if (
      subscriptions?.brand?.["license.vatinvoice"] &&
      appParams?.order?.forceInvoiceNeeded
    ) {
      handleInvoiceNeeded(1);
    }
    // eslint-disable-next-line
  }, [appParams, features]);

  if (!subscriptions?.brand?.["license.vatinvoice"]) return null;

  if (
    appParams?.basketModal?.visibleBlocks?.invoice == null ||
    appParams?.basketModal?.visibleBlocks?.invoice === false
  )
    return null;

  return (
    <div
      className={`${
        appParams?.styles?.fonts?.capitalize ? "font-extrabold uppercase" : ""
      } rounded-[var(--order-button-border-radius)] flex flex-col px-2 touch-pan-y`}
    >
      <p className="font-bold text-xl mb-2">
        {getValue("app_v2_invoiceAddress")}
      </p>
      <div className="invoice-needed items-center justify-between w-full flex mb-2">
        {appParams?.order?.forceInvoiceNeeded &&
          getValue("app_v2_basket_slider_invoice_needed_text")}
        {!appParams?.order?.forceInvoiceNeeded && (
          <>
            <Switch
              isSelected={invoiceNeeded}
              onValueChange={() => handleInvoiceNeeded(invoiceNeeded ? 0 : 1)}
            >
              {getValue("app_v2_invoiceAddress_invoiceNeeded")}
            </Switch>
          </>
        )}
      </div>
      {invoiceNeeded === 1 && (
        <InvoiceAddresses
          setAddressInOrder={true}
          handleLoading={handleLoading}
        />
      )}
    </div>
  );
}
