import { useClient, useDictionary } from "@adamlip/okeoke.client";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { InvoiceAPI } from "apis/InvoiceApi";
import { UserContext } from "contexts/UserContext";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Helpers } from "utils/Helpers-v1";
import axios from "axios";
import Inputs from "./Inputs";
import ConfigInputs from "./ConfigInputs";
import useBasket from "hooks/useBasket";

export default function EditDeliveryAddressModal({
  data,
  isOpen,
  setIsOpen,
  onSave,
  onDelete,
}) {
  const { brandID } = useClient();
  const { getValue } = useDictionary();
  const { setDeliveryInfo } = useBasket();
  const { updateAddress, addAddress, deleteAddress } = useContext(UserContext);

  const dataTemplate = {
    addressLabel: "",
    name: "",
    state: "",
    zip: "",
    street: "",
    streetNumber: "",
    telNumber: "",
    taxNumber: "",
    taxType: "",
    city: "",
    countryCode: "HU",
    floor: "",
  };

  const [userData, setUserData] = useState(dataTemplate);
  const [userDataHelperText, setUserDataHelperText] = useState({
    addressLabel: null,
    name: null,
    state: null,
    zip: null,
    street: null,
    streetNumber: null,
    telNumber: null,
    city: null,
  });
  const [loading, setLoading] = useState(false);

  const [addressConfig, setAddressConfig] = useState(null);

  const requiredFields =
    addressConfig == null
      ? ["addressLabel", "name", "zip", "street", "streetNumber", "city"]
      : ["addressLabel", "name", "streetNumber", "street"];

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    try {
      if (
        e.target.name === "zip" &&
        e.target.value.replaceAll("_", "").length === 4
      )
        getRegion(e.target.value);
    } catch (e) {
      console.log(e);
    }
  };

  const getRegion = async (zip) => {
    let data = null;
    InvoiceAPI.getRegionByZip(zip)
      .then((response) => {
        if (response.data?.code != null) {
          let newUserData = { ...userData };
          newUserData.state = response.data.state;
          newUserData.city = `${response.data.region}${
            response.data.sub_region !== ""
              ? ", " + response.data.sub_region
              : ""
          }`;
          newUserData.zip = response.data.code;

          let newUserDataHelperText = { ...userDataHelperText };
          newUserDataHelperText.zip = null;
          setUserData(newUserData);
          data = newUserDataHelperText.zip;
        } else {
          let newUserDataHelperText = { ...userDataHelperText };
          newUserDataHelperText.zip = getValue("app_v2_address_invalidZip");
          data = newUserDataHelperText.zip;
        }
      })
      .catch((error) => console.error(error));
    return data;
  };

  const validateData = () => {
    setLoading(true);

    let newData = { ...userDataHelperText };
    let valid = true;

    if (Object.keys(userData || {}).length > 0) {
      for (const [key, value] of Object.entries(userData)) {
        if (requiredFields.includes(key)) {
          newData[key] =
            value === "" ? getValue("app_v2_address_requiredField") : null;
          let isThisValid = value === "" ? false : true;
          valid = valid === false ? false : isThisValid;
        }
        if (key === "telNumber") {
          try {
            let repValue = Helpers.replaceAll(value, { _: "", " ": "" });
            if (repValue.length > 0) {
              if (repValue.length !== 12) {
                newData.telNumber = getValue("app_v2_address_invalidPhone");
                valid = false;
              } else {
                newData.telNumber = null;
              }
            } else {
              valid = false;
              newData.telNumber = getValue("app_v2_address_invalidPhone");
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    } else {
      valid = false;
    }
    console.log("newData", newData, valid);
    setUserDataHelperText(newData);

    return valid;
  };

  const formatPhoneNumber = (input) =>
    Helpers.replaceAll(input, { _: "", " ": "" });

  const saveUserData = () => {
    let dataValid = validateData();

    let newUserData = { ...userData };
    newUserData.telNumber = formatPhoneNumber(userData.telNumber);
    if (newUserData.countryCode == null) newUserData.countryCode = "HU";
    if (newUserData.addressType == null) newUserData.addressType = 2;

    if (dataValid) {
      if (userData.id == null) {
        let address = {
          ...newUserData,
          taxNumber: "",
          taxType: "",
          floor: "",
        };
        if (addressConfig) {
          address = {
            ...address,
            city: addressConfig?.city || "",
            zip: addressConfig?.zip || "",
          };
        }
        addAddress(address)
          .then((res) => handleResponse(res, address))
          .catch(handleError);
      } else {
        let address = {
          ...newUserData,
        };
        if (addressConfig) {
          address = {
            ...address,
            city: addressConfig?.city || "",
            zip: addressConfig?.zip || "",
          };
        }
        updateAddress(address)
          .then((res) => handleResponse(res, address))
          .catch(handleError);
      }
    } else {
      toast.error(getValue("app_v2_toast_userDataInvalid"));
      setLoading(false);
    }
  };

  const handleResponse = (res, address) => {
    setLoading(false);
    if (res != null) {
      console.log(res);
      toast.success(getValue("app_v2_toast_userDataSaved"));
      setUserData(dataTemplate);
      setDeliveryInfo(address);
      setIsOpen(false);
      onSave(res);
    }
  };

  const handleError = (error) => {
    console.log(error);
    toast.error(getValue("app_v2_toast_userDataInvalid"));
    setLoading(false);
  };

  const handleDeleteAddress = () => {
    if (userData?.id) {
      deleteAddress(data?.id).then((res) => {
        if (res === 1) {
          setIsOpen(false);
          if (typeof onDelete === "function") onDelete();
        }
      });
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (data) {
      setUserData(data);
    }
  }, [data, addressConfig]);

  useEffect(() => {
    if (brandID) {
      try {
        axios
          .get(`https://cdn.okeoke.io/brands/${brandID}/address.json`)
          .then((res) => {
            if (res.status === 200) {
              setAddressConfig(res.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (e) {
        console.log(e);
      }
    }
  }, [brandID]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => {
        setIsOpen(!isOpen);
      }}
      size="full"
      radius="none"
      scrollBehavior="inside"
      className="bg-background"
      placement="bottom-center"
      classNames={{
        base: "m-0 max-w-[820px]",
        wrapper: "sm:items-end",
        closeButton:
          "z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]",
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>
              {getValue("app_v2_deliveryAddress_modal_title")}
            </ModalHeader>
            <ModalBody className="px-2">
              <div className="flex flex-col gap-2">
                {addressConfig == null && (
                  <Inputs
                    userData={userData}
                    userDataHelperText={userDataHelperText}
                    handleChange={handleChange}
                  />
                )}
                {addressConfig != null && (
                  <ConfigInputs
                    userData={userData}
                    userDataHelperText={userDataHelperText}
                    handleChange={handleChange}
                    addressConfig={addressConfig}
                  />
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              {userData?.id != null && (
                <Button
                  variant="light"
                  color="danger"
                  onClick={handleDeleteAddress}
                  className="ml-0 mr-auto"
                >
                  {getValue("app_v2_address_delete_delivery")}
                </Button>
              )}
              <Button
                fullWidth
                color="danger"
                isDisabled={loading}
                onClick={handleCancel}
                variant="flat"
              >
                {getValue("app_v2_address_cancel")}
              </Button>
              <Button
                fullWidth
                color="success"
                variant="flat"
                isDisabled={loading}
                onClick={saveUserData}
              >
                {getValue("app_v2_address_save")}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
