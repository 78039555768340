import { useClient, useItems, useOrder } from "@adamlip/okeoke.client"
import { createContext, useEffect, useRef, useState, useContext } from "react"
import useStorage from "hooks/useStorage"
import moment from "moment"
import { AppParamsContext } from "contexts/AppParamsContext"

const BasketContext = createContext()

function BasketContextProvider({ children }) {
  const { appParams } = useContext(AppParamsContext)
  const {
    locations,
    selectedLocationUUID,
    setSelectedLocation,
    activeBasketUUID,
    ordersInited,
  } = useClient()

  const {
    setTable,
    startNewOrder,
    setRequestedReadyTime,
    requestedReadyTime,
    orderItems,
    sumDiscountServiceFee,
    serviceFee,
    location,
    locationUUID,
    submit,
    paymentsNeeded,
    tableUUID,
    itemCount,
    orderState,
    discountPrice,
    setInvoiceNeeded,
    invoiceNeeded,
    invoiceInfo,
    sumDiscountPrice,
    setPaymentType,
    paymentType,
    setOrderIdentifier,
    setTakeAway,
    takeAway,
    orderIdentifier,
    tableInfo,
    vipPoints,
    order,
    setNotes,
    deliveryCost,
    orderUUID,
    orderItemsAssembled,
    setDeliveryInfo,
    addItem,
    deliveryInfo,
    notes,
    reserveVip,
    setInvoiceInfo,
    finalizeVip,
  } = useOrder(null, activeBasketUUID)

  const { availability, assembledItems } = useItems()
  const { getSV, setSV, removeSV } = useStorage()

  const [canOrderFromSelectedLocation, setCanOrderFromSelectedLocation] =
    useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [validation, setValidation] = useState({})
  const validationRef = useRef({})
  const [selectedPayment, setSelectedPayment] = useState(null)
  const [isUserSelectedLocation, setIsUserSelectedLocation] = useState(false)
  const [isUserConfirmedTable, setIsUserConfirmedTable] = useState(false)
  const [savedTable, setSavedTable] = useState(null)
  const [savedTableTimestamp, setSavedTableTimestamp] = useState(null)
  const [selectedSubCategory, setSelectedSubCategory] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("")
  const orderLoaded = useRef(false)

  const checkLocationMatch = () => {
    if (savedTable?.locationUUID) {
      setSelectedLocation(savedTable?.locationUUID)
    } else {
      if (locationUUID != null && selectedLocationUUID !== locationUUID) {
        setSelectedLocation(locationUUID)
      }
    }
  }

  const updateValidationObject = (key, value) => {
    validationRef.current = { ...validationRef.current, [key]: value }
    setValidation({ ...validationRef.current })
  }

  const handleTableSet = (value) => {
    setSavedTable(value)
    setSavedTableTimestamp(moment().format("YYYY-MM-DD HH:mm"))
    if (value) {
      setSV("savedTableTimestamp", moment().format("YYYY-MM-DD HH:mm"))
      setSV("savedTable", JSON.stringify(value))
      console.log("BasketContextLocation")
      setSelectedLocation(value.locationUUID)
      setIsUserSelectedLocation(true)
    } else {
      removeSV("savedTable")
      removeSV("savedTableTimestamp")
      setTable(null)
    }
  }

  useEffect(() => {
    setCanOrderFromSelectedLocation(locations?.[locationUUID]?.openState?.open)
    updateValidationObject(
      "locationValid",
      locations?.[locationUUID]?.openState?.open
    )
  }, [locations, locationUUID])

  useEffect(() => {
    if (invoiceNeeded) {
      updateValidationObject("invoiceValid", invoiceInfo != null)
      if (
        invoiceInfo != null &&
        invoiceInfo?.taxType === "HAS_TAX_NUMBER" &&
        invoiceInfo?.taxNumber.length !== 13
      ) {
        console.log("BasketContext")
        updateValidationObject("invoiceValid", false)
      } else {
        updateValidationObject("invoiceValid", true)
      }
    } else {
      updateValidationObject("invoiceValid", true)
    }
  }, [invoiceNeeded, invoiceInfo])

  useEffect(() => {
    let anyError = false
    for (let row of Object.values(validation)) {
      if (!row) anyError = true
    }
    setCanSubmit(!anyError)
  }, [validation])

  useEffect(() => {
    if (Object.keys(assembledItems || {}).length > 0 && orderItems.length > 0) {
      let anyNotVisible = false
      for (let item of orderItems) {
        if (
          item.count > 0 &&
          assembledItems?.[item.itemUUID]?.sizes?.find(
            (size) => size.uuid === item.selectedSizeUUID
          )?.availability?.visible !== 1
        ) {
          anyNotVisible = true
        }
      }
      updateValidationObject("itemVisibilityValid", !anyNotVisible)
    }
  }, [assembledItems, orderItems])

  useEffect(() => {
    updateValidationObject(
      "minimumPriceValid",
      +(appParams?.basketModal?.minBasketValue || 0) <= +paymentsNeeded
    )
  }, [appParams, paymentsNeeded])

  useEffect(() => {
    if (appParams?.order?.tableSelectionRequired) {
      if (tableInfo == null) {
        updateValidationObject("tableValid", false)
      } else {
        updateValidationObject("tableValid", true)
      }
    } else {
      updateValidationObject("tableValid", true)
    }
  }, [appParams, tableInfo])

  useEffect(() => {
    if (
      !orderLoaded.current &&
      orderUUID != null &&
      orderItemsAssembled != null
    ) {
      orderLoaded.current = true
      checkLocationMatch()
    }
  }, [orderUUID, orderItemsAssembled, savedTable])

  useEffect(() => {
    if (ordersInited && orderState !== 0) {
      startNewOrder({ locationUUID: localStorage["lsl_uuid"] })
    }
  }, [orderState, ordersInited])

  useEffect(() => {
    let table = getSV("savedTable", true)
    let tableTimeStamp = getSV("savedTableTimestamp")
    setSavedTable(table)
    setSavedTableTimestamp(tableTimeStamp)
  }, [])

  return (
    <BasketContext.Provider
      value={{
        startNewOrder,
        setRequestedReadyTime,
        requestedReadyTime,
        setPaymentType,
        paymentType,
        orderItems,
        sumDiscountServiceFee,
        serviceFee,
        orderLocation: { ...location, uuid: locationUUID },
        canOrderFromSelectedLocation,
        submit,
        paymentsNeeded,
        canSubmit,
        itemCount,
        orderState,
        discountPrice,
        setInvoiceNeeded,
        invoiceNeeded,
        invoiceInfo,
        sumDiscountPrice,
        validation,
        setOrderIdentifier,
        setTakeAway,
        takeAway,
        selectedPayment,
        setSelectedPayment,
        tableUUID,
        orderIdentifier,
        isUserSelectedLocation,
        setIsUserSelectedLocation,
        tableInfo,
        isUserConfirmedTable,
        setIsUserConfirmedTable,
        vipPoints,
        order,
        setNotes,
        deliveryCost,
        handleTableSet,
        setDeliveryInfo,
        addItem,
        orderUUID,
        deliveryInfo,
        notes,
        reserveVip,
        setInvoiceInfo,
        updateValidationObject,
        setTable,
        savedTable,
        savedTableTimestamp,
        selectedSubCategory,
        setSelectedSubCategory,
        selectedCategory,
        setSelectedCategory,
        finalizeVip,
      }}
    >
      {children}
    </BasketContext.Provider>
  )
}

export { BasketContext, BasketContextProvider }
