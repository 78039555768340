import React, { useState, useEffect, useContext } from "react";
import { useDictionary } from "@adamlip/okeoke.client";
import useStorage from "hooks/useStorage";
import { UserContext } from "contexts/UserContext";

function SimplePayParams() {
  const { getValue } = useDictionary();
  const { userData } = useContext(UserContext);

  const { getSV, setSV, removeSV } = useStorage();
  const [simplePayCardID, setSimplePayCardID] = useState(
    getSV("simplePayCardID")
  );
  const [savedCards, setSavedCards] = useState({});

  useEffect(() => {
    if (userData != null && Object.keys(userData).length > 0) {
      let savedCardID = getSV("simplePayCardID");
      let savedCardFound = false;
      if (userData.savedCards != null) {
        let obj = {};
        for (let card of userData.savedCards.simplePay) {
          obj[card.id] = card;
          if (+savedCardID === +card.id && card.availableTokens > 0)
            savedCardFound = true;
        }
        if (!savedCardFound) {
          setSimplePayCardID(savedCardID);
        }
        setSavedCards(obj);
      } else {
        setSimplePayCardID(savedCardID);
      }
    }
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    simplePayCardID != null
      ? setSV("simplePayCardID", simplePayCardID)
      : removeSV("simplePayCardID");
    // eslint-disable-next-line
  }, [simplePayCardID]);

  const handleParamSelect = (id) => {
    setSimplePayCardID(id.toString());
  };

  return (
    <div className="simple-pay-params">
      <div
        className="simple-pay-param"
        onClick={() => {
          handleParamSelect("-1");
        }}
      >
        <div className="radio-root">
          <div
            className={`radio-select-circle ${
              simplePayCardID == null || simplePayCardID === "-1"
                ? "selected"
                : "not-selected"
            }`}
          ></div>
        </div>
        <div className="simple-pay-param-text font-small">
          {getValue("app_v2_noRegCard")}
        </div>
      </div>
      <div
        className="simple-pay-param"
        onClick={() => {
          handleParamSelect("0");
        }}
      >
        <div className="radio-root">
          <div
            className={`radio-select-circle ${
              simplePayCardID === "0" ? "selected" : "not-selected"
            }`}
          ></div>
        </div>
        <div className="simple-pay-param-text font-small">
          {getValue("app_v2_saveCardData")}
        </div>
      </div>
      {Object.values(savedCards || {})
        .filter((c) => c.availableTokens > 0)
        .map((card) => (
          <div
            key={card.id}
            className="simple-pay-param"
            onClick={() => {
              handleParamSelect(card.id);
            }}
          >
            <div className="radio-root">
              <div
                className={`radio-select-circle ${
                  simplePayCardID === card.id.toString()
                    ? "selected"
                    : "not-selected"
                }`}
              ></div>
            </div>
            <div className="simple-pay-param-text font-small">
              {card.cardMask}
            </div>
          </div>
        ))}
    </div>
  );
}

export default SimplePayParams;
