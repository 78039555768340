import {OEAsset} from '@adamlip/okeoke.client';
import {AppParamsContext} from 'contexts/AppParamsContext';
import {useContext} from 'react';

export default function Item({assembledItem}) {
  const {appParams} = useContext(AppParamsContext);

  const imageRatioValues = {
    1: {
      width: 'w-[147px]',
      height: appParams?.items?.layoutType !== '3' ? 'h-[110px]' : 'h-[106px]',
    },
    2: {
      width: 'w-[83px]',
      height: appParams?.items?.layoutType !== '3' ? 'h-[110px]' : 'h-[106px]',
    },
    3: {
      width: 'w-[73px]',
      height: appParams?.items?.layoutType !== '3' ? 'h-[110px]' : 'h-[106px]',
    },
    4: {
      width: 'w-[165px]',
      height: appParams?.items?.layoutType !== '3' ? 'h-[110px]' : 'h-[106px]',
    },
    5: {
      width: 'w-[110px]',
      height: appParams?.items?.layoutType !== '3' ? 'h-[110px]' : 'h-[106px]',
    },
  };

  if (!appParams?.items?.showImage) return null;

  if (!appParams?.assets?.defaultItemImg) return null;
  return (
    <div
      className={`flex items-center justify-center shrink-0 ${imageRatioValues?.[appParams?.items?.imageRatio]?.width || 'w-[147px]'} ${
        imageRatioValues?.[appParams?.items?.imageRatio]?.height || 'h-[110px]'
      }`}>
      <OEAsset
        uuid={assembledItem.attributes.appImage}
        addClass={`orderItemListItem-img ${appParams?.items?.imageRadius || ''} ${appParams?.items?.imageFit === 'contain' ? 'object-contain' : 'object-cover'}`}
        size='l'
      />
    </div>
  );
}
