import { useClient, useDictionary, useOkeFrame } from "@adamlip/okeoke.client";
import { MainContext } from "contexts/MainContext";
import useBasket from "hooks/useBasket";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { QrReader } from "react-qr-reader";
import { useNavigate, useParams } from "react-router-dom";
import { Helpers } from "utils/Helpers-v1";
import LoadSvg from "utils/LoadSvg-v3";
import PinInputComponent from "components/PinInputComponent";
import { Button, CircularProgress } from "@nextui-org/react";
import { UserContext } from "contexts/UserContext";
import LoginModal from "views/Login";

export default function SelectTable({ onSelect, navigateOnSelect }) {
  const { id } = useParams();
  const { getValue } = useDictionary();
  const navigate = useNavigate();
  const { frameType } = useOkeFrame();

  const { client, catalogLoading } = useClient();

  const {
    handleTableSet,
    handleStartNewOrder,
    activeBasketUUID,
    ordersInited,
  } = useBasket();

  const { isWebView } = useContext(MainContext);
  const { userLoggedIn } = useContext(UserContext);

  const [pin, setPin] = useState(null);
  const [showQRReader, setShowQRReader] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);

  const [loginModal, setLoginModal] = useState(false);

  const tableReadDone = useRef(null);

  useEffect(() => {
    if (data != null) {
      // alert(JSON.stringify(data));
      let link = data.split("/");
      let pinString = link[link.length - 1];
      setPin(`${pinString[0]}${pinString[1]}${pinString[2]}${pinString[3]}`);
    }
  }, [data]);

  useEffect(() => {
    if (!tableReadDone.current) {
      if (!catalogLoading) {
        if (userLoggedIn) {
          if (ordersInited) {
            if (pin != null) {
              tableReadDone.current = true;
              let response = client.getTableByCode(pin);
              if (response.success) {
                console.log("Select table", response.data);
                handleTableSet(response.data);
                toast.success(`${response.data.name}`);
                if (activeBasketUUID == null || activeBasketUUID === "0") {
                  console.log("Select table start order");
                  handleStartNewOrder({ tableUUID: response.data.uuid });
                }
                if (navigateOnSelect) {
                  navigate("/order");
                } else {
                  setLoading(false);
                }
                if (typeof onSelect === "function") onSelect(response.data);
              } else {
                setLoading(false);
                toast.error(getValue("app_v2_order_select_table_error"));
              }
            } else {
              console.log("Select table - Table code null");
            }
          } else {
            console.log("Select table - Orders not inited");
          }
        } else {
          console.log("Select table - User not logged in");
        }
      } else {
        console.log("Select table - Catalog loading");
      }
    } else {
      console.log("Select table - Table already read!");
    }
  }, [pin, ordersInited, userLoggedIn, activeBasketUUID, catalogLoading]);

  useEffect(() => {
    if (pin?.length > 3) {
      setLoading(true);
    }
  }, [pin]);

  useEffect(() => {
    if (id === "start") {
      navigate("/");
    } else {
      if (userLoggedIn) {
        setPin(id);
      } else {
        setLoginModal(true);
      }
    }
  }, [userLoggedIn]);

  const handleClickOnReadQr = (message, cancelButtonText) => {
    if (isWebView) {
      let data = {
        purpose: "selectTable",
        message,
        cancelButtonText,
      };
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ action: "readQRCode", data })
      );
    } else if (frameType === "NONE") {
      console.log("none");
      setShowQRReader(true);
    } else {
      let data = {
        purpose: "selectTable",
        message,
        cancelButtonText,
      };
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ action: "readQRCode", data })
      );

      Helpers.sendMessageToWebView(
        JSON.stringify({ action: "readQRCode", data })
      );
    }
  };

  if (loading)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <CircularProgress color="primary" size="lg" aria-label="aria label" />
      </div>
    );

  if (showQRReader)
    return (
      <div className="bg-[var(--main-secondary-bg-color)] mt-10">
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            if (!!result) {
              setData(result?.text);
            }

            if (!!error) {
              console.log(error);
              if (error === "DOMException: Requested device not found") {
                toast.error(getValue("app_v2_order_getqr_read_error"));
                setShowQRReader(false);
              }
            }
          }}
          videoContainerStyle={{
            position: "unset",
            borderRadius: "10px",
            pointerEvent: "none",
          }}
          ViewFinder={() => {
            return (
              <>
                <LoadSvg className="qrreaderview" name="qrcode" />
                <div
                  className="close-icon"
                  onClick={() => setShowQRReader(false)}
                >
                  <LoadSvg name="setClose" />
                </div>
              </>
            );
          }}
          className="qrcode-container"
        />
      </div>
    );

  return (
    <>
      {loginModal && (
        <LoginModal
          isOpen={loginModal}
          setIsOpen={setLoginModal}
          canGuestLogin={true}
          forceVisible={true}
        />
      )}
      <div className="select-table-title font-extraLarge font-bold text-center font-uppercase mt-4 mb-2">
        {getValue("app_v2_selectTable_title")}
      </div>
      <div className="text-center">
        <Button
          className="btn primary"
          onClick={() =>
            handleClickOnReadQr(
              getValue("app_v2_order_getqr_message"),
              getValue("app_v2_order_getqr_cancelButtonText")
            )
          }
        >
          {getValue("app_v2_readQr")}
        </Button>
      </div>
      <div className="font-extraLarge font-bold text-center mt-2 mb-2">
        {getValue("app_v2_or")}
      </div>
      <div className="px-8 py-2">
        <PinInputComponent
          length={4}
          onComplete={(value) => setPin(value)}
          className="mb-8 w-full justify-between"
          focusOnLoad={true}
          type="numeric"
        />
      </div>
    </>
  );
}
