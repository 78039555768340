import {
  OEAsset,
  useClient,
  useDictionary,
  useItem,
} from "@adamlip/okeoke.client";
import {
  Button,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ScrollShadow,
} from "@nextui-org/react";
import ChangeCountButton from "components/ChangeCountButton";
import LoginModal from "views/Login";
import ModifierGroupSelector from "components/Modals/OrderItemModalV3/ModifierGroupSelector";
import SizeSelector from "components/Modals/OrderItemModalV3/SizeSelector";
import UpsaleItem from "components/UpsaleItem";
import { UserContext } from "contexts/UserContext";
import useStorage from "hooks/useStorage";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Element, Events, scroller } from "react-scroll";
import LoadSvg from "utils/LoadSvg-v3";
import { Logger } from "utils/Logger-v1";
import useBasket from "hooks/useBasket";
import { AppParamsContext } from "contexts/AppParamsContext";

function UpSaleItemModal({ isOpen, setIsOpen, itemUUID, readOnly, sizeUUID }) {
  const { getName, tokenData } = useClient();
  const { appParams } = useContext(AppParamsContext);
  const { getValue } = useDictionary();
  const {
    assembledItem,
    setSize,
    setCount,
    setModifierCount,
    setTakeAway,
    setNotes,
    getUpsales,
  } = useItem(itemUUID, sizeUUID);
  const { addItem } = useBasket();
  const { getSV } = useStorage();

  const { userLoggedIn } = useContext(UserContext);
  const [firstInvalid, setFirstInvalid] = useState(null);
  const [itemNotes, setItemNotes] = useState("");
  const [allergens, setAllergens] = useState([]);
  const [upsaleItems, setUpsaleItems] = useState([]);
  const [tryedToAdd, setTryedToAdd] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  useEffect(() => {
    if (assembledItem != null) {
      let items = getUpsales();
      if (Array.isArray(items)) setUpsaleItems(items);
    }
    // eslint-disable-next-line
  }, [assembledItem]);

  useEffect(() => {
    if (
      assembledItem != null &&
      typeof setCount === "function" &&
      (assembledItem.count < 1 || assembledItem.count == null)
    )
      setCount(1);

    if (assembledItem != null) {
      let allergenArray = [];
      for (let [key, value] of Object.entries(assembledItem.allergens)) {
        if (value != null && value > 0) allergenArray.push(key);
      }
      setAllergens(allergenArray);
    }
    // eslint-disable-next-line
  }, [assembledItem]);

  const addItemToBasket = () => {
    if (userLoggedIn) {
      if (assembledItem.count > 0 && assembledItem.valid) {
        let response = addItem(assembledItem);
        Logger.add("Item added, id: " + response);
        //console.log("Item added, id: "+response)
        if (response != null) {
          toast.success(getValue("app_v2_toast_itemAdded"));
          setTryedToAdd(false);
          setIsOpen(false);
        } else {
          console.log(response);

          toast.error(getValue("app_v2_toast_itemFailed"));
        }
      } else {
        setTryedToAdd(true);
        // console.log(assembledItem);
        let firstInvalidItem = assembledItem.modifierGroups.find(
          (group) => group.valid === false
        )?.uuid;
        setFirstInvalid(firstInvalidItem);
        if (firstInvalidItem != null) {
          const options = {
            duration: 1500,
            delay: 100,
            smooth: true,
            containerId: "scrollContainer",
            offset: -40,
          };
          scroller.scrollTo("11EE6DD359DE1E00ACA58B0B80351133", options);
        }

        toast.error(getValue("app_v2_toast_itemFailed"));
      }
    } else {
      setLoginModal(true);
      toast.error(getValue("app_v2_toast_needLogin"));
    }
  };

  useEffect(() => {
    Events.scrollEvent.register("begin", function () {});

    Events.scrollEvent.register("end", function () {});
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const changeCount = (count) => {
    setCount(count);
  };

  const handleChangeNotes = (e) => setItemNotes(e.target.value);

  const handleBlurNotes = (e) => setNotes(itemNotes?.slice(0, 100));

  const handleTakeAway = (params) => {
    setTakeAway(params.takeAway);
  };
  const displayDescription = (description) => {
    const descriptionLength = description?.length;
    if (descriptionLength > 250) {
      return (
        <div
          className={`px-4 text-sm opacity-60 w-full ${
            showMore ? "show-more-content show-more" : "show-more-content"
          } ${
            appParams?.styles?.fonts?.capitalize ? "font-bold !uppercase" : ""
          }`}
        >
          {showMore ? description : description.slice(0, 120) + "..."}
          <span
            className="ml-2 text-primary font-bold"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore
              ? getValue("app_v2_showLess")
              : getValue("app_v2_showMore")}
          </span>
        </div>
      );
    } else {
      return (
        <div className="px-4 text-sm opacity-60  text-clip w-full ">
          {description}
        </div>
      );
    }
  };
  if (assembledItem == null) return null;

  return (
    <>
      {loginModal && (
        <LoginModal isOpen={loginModal} setIsOpen={setLoginModal} />
      )}
      <Modal
        isOpen={isOpen}
        onOpenChange={() => {
          setIsOpen(!isOpen);
        }}
        size="full"
        className="bg-background"
        placement="bottom-center"
        scrollBehavior="inside"
        classNames={{
          base: "m-0 max-w-[820px] bottom-0 ",
          wrapper: "sm:items-end",
          closeButton:
            "z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]",
        }}
      >
        <ModalContent className="!rounded-tl-[var(--modal-radius)] !rounded-tr-[var(--modal-radius)] overflow-hidden">
          {(onClose) => (
            <>
              {/* <ModalHeader className='flex flex-row gap-1 items-center '>
              {getName(assembledItem.description) !== '' && (
                <div className='info' onClick={() => console.log('OpenDesc')}>
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                    <path fill='currentColor' d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z' />
                  </svg>
                </div>
              )}
              <div className='orderItem-modal-itemName font-extraLarge'>{getName(assembledItem.name)}</div>
            </ModalHeader> */}
              <ModalBody className="p-0">
                <ScrollShadow className="pb-16 realtive w-full grow overflow-x-hidden scrollArea !relative">
                  {assembledItem.availability.available === 0 && (
                    <div className="orderItem-modal-notAvailable font-small text-center mb-1">
                      {getValue("app_v2_orderItem_notAvailable")}
                    </div>
                  )}
                  <div className="orderItem-modal-body " id="scrollContainer">
                    {(appParams?.items?.showImage ||
                      appParams?.items?.showImage === undefined) &&
                      appParams?.assets?.defaultItemImg != null && (
                        <div className="orderItemListItem-img-container">
                          <OEAsset
                            uuid={assembledItem.attributes.appImage}
                            addClass={`w-full aspect-[4/3] max-h-[200px] flex flex-start overflow-hidden object-cover  ${
                              appParams?.features?.imagesContain
                                ? "img-contain"
                                : ""
                            }`}
                            size="l"
                          />
                        </div>
                      )}
                  </div>
                  <div className="z-50 sticky top-0 pt-4 bg-[var(--main-bg-color)]">
                    <div className="px-4 pt-2 pb-2 flex flex-row gap-1 bg-[var(--main-bg-color)] items-center !relative z-50">
                      <div
                        id="desc"
                        className="orderItem-modal-itemName font-extraLarge"
                      >
                        {getName(assembledItem.name)}
                      </div>
                    </div>
                  </div>
                  <div className="uppercase">
                    {displayDescription(getName(assembledItem.description))}
                  </div>
                  <div className="w-full flex flex-wrap gap-2 flex-row px-2">
                    {allergens.map((alle, index) => (
                      <div key={index} className="allergen-icon">
                        <LoadSvg key={index} name={alle} />
                      </div>
                    ))}
                  </div>
                  <div className="px-2">
                    {allergens.length > 0 && (
                      <span className=" text-small">
                        <span className="title">
                          - {getValue("app_v2_allergens")}:{" "}
                        </span>
                        {allergens.map((alle, index) => (
                          <span key={index} className="allergen">
                            {getValue("app_v2_allergens_" + alle)}
                            {allergens.length !== index + 1 && ", "}
                          </span>
                        ))}
                      </span>
                    )}
                    <div className="item-tags mt-2">
                      {Array.isArray(assembledItem.tags) &&
                        assembledItem.tags.includes("meat") && (
                          <LoadSvg name={"meat"} />
                        )}
                      {Array.isArray(assembledItem.tags) &&
                        assembledItem.tags.includes("lactoseFree") && (
                          <LoadSvg name={"milk"} />
                        )}
                      {Array.isArray(assembledItem.tags) &&
                        assembledItem.tags.includes("vegan") && (
                          <LoadSvg name={"vegan"} />
                        )}
                      {Array.isArray(assembledItem.tags) &&
                        assembledItem.tags.includes("glutenFree") && (
                          <LoadSvg name={"glutenFree"} />
                        )}
                    </div>
                  </div>
                  {sizeUUID == null && assembledItem.sizes?.length > 1 && (
                    <div className="orderItem-modal-itemSizes z-10">
                      {/* <OrderItemSizeSelector sizes={assembledItem.sizes} setSize={setSize} selectedSizeUUID={assembledItem.selectedSizeUUID} /> */}
                      <SizeSelector
                        sizes={assembledItem.sizes}
                        setSize={setSize}
                        selectedSizeUUID={assembledItem.selectedSizeUUID}
                      />
                    </div>
                  )}
                  <div className=" px-4 flex flex-col gap-2 w-full z-0 !relative ">
                    {assembledItem.modifierGroups
                      .sort((a, b) => (a?.min > b?.min ? 1 : -1))
                      .sort((a, b) => (a?.idx > b?.idx ? 1 : -1))
                      // .filter(group => (group.availability.available === 1 && group.availability.visible === 1))
                      .map((group, index) => {
                        return (
                          <Element key={group.uuid} name={group.uuid}>
                            <ModifierGroupSelector
                              key={group.uuid}
                              firstInvalid={firstInvalid}
                              group={group}
                              setModifierCount={setModifierCount}
                              readOnly={readOnly}
                              tryedToAdd={tryedToAdd}
                              name={group?.uuid}
                            />
                            {/* <OrderItemModifierGroupSelector key={group.uuid} firstInvalid={firstInvalid} group={group} setModifierCount={setModifierCount} readOnly={readOnly} tryedToAdd={tryedToAdd} name={group?.uuid} /> */}
                            {index !==
                              assembledItem.modifierGroups.length - 1 && (
                              <Divider className="my-4 bg-[var(--main-border-color)]" />
                            )}
                          </Element>
                        );
                      })}
                    {!readOnly &&
                      !(appParams?.items?.takeAwayHidden === true) && (
                        <div className="orderItemModifierGroup-root">
                          <div className="orderItemModifierGroup-modifier-root orderItem-modal-takeAway font-small">
                            <div className="title">
                              {getValue("app_v2_orderItem_takeAway")}
                            </div>
                            <div className="value">
                              <ChangeCountButton
                                count={assembledItem.takeAway ? 1 : 0}
                                params={{
                                  takeAway:
                                    assembledItem.takeAway === 1 ? 0 : 1,
                                }}
                                onClick={handleTakeAway}
                                type="radio"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                    {!readOnly && !(appParams?.items?.notesHidden === true) && (
                      <div className="orderItemModifierGroup-modifier-root orderItem-modal-notes font-small">
                        <Divider className="my-4 bg-[var(--main-border-color)]" />
                        <Input
                          aria-label="input"
                          type="text"
                          classNames={{
                            inputWrapper:
                              "group-data-[focus=true]:bg-[var(--input-bg-color)] border-1 rounded-[var(--general-border-radius)] bg-[var(--input-bg-color)] border-[var(--input-border-color)] text-[var(--input-text-color)]",
                            label: appParams?.styles?.fonts?.capitalize
                              ? "font-bold uppercase"
                              : "",
                          }}
                          name="itemNotes"
                          label={getValue("app_v2_orderItem_notes")}
                          value={itemNotes}
                          fullWidth={true}
                          onChange={handleChangeNotes}
                          onBlur={handleBlurNotes}
                        />
                      </div>
                    )}
                    {!readOnly &&
                      upsaleItems != null &&
                      upsaleItems.length > 0 && (
                        <>
                          <div className="text-center mb-05 mt-2 font-bold">
                            {getValue("app_v2_upsellTitle")}
                          </div>
                          <div className="upsell-items-container">
                            {upsaleItems.map((item, index) => (
                              <UpsaleItem
                                key={index}
                                itemUUID={item.itemUUID}
                              />
                            ))}
                          </div>
                        </>
                      )}
                  </div>
                </ScrollShadow>
                <div className="w-full flex flex-row  justify-between px-4 pb-4  gap-4 z-10">
                  {!readOnly && (
                    <ChangeCountButton
                      count={assembledItem.count}
                      changeCount={changeCount}
                      type="stackableMultiple"
                    />
                  )}

                  <Button
                    onPress={addItemToBasket}
                    color="primary"
                    size="lg"
                    className={`grow flex rounded-[var(--order-button-border-radius)] font-bold flex-row justify-between ${
                      assembledItem?.count > 0 &&
                      assembledItem?.valid &&
                      tokenData?.valid
                        ? ""
                        : "opacity-50"
                    }  ${
                      appParams?.styles?.fonts?.capitalize
                        ? "font-bold !uppercase"
                        : ""
                    }`}
                  >
                    <span className="font-semibold">
                      {getValue("app_v2_addToBasket")}
                    </span>
                    <span className="total-price">{`${
                      assembledItem?.totalPrice || 0
                    } Ft`}</span>
                  </Button>
                  {/* <div className={`btn ${assembledItem.count > 0 && assembledItem.valid && tokenData?.valid != null ? 'primary' : 'disabled'}`} onClick={addItemToBasket}>
                          <span className='text'>{getValue('app_v2_addToBasket')}</span>
                          <span className='total-price'>{`${assembledItem?.totalPrice || 0} Ft`}</span>
                        </div> */}
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default UpSaleItemModal;
