import {Modal, ModalBody, ModalContent, ModalHeader} from '@nextui-org/react';
import Languages from 'components/MoreMenuElements/Languages';

export default function SelectLanguageModal({isOpen, onOpenChange, saveToLocalStorage}) {
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => onOpenChange()}
      size={saveToLocalStorage ? 'full' : '2xl'}
      isDismissable={saveToLocalStorage ? false : true}
      radius='none'
      hideCloseButton={saveToLocalStorage ? true : false}
      className='bg-background'
      classNames={{
        base: saveToLocalStorage ? 'm-0 flex flex-col max-w-[820px] ' : 'm-0 flex flex-col max-w-[820px] max-h-[90vh]',
        wrapper: 'sm:items-end',
        closeButton: 'z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]',
      }}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className='flex flex-col gap-1'></ModalHeader>
            <ModalBody className={'overflow-hidden'}>
              <Languages className='moreMenu-accordion hidden-content appear-quick' close={() => onOpenChange()} saveToLocalStorage={saveToLocalStorage} />
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
