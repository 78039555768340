import {Button, Modal, ModalBody, ModalContent, ModalHeader} from '@nextui-org/react';
import SelectTableModal from 'components/SelectTableModal';
import useBasket from 'hooks/useBasket';
import {useEffect, useState} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import {useDictionary} from '@adamlip/okeoke.client';

export default function SelectedTableModal({isOpen, setIsOpen, onSelect}) {
  const {getValue} = useDictionary();
  const {setIsUserConfirmedTable, savedTable} = useBasket();
  const [disabled, setDisabled] = useState(true);
  const [selectTableOpen, setSelectTableOpen] = useState(false);

  const handleClickClose = () => {
    if (disabled) return null;
    setIsUserConfirmedTable(true);
    setIsOpen(false);
  };

  const onSelectTableModalClose = (res) => {
    console.log('SelectedTableModal', res);
    if (typeof onSelect === 'function') onSelect(res);
    setIsOpen(false);
  };

  const openSelectTableModal = () => {
    setSelectTableOpen(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setDisabled(false);
    }, 2000);
  }, []);

  if (typeof getValue !== 'function') return null;

  return (
    <>
      <SelectTableModal isOpen={selectTableOpen} setIsOpen={setSelectTableOpen} onClose={(res) => onSelectTableModalClose(res)} />
      <Modal
        isOpen={isOpen}
        onOpenChange={() => setIsOpen(!isOpen)}
        size='full'
        classNames={{
          base: 'm-0 max-w-[820px]',
          closeButton: 'z-50 bg-[var(--button-bg-color)] text-[var(--button-text-color)]',
        }}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className='flex flex-col gap-1'>{`${getValue('app_v2_order_tableSelected')} ${savedTable?.name}`}</ModalHeader>
              <ModalBody>
                <div className='w-full h-full flex flex-col items-center justify-center'>
                  <h1 className='font-bold text-lg text-center'>{`${getValue('app_v2_order_tableSelected')} ${savedTable?.name}`}</h1>
                  <p className='text-center mb-4'>{getValue('app_v2_order_tableSelected_warning')}</p>
                  <Button isDisabled={disabled} color='primary' onClick={handleClickClose} fullWidth size='lg'>
                    {disabled && <ClipLoader color={'#fff'} size={20} />}
                    {!disabled && getValue('app_v2_order_tableSelected_oke')}
                  </Button>
                  <div className='mt-3 w-full'>
                    <Button color='secondary' onClick={openSelectTableModal} fullWidth size='lg'>
                      {getValue('app_v2_order_tableSelected_selectOtherTable')}
                    </Button>
                  </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
