import {useClient} from '@adamlip/okeoke.client';
import {Slider} from '@nextui-org/react';
import {useEffect, useRef, useState} from 'react';
import './modifier.scss';

export default function ModifierV4Type1(props) {
  const {setCount, group} = props;
  const {getName} = useClient();
  const [x_Square, setX_Square] = useState(-15);
  const [rowBaseX, setRowBaseX] = useState(0);
  const [rowMaxX, setRowMaxX] = useState(0);
  const [toSnap, setToSnap] = useState({});
  const previouslySelected = useRef(null);
  const selected = useRef(null);
  const ref_Square = useRef(null);
  const ref_Bar = useRef(null);

  const handleOnDragOfSquare = (x) => {
    let temp = 0;
    let newX = x - rowBaseX - 25;
    //yeah... padding messes up the values, we needed the -5 and -30
    if (newX > -15 && newX < rowMaxX - 25) {
      temp = newX;
      selected.current = returnClosest(newX).uuid;
    } else {
      return null;
    }
    setX_Square(temp);
  };

  const returnClosest = (value) => {
    let temp;
    let shortestDistance = 9999;
    let thisDistance = 0;
    for (let i = 0; i < Object.entries(toSnap).length; i++) {
      thisDistance = 0;
      if (value > toSnap[i].x) {
        thisDistance = value - toSnap[i].x;
      } else {
        thisDistance = toSnap[i].x - value;
      }
      if (shortestDistance > thisDistance) {
        shortestDistance = thisDistance;
        temp = toSnap[i];
      }
    }
    return temp;
  };

  const snapToSelected = () => {
    updateModifierSelected();
    for (let i of Object.values(toSnap)) {
      if (selected.current === i.uuid) {
        setX_Square(i.x);
      }
    }
  };

  const updateModifierSelected = () => {
    if (previouslySelected.current === selected.current) return null;
    setCount(previouslySelected.current, 0);
    setCount(selected.current, 1);
    previouslySelected.current = selected.current;
  };

  const snapToIndex = (index) => {
    setX_Square(toSnap[index].x);
  };

  const selectOptionHandler = (e) => {
    selected.current = toSnap[e].uuid;
    updateModifierSelected();
    snapToIndex(e);
  };

  const setDefaultSelected = () => {
    let currentlySelected = null;
    let defaultValue = group?.modifiers.filter((i) => {
      if (i.count === 1) {
        currentlySelected = i.uuid;
      }
      if (i.defaultCount === 1) {
        return true;
      } else {
        return false;
      }
    })[0]?.uuid;
    selected.current = currentlySelected ? currentlySelected : defaultValue;
    previouslySelected.current = currentlySelected ? currentlySelected : defaultValue;
  };

  const prepToSnapValues = () => {
    let temp = {};
    for (let i = 0; i < group.modifiers.length; i++) {
      temp = {
        ...temp,
        [i]: {
          uuid: group.modifiers[i].uuid,
          x: i === 0 ? -15 : ((ref_Bar?.current?.getBoundingClientRect().width - 30) / (group.modifiers.length - 1)) * i - 12,
        },
      };
    }
    setToSnap(temp);
  };

  useEffect(() => {
    if (ref_Bar == null && !ref_Bar?.current?.offsetLeft) {
    } else {
      setRowBaseX(ref_Bar?.current?.getBoundingClientRect().x);
      setRowMaxX(ref_Bar?.current?.getBoundingClientRect().width);
    }
    // eslint-disable-next-line
  }, [ref_Bar]);

  useEffect(() => {
    if (ref_Bar && group) {
      prepToSnapValues();
      setDefaultSelected();
    }
    // eslint-disable-next-line
  }, [ref_Bar.current]);

  useEffect(() => {
    snapToSelected();
    // eslint-disable-next-line
  }, [toSnap]);

  return (
    <div className='slider-modifier-root'>
      <div className='slider-modifier-items-title'>{getName(group.name)}</div>
      <Slider
        showSteps
        step={1}
        minValue={0}
        maxValue={group.modifiers?.length - 1}
        value={Object.values(toSnap).findIndex((i) => i.uuid === selected.current)}
        marks={Object.values(toSnap).map((i, index) => {
          return {
            value: index,
            label: getName(group.modifiers[index].name),
          };
        })}
        aria-label='Slider'
        onChange={(e) => {
          if (e !== Object.values(toSnap).findIndex((i) => i.uuid === selected.current)) {
            selectOptionHandler(e);
          }
        }}
        classNames={{
          thumb: ' rounded-[var(--general-border-radius)] after:bg-[var(--button2-bg-color)] after:rounded-[var(--general-border-radius)]',
          track: ' rounded-[var(--general-border-radius)]',
        }}
      />
      {/* <div className='slider-modifier-items-container'>
        <div ref={ref_Bar} className='container-row'>
          <div className='container-row-progress-bar' style={{width: x_Square + 30, left: 0}}></div>
          {group.modifiers.map((modifier, index) => {
            return (
              <div key={index} className={`in-container-dot ${modifier.uuid === selected.current ? 'selected' : ''}`}>
                <div className='le-dot'></div>
                <div
                  className={`in-container-dot-clickable`}
                  onClick={() => {
                    selectOptionHandler(index);
                  }}></div>
                <div className={`container-dot-floating-name`} style={{width: 553 / group?.availableModifiers + 'px'}}>
                  {getName(modifier.name)}
                </div>
              </div>
            );
          })}
          <div
            draggable
            className={`container-row-dragable-square-container pulse-once`}
            onTouchMoveCapture={(e) => {
              handleOnDragOfSquare(e.touches[0].pageX);
            }}
            onDrag={(e) => {
              handleOnDragOfSquare(e.pageX);
            }}
            onTouchEnd={snapToSelected}
            onDragEnd={snapToSelected}
            style={{left: x_Square}}>
            <div ref={ref_Square} className='container-row-dragable-square' />
          </div>
        </div>
      </div> */}
    </div>
  );
}
