import {ReactComponent as OrderStatus1b} from 'assets/svg3/status/orderStatus1b.svg';
import {ReactComponent as OrderStatus2b} from 'assets/svg3/status/orderStatus2b.svg';
import {ReactComponent as OrderStatus3bb} from 'assets/svg3/status/orderStatus3b.svg';
import {ReactComponent as OrderStatus5b} from 'assets/svg3/status/orderStatus5b.svg';
import {ReactComponent as OrderStatusDelete} from 'assets/svg3/status/orderStatusDelete.svg';

import {ReactComponent as Delivery} from 'assets/svg/car-03.svg';
import {ReactComponent as MinusIcon} from 'assets/svg/minusIcon.svg';
import {ReactComponent as PlusIcon} from 'assets/svg/plusIcon.svg';

import {ReactComponent as Celery} from 'assets/svg3/common/celery.svg';
import {ReactComponent as Crustaceans} from 'assets/svg3/common/crustaceans.svg';
import {ReactComponent as Eggs} from 'assets/svg3/common/eggs.svg';
import {ReactComponent as Fish} from 'assets/svg3/common/fish.svg';
import {ReactComponent as Gluten} from 'assets/svg3/common/gluten.svg';
import {ReactComponent as Lupin} from 'assets/svg3/common/lupin.svg';
import {ReactComponent as Meat} from 'assets/svg3/common/meat.svg';
import {ReactComponent as Milk} from 'assets/svg3/common/milk.svg';
import {ReactComponent as Molluscs} from 'assets/svg3/common/molluscs.svg';
import {ReactComponent as Mustard} from 'assets/svg3/common/mustard.svg';
import {ReactComponent as Peanuts} from 'assets/svg3/common/peanut.svg';
import {ReactComponent as GlutenFree} from 'assets/svg3/common/sensegluten.svg';
import {ReactComponent as Sesame} from 'assets/svg3/common/sesame.svg';
import {ReactComponent as Soya} from 'assets/svg3/common/soya.svg';
import {ReactComponent as Sulphites} from 'assets/svg3/common/sulphites.svg';
import {ReactComponent as TreeNuts} from 'assets/svg3/common/treeNuts.svg';
import {ReactComponent as Vegan} from 'assets/svg3/common/vegan.svg';

import {ReactComponent as EatIn} from 'assets/svg/eatInIcon.svg';
import {ReactComponent as TakeAway} from 'assets/svg/takeAwayIcon.svg';
import {ReactComponent as QR} from 'assets/svg3/iPanel/QR.svg';
import {ReactComponent as CircleCheck} from 'assets/svg3/iPanel/akar-icons_circle-check2.svg';
import {ReactComponent as CirclePlus} from 'assets/svg3/iPanel/akar-icons_circle-plus.svg';
import {ReactComponent as CircleX} from 'assets/svg3/iPanel/akar-icons_circle-x2.svg';
import {ReactComponent as Cloud} from 'assets/svg3/iPanel/akar-icons_cloud.svg';
import {ReactComponent as Info} from 'assets/svg3/iPanel/akar-icons_info-fill.svg';
import {ReactComponent as Tablet} from 'assets/svg3/iPanel/akar-icons_tablet-device.svg';
import {ReactComponent as Trash} from 'assets/svg3/iPanel/akar-icons_trash-can.svg';
import {ReactComponent as CheckList} from 'assets/svg3/iPanel/bi_list-check.svg';
import {ReactComponent as Brand} from 'assets/svg3/iPanel/brand.svg';
import {ReactComponent as Build} from 'assets/svg3/iPanel/build.svg';
import {ReactComponent as Home} from 'assets/svg3/iPanel/bx_home.svg';
import {ReactComponent as Modifiers} from 'assets/svg3/iPanel/carbon_category-new-each.svg';
import {ReactComponent as Catalog} from 'assets/svg3/iPanel/catalog.svg';
import {ReactComponent as Filter} from 'assets/svg3/iPanel/charm_filter.svg';
import {ReactComponent as Plus} from 'assets/svg3/iPanel/charm_plus.svg';
import {ReactComponent as Check} from 'assets/svg3/iPanel/check.svg';
import {ReactComponent as Chevron} from 'assets/svg3/iPanel/chevronSquare.svg';
import {ReactComponent as Upsell} from 'assets/svg3/iPanel/cil_chevron-double-up.svg';
import {ReactComponent as Pencil} from 'assets/svg3/iPanel/cil_pencil.svg';
import {ReactComponent as Image} from 'assets/svg3/iPanel/clarity_image-line.svg';
import {ReactComponent as Category} from 'assets/svg3/iPanel/clarity_list-line2.svg';
import {ReactComponent as Settings} from 'assets/svg3/iPanel/clarity_settings-line.svg';
import {ReactComponent as Cross} from 'assets/svg3/iPanel/cross.svg';
import {ReactComponent as Description} from 'assets/svg3/iPanel/descIcon.svg';
import {ReactComponent as Devices} from 'assets/svg3/iPanel/devices.svg';
import {ReactComponent as Dictionary} from 'assets/svg3/iPanel/dictionary.svg';
import {ReactComponent as English} from 'assets/svg3/iPanel/english.svg';
import {ReactComponent as Link} from 'assets/svg3/iPanel/entypo_link.svg';
import {ReactComponent as Back} from 'assets/svg3/iPanel/eva_arrow-ios-back-fill.svg';
import {ReactComponent as Exit} from 'assets/svg3/iPanel/exit.svg';
import {ReactComponent as Order} from 'assets/svg3/iPanel/fe_list-order.svg';
import {ReactComponent as Fields} from 'assets/svg3/iPanel/fields.svg';
import {ReactComponent as SelectNone} from 'assets/svg3/iPanel/fluent_select-all-off-24-regular.svg';
import {ReactComponent as SelectAll} from 'assets/svg3/iPanel/fluent_select-all-on-24-regular.svg';
import {ReactComponent as Global} from 'assets/svg3/iPanel/global.svg';
import {ReactComponent as GridDots} from 'assets/svg3/iPanel/grid_dots.svg';
import {ReactComponent as Group} from 'assets/svg3/iPanel/group.svg';
import {ReactComponent as Hungary} from 'assets/svg3/iPanel/hu.svg';
import {ReactComponent as Kiosk} from 'assets/svg3/iPanel/kiosk.svg';
import {ReactComponent as Locations} from 'assets/svg3/iPanel/locations.svg';
import {ReactComponent as Reply} from 'assets/svg3/iPanel/mdi_reply-all.svg';
import {ReactComponent as DarkLight} from 'assets/svg3/iPanel/mdi_theme-light-dark.svg';
import {ReactComponent as MoreDots} from 'assets/svg3/iPanel/moreDots.svg';
import {ReactComponent as News} from 'assets/svg3/iPanel/news.svg';
import {ReactComponent as OkeOkeIcon} from 'assets/svg3/iPanel/okeOkeIcon.svg';
import {ReactComponent as OpenHours} from 'assets/svg3/iPanel/openHours.svg';
import {ReactComponent as Orders} from 'assets/svg3/iPanel/orders.svg';
import {ReactComponent as PayAtCounter} from 'assets/svg3/iPanel/payAtCounter.svg';
import {ReactComponent as Payments} from 'assets/svg3/iPanel/payments.svg';
import {ReactComponent as Plugged} from 'assets/svg3/iPanel/plugged.svg';
import {ReactComponent as Pos} from 'assets/svg3/iPanel/pos.svg';
import {ReactComponent as RoundedArrow} from 'assets/svg3/iPanel/rounded_arrow.svg';
import {ReactComponent as Search} from 'assets/svg3/iPanel/search.svg';
import {ReactComponent as SortIconDown} from 'assets/svg3/iPanel/sortIconDown.svg';
import {ReactComponent as SortIconNone} from 'assets/svg3/iPanel/sortIconNone.svg';
import {ReactComponent as SortIconUp} from 'assets/svg3/iPanel/sortIconUp.svg';
import {ReactComponent as Store} from 'assets/svg3/iPanel/store.svg';
import {ReactComponent as SubMenu} from 'assets/svg3/iPanel/submenu.svg';
import {ReactComponent as Templates} from 'assets/svg3/iPanel/templates.svg';
import {ReactComponent as UnPlugged} from 'assets/svg3/iPanel/unplugged.svg';
import {ReactComponent as Users} from 'assets/svg3/iPanel/users.svg';

import {ReactComponent as ActionReq} from 'assets/svg3/brandApp/actionReq.svg';
import {ReactComponent as Milk2} from 'assets/svg3/brandApp/allergen1.svg';
import {ReactComponent as Bug} from 'assets/svg3/brandApp/bug.svg';
import {ReactComponent as Calendar} from 'assets/svg3/brandApp/calendar.svg';
import {ReactComponent as CheckCircle} from 'assets/svg3/brandApp/check-circle.svg';
import {ReactComponent as Clock} from 'assets/svg3/brandApp/clock.svg';
import {ReactComponent as ColoredCheck} from 'assets/svg3/brandApp/coloredCheck.svg';
import {ReactComponent as ColoredFail} from 'assets/svg3/brandApp/coloredFail.svg';
import {ReactComponent as Done} from 'assets/svg3/brandApp/done.svg';
import {ReactComponent as NewHomeIcon} from 'assets/svg3/brandApp/newHomeIcon.svg';
import {ReactComponent as Prep} from 'assets/svg3/brandApp/prep.svg';
import {ReactComponent as QrCode} from 'assets/svg3/brandApp/qrcode.svg';
import {ReactComponent as Search2} from 'assets/svg3/brandApp/search.svg';
import {ReactComponent as Filter2} from 'assets/svg3/brandApp/system-uicons_filtering.svg';

import {ReactComponent as Set1CircleCheck} from 'assets/svg3/set1/akar-icons_circle-check.svg';
import {ReactComponent as Set1CircleX} from 'assets/svg3/set1/akar-icons_circle-x.svg';
import {ReactComponent as Set1Location} from 'assets/svg3/set1/akar-icons_location.svg';
import {ReactComponent as Set1Minus} from 'assets/svg3/set1/akar-icons_minus.svg';
import {ReactComponent as Set1More} from 'assets/svg3/set1/akar-icons_more-horizontal.svg';
import {ReactComponent as Set1Tag} from 'assets/svg3/set1/akar-icons_tag.svg';
import {ReactComponent as Set1Trash} from 'assets/svg3/set1/akar-icons_trash-can.svg';
import {ReactComponent as Set1Global} from 'assets/svg3/set1/ant-design_global-outlined.svg';
import {ReactComponent as Set1Cart} from 'assets/svg3/set1/ant-design_shopping-cart-outlined.svg';
import {ReactComponent as Set1User} from 'assets/svg3/set1/ant-design_user-outlined.svg';
import {ReactComponent as Set1CreditCard} from 'assets/svg3/set1/bi_credit-card-2-front.svg';
import {ReactComponent as Set1Home} from 'assets/svg3/set1/bx_home.svg';
import {ReactComponent as Set1Plus} from 'assets/svg3/set1/charm_plus.svg';
import {ReactComponent as Set1Check} from 'assets/svg3/set1/check.svg';
import {ReactComponent as Set1List} from 'assets/svg3/set1/clarity_list-line.svg';
import {ReactComponent as Set1Close} from 'assets/svg3/set1/close.svg';
import {ReactComponent as Set1Back} from 'assets/svg3/set1/eva_arrow-ios-back-fill.svg';
import {ReactComponent as Set1Info} from 'assets/svg3/set1/eva_info-outline.svg';
import {ReactComponent as Set1History} from 'assets/svg3/set1/fluent_history-32-filled.svg';
import {ReactComponent as Set1Scan} from 'assets/svg3/set1/fluent_scan-dash-32-regular.svg';
import {ReactComponent as Set1Payments} from 'assets/svg3/set1/ic_baseline-payments.svg';
import {ReactComponent as Set1News} from 'assets/svg3/set1/news.svg';
import {ReactComponent as Set1Invoice} from 'assets/svg3/set1/nimbus_invoice.svg';
import {ReactComponent as Set1Pencil} from 'assets/svg3/set1/pencil.svg';
import {ReactComponent as Set1Coupon} from 'assets/svg3/set1/ri_coupon-3-line.svg';
import {ReactComponent as Set1Discount} from 'assets/svg3/set1/tabler_discount-2.svg';

function Container(props) {
  const {className} = props;

  return <div className={`svg-root ${className != null ? className : ''}`}>{props.children}</div>;
}

function LoadSvg(props) {
  const {name, onClick, set, className} = props;

  const handleClick = (e) => {
    if (typeof onClick === 'function') onClick(e);
  };

  if (name == null) return null;

  const icons = {
    default: {
      orderStatus1b: (
        <Container className={className}>
          <OrderStatus1b onClick={handleClick} />
        </Container>
      ),
      orderStatus2b: (
        <Container className={className}>
          <OrderStatus2b onClick={handleClick} />
        </Container>
      ),
      orderStatus3bb: (
        <Container className={className}>
          <OrderStatus3bb onClick={handleClick} />
        </Container>
      ),
      orderStatus5b: (
        <Container className={className}>
          <OrderStatus5b onClick={handleClick} />
        </Container>
      ),
      orderStatusDelete: (
        <Container className={className}>
          <OrderStatusDelete onClick={handleClick} />
        </Container>
      ),
      circleCheck: (
        <Container className={className}>
          <CircleCheck onClick={handleClick} />
        </Container>
      ),
      circlePlus: (
        <Container className={className}>
          <CirclePlus onClick={handleClick} />
        </Container>
      ),
      circleX: (
        <Container className={className}>
          <CircleX onClick={handleClick} />
        </Container>
      ),
      category: (
        <Container className={className}>
          <Category onClick={handleClick} />
        </Container>
      ),
      filter: (
        <Container className={className}>
          <Filter onClick={handleClick} />
        </Container>
      ),
      filter2: (
        <Container className={className}>
          <Filter2 onClick={handleClick} />
        </Container>
      ),
      upsell: (
        <Container className={className}>
          <Upsell onClick={handleClick} />
        </Container>
      ),
      pencil: (
        <Container className={className}>
          <Pencil onClick={handleClick} />
        </Container>
      ),
      modifiers: (
        <Container className={className}>
          <Modifiers onClick={handleClick} />
        </Container>
      ),
      image: (
        <Container className={className}>
          <Image onClick={handleClick} />
        </Container>
      ),
      english: (
        <Container className={className}>
          <English onClick={handleClick} />
        </Container>
      ),
      hungary: (
        <Container className={className}>
          <Hungary onClick={handleClick} />
        </Container>
      ),
      description: (
        <Container className={className}>
          <Description onClick={handleClick} />
        </Container>
      ),
      moreDots: (
        <Container className={className}>
          <MoreDots onClick={handleClick} />
        </Container>
      ),
      group: (
        <Container className={className}>
          <Group onClick={handleClick} />
        </Container>
      ),
      chevron: (
        <Container className={className}>
          <Chevron onClick={handleClick} />
        </Container>
      ),
      roundedArrow: (
        <Container className={className}>
          <RoundedArrow onClick={handleClick} />
        </Container>
      ),
      cross: (
        <Container className={className}>
          <Cross onClick={handleClick} />
        </Container>
      ),
      okeOkeIcon: (
        <Container className={className}>
          <OkeOkeIcon onClick={handleClick} />
        </Container>
      ),
      sortIconNone: (
        <Container className={className}>
          <SortIconNone onClick={handleClick} />
        </Container>
      ),
      sortIconUp: (
        <Container className={className}>
          <SortIconUp onClick={handleClick} />
        </Container>
      ),
      newHomeIcon: (
        <Container className={className}>
          <NewHomeIcon onClick={handleClick} />
        </Container>
      ),
      sortIconDown: (
        <Container className={className}>
          <SortIconDown onClick={handleClick} />
        </Container>
      ),
      gridDots: (
        <Container className={className}>
          <GridDots onClick={handleClick} />
        </Container>
      ),
      check: (
        <Container className={className}>
          <Check onClick={handleClick} />
        </Container>
      ),
      reply: (
        <Container className={className}>
          <Reply onClick={handleClick} />
        </Container>
      ),
      brand: (
        <Container className={className}>
          <Brand onClick={handleClick} />
        </Container>
      ),
      global: (
        <Container className={className}>
          <Global onClick={handleClick} />
        </Container>
      ),
      exit: (
        <Container className={className}>
          <Exit onClick={handleClick} />
        </Container>
      ),
      info: (
        <Container className={className}>
          <Info onClick={handleClick} />
        </Container>
      ),
      plugged: (
        <Container className={className}>
          <Plugged onClick={handleClick} />
        </Container>
      ),
      unPlugged: (
        <Container className={className}>
          <UnPlugged onClick={handleClick} />
        </Container>
      ),
      link: (
        <Container className={className}>
          <Link onClick={handleClick} />
        </Container>
      ),
      payAtCounter: (
        <Container className={className}>
          <PayAtCounter onClick={handleClick} />
        </Container>
      ),
      store: (
        <Container className={className}>
          <Store onClick={handleClick} />
        </Container>
      ),
      build: (
        <Container className={className}>
          <Build onClick={handleClick} />
        </Container>
      ),
      home: (
        <Container className={className}>
          <Home onClick={handleClick} />
        </Container>
      ),
      search: (
        <Container className={className}>
          <Search onClick={handleClick} />
        </Container>
      ),
      search2: (
        <Container className={className}>
          <Search2 onClick={handleClick} />
        </Container>
      ),
      trash: (
        <Container className={className}>
          <Trash onClick={handleClick} />
        </Container>
      ),
      back: (
        <Container className={className}>
          <Back onClick={handleClick} />
        </Container>
      ),
      plus: (
        <Container className={className}>
          <Plus onClick={handleClick} />
        </Container>
      ),
      order: (
        <Container className={className}>
          <Order onClick={handleClick} />
        </Container>
      ),
      fields: (
        <Container className={className}>
          <Fields onClick={handleClick} />
        </Container>
      ),
      openHours: (
        <Container className={className}>
          <OpenHours onClick={handleClick} />
        </Container>
      ),
      settings: (
        <Container className={className}>
          <Settings onClick={handleClick} />
        </Container>
      ),
      catalog: (
        <Container className={className}>
          <Catalog onClick={handleClick} />
        </Container>
      ),
      devices: (
        <Container className={className}>
          <Devices onClick={handleClick} />
        </Container>
      ),
      locations: (
        <Container className={className}>
          <Locations onClick={handleClick} />
        </Container>
      ),
      news: (
        <Container className={className}>
          <News onClick={handleClick} />
        </Container>
      ),
      payments: (
        <Container className={className}>
          <Payments onClick={handleClick} />
        </Container>
      ),
      qr: (
        <Container className={className}>
          <QR onClick={handleClick} />
        </Container>
      ),
      templates: (
        <Container className={className}>
          <Templates onClick={handleClick} />
        </Container>
      ),
      orders: (
        <Container className={className}>
          <Orders onClick={handleClick} />
        </Container>
      ),
      subMenu: (
        <Container className={className}>
          <SubMenu onClick={handleClick} />
        </Container>
      ),
      dictionary: (
        <Container className={className}>
          <Dictionary onClick={handleClick} />
        </Container>
      ),
      bug: (
        <Container className={className}>
          <Bug onClick={handleClick} />
        </Container>
      ),
      qrcode: (
        <Container className={className}>
          <QrCode onClick={handleClick} />
        </Container>
      ),
      users: (
        <Container className={className}>
          <Users onClick={handleClick} />
        </Container>
      ),
      darkLight: (
        <Container className={className}>
          <DarkLight onClick={handleClick} />
        </Container>
      ),
      cloud: (
        <Container className={className}>
          <Cloud onClick={handleClick} />
        </Container>
      ),
      tablet: (
        <Container className={className}>
          <Tablet onClick={handleClick} />
        </Container>
      ),
      kiosk: (
        <Container className={className}>
          <Kiosk onClick={handleClick} />
        </Container>
      ),
      pos: (
        <Container className={className}>
          <Pos onClick={handleClick} />
        </Container>
      ),
      checkList: (
        <Container className={className}>
          <CheckList onClick={handleClick} />
        </Container>
      ),
      selectAll: (
        <Container className={className}>
          <SelectAll onClick={handleClick} />
        </Container>
      ),
      selectNone: (
        <Container className={className}>
          <SelectNone onClick={handleClick} />
        </Container>
      ),
      meat: (
        <Container className={className}>
          <Meat onClick={handleClick} />
        </Container>
      ),
      milk: (
        <Container className={className}>
          <Milk onClick={handleClick} />
        </Container>
      ),
      milk2: (
        <Container className={className}>
          <Milk2 onClick={handleClick} />
        </Container>
      ),
      eggs: (
        <Container className={className}>
          <Eggs onClick={handleClick} />
        </Container>
      ),
      fish: (
        <Container className={className}>
          <Fish onClick={handleClick} />
        </Container>
      ),
      soya: (
        <Container className={className}>
          <Soya onClick={handleClick} />
        </Container>
      ),
      lupin: (
        <Container className={className}>
          <Lupin onClick={handleClick} />
        </Container>
      ),
      celery: (
        <Container className={className}>
          <Celery onClick={handleClick} />
        </Container>
      ),
      gluten: (
        <Container className={className}>
          <Gluten onClick={handleClick} />
        </Container>
      ),
      sesame: (
        <Container className={className}>
          <Sesame onClick={handleClick} />
        </Container>
      ),
      mustard: (
        <Container className={className}>
          <Mustard onClick={handleClick} />
        </Container>
      ),
      peanuts: (
        <Container className={className}>
          <Peanuts onClick={handleClick} />
        </Container>
      ),
      molluscs: (
        <Container className={className}>
          <Molluscs onClick={handleClick} />
        </Container>
      ),
      treeNuts: (
        <Container className={className}>
          <TreeNuts onClick={handleClick} />
        </Container>
      ),
      sulphites: (
        <Container className={className}>
          <Sulphites onClick={handleClick} />
        </Container>
      ),
      crustaceans: (
        <Container className={className}>
          <Crustaceans onClick={handleClick} />
        </Container>
      ),
      glutenFree: (
        <Container className={className}>
          <GlutenFree onClick={handleClick} />
        </Container>
      ),
      vegan: (
        <Container className={className}>
          <Vegan onClick={handleClick} />
        </Container>
      ),
      setPlus: (
        <Container className={className}>
          <Set1Plus onClick={handleClick} />
        </Container>
      ),
      setMinus: (
        <Container className={className}>
          <Set1Minus onClick={handleClick} />
        </Container>
      ),
      setCircleCheck: (
        <Container className={className}>
          <Set1CircleCheck onClick={handleClick} />
        </Container>
      ),
      setCircleX: (
        <Container className={className}>
          <Set1CircleX onClick={handleClick} />
        </Container>
      ),
      setLocation: (
        <Container className={className}>
          <Set1Location onClick={handleClick} />
        </Container>
      ),
      setMore: (
        <Container className={className}>
          <Set1More onClick={handleClick} />
        </Container>
      ),
      setTag: (
        <Container className={className}>
          <Set1Tag onClick={handleClick} />
        </Container>
      ),
      setTrash: (
        <Container className={className}>
          <Set1Trash onClick={handleClick} />
        </Container>
      ),
      setGlobal: (
        <Container className={className}>
          <Set1Global onClick={handleClick} />
        </Container>
      ),
      setCart: (
        <Container className={className}>
          <Set1Cart onClick={handleClick} />
        </Container>
      ),
      setUser: (
        <Container className={className}>
          <Set1User onClick={handleClick} />
        </Container>
      ),
      setCreditCard: (
        <Container className={className}>
          <Set1CreditCard onClick={handleClick} />
        </Container>
      ),
      setHome: (
        <Container className={className}>
          <Set1Home onClick={handleClick} />
        </Container>
      ),
      setList: (
        <Container className={className}>
          <Set1List onClick={handleClick} />
        </Container>
      ),
      setBack: (
        <Container className={className}>
          <Set1Back onClick={handleClick} />
        </Container>
      ),
      setPencil: (
        <Container className={className}>
          <Set1Pencil onClick={handleClick} />
        </Container>
      ),
      setInfo: (
        <Container className={className}>
          <Set1Info onClick={handleClick} />
        </Container>
      ),
      setHistory: (
        <Container className={className}>
          <Set1History onClick={handleClick} />
        </Container>
      ),
      setScan: (
        <Container className={className}>
          <Set1Scan onClick={handleClick} />
        </Container>
      ),
      setCoupon: (
        <Container className={className}>
          <Set1Coupon onClick={handleClick} />
        </Container>
      ),
      setClose: (
        <Container className={className}>
          <Set1Close onClick={handleClick} />
        </Container>
      ),
      setInvoice: (
        <Container className={className}>
          <Set1Invoice onClick={handleClick} />
        </Container>
      ),
      setPayments: (
        <Container className={className}>
          <Set1Payments onClick={handleClick} />
        </Container>
      ),
      setCheck: (
        <Container className={className}>
          <Set1Check onClick={handleClick} />
        </Container>
      ),
      setNews: (
        <Container className={className}>
          <Set1News onClick={handleClick} />
        </Container>
      ),
      setDiscount: (
        <Container className={className}>
          <Set1Discount onClick={handleClick} />
        </Container>
      ),
      clock: (
        <Container className={className}>
          <Clock onClick={handleClick} />
        </Container>
      ),
      prep: (
        <Container className={className}>
          <Prep onClick={handleClick} />
        </Container>
      ),
      done: (
        <Container className={className}>
          <Done onClick={handleClick} />
        </Container>
      ),
      actionReq: (
        <Container className={className}>
          <ActionReq onClick={handleClick} />
        </Container>
      ),
      coloredCheck: (
        <Container className={className}>
          <ColoredCheck onClick={handleClick} />
        </Container>
      ),
      coloredFail: (
        <Container className={className}>
          <ColoredFail onClick={handleClick} />
        </Container>
      ),
      checkCircle: (
        <Container className={className}>
          <CheckCircle onClick={handleClick} />
        </Container>
      ),
      calendar: (
        <Container className={className}>
          <Calendar onClick={handleClick} />
        </Container>
      ),
      takeAway: (
        <Container className={className}>
          <TakeAway onClick={handleClick} />
        </Container>
      ),
      eatIn: (
        <Container className={className}>
          <EatIn onClick={handleClick} />
        </Container>
      ),
      plusIcon: (
        <Container className={className}>
          <PlusIcon onClick={handleClick} />
        </Container>
      ),
      minusIcon: (
        <Container className={className}>
          <MinusIcon onClick={handleClick} />
        </Container>
      ),
      delivery: (
        <Container className={className}>
          <Delivery onClick={handleClick} />
        </Container>
      ),
    },
  };

  return (
    icons?.[set == null ? 'default' : set]?.[name] || (
      <Container className={className}>
        <CircleX onClick={handleClick} />
      </Container>
    )
  );
}

export default LoadSvg;
