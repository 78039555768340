import CustomInput from "components/CustomInput";
import { useDictionary } from "@adamlip/okeoke.client";

export default function Inputs({ userData, userDataHelperText, handleChange }) {
  const { getValue } = useDictionary();

  return (
    <>
      <CustomInput
        aria-label="address"
        type="text"
        name="addressLabel"
        label={getValue("app_v2_address_label")}
        value={userData?.addressLabel || ""}
        fullWidth={true}
        onChange={handleChange}
        errorMessage={userDataHelperText.addressLabel}
        isInvalid={userDataHelperText.addressLabel}
      />
      <CustomInput
        aria-label="address"
        type="text"
        name="name"
        label={getValue("app_v2_address_name")}
        value={userData?.name || ""}
        fullWidth={true}
        onChange={handleChange}
        errorMessage={userDataHelperText.name}
        isInvalid={userDataHelperText.name}
      />
      <CustomInput
        aria-label="address"
        type="text"
        name="zip"
        inputMode="numeric"
        label={getValue("app_v2_address_zip")}
        value={userData?.zip || ""}
        fullWidth={true}
        onChange={handleChange}
        errorMessage={userDataHelperText.zip}
        isInvalid={userDataHelperText.zip}
        mask={"9999"}
      />
      <CustomInput
        aria-label="address"
        type="text"
        name="state"
        label={getValue("app_v2_address_state")}
        value={userData?.state || ""}
        fullWidth={true}
        onChange={handleChange}
        errorMessage={userDataHelperText.state}
        isInvalid={userDataHelperText.state}
      />
      <CustomInput
        aria-label="address"
        type="text"
        name="city"
        label={getValue("app_v2_address_city")}
        value={userData?.city || ""}
        fullWidth={true}
        onChange={handleChange}
        errorMessage={userDataHelperText.city}
        isInvalid={userDataHelperText.city}
      />
      {/* szigliget */}
      <CustomInput
        aria-label="address"
        type="text"
        name="street"
        label={getValue("app_v2_address_street")}
        value={userData?.street || ""}
        fullWidth={true}
        onChange={handleChange}
        errorMessage={userDataHelperText.street}
        isInvalid={userDataHelperText.street}
      />
      <CustomInput
        aria-label="address"
        type="text"
        name="streetNumber"
        inputMode="numeric"
        label={getValue("app_v2_address_streetNumber")}
        value={userData?.streetNumber || ""}
        fullWidth={true}
        onChange={handleChange}
        errorMessage={userDataHelperText.streetNumber}
        isInvalid={userDataHelperText.streetNumber}
      />
      <CustomInput
        aria-label="address"
        type="text"
        name="telNumber"
        inputMode="tel"
        label={getValue("app_v2_address_phone")}
        value={(userData?.telNumber || "+36").replace(
          /(\+\d{2})(\d{2})(\d{3})(\d{4})/,
          "$1 $2 $3 $4"
        )}
        fullWidth={true}
        onChange={handleChange}
        errorMessage={userDataHelperText.telNumber}
        isInvalid={userDataHelperText.telNumber}
        mask={"+36 99 999 9999"}
      />
      <CustomInput
        aria-label="address"
        type="text"
        name="notes"
        label={getValue("app_v2_address_notes")}
        value={userData?.notes || ""}
        fullWidth={true}
        onChange={handleChange}
        errorMessage={userDataHelperText.notes}
      />
    </>
  );
}
