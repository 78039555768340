import LocationCard from "../../LocationCard";
import WhereTabs from "./WhereTabs";

export default function WhereAndLocation({
  setDelivery,
  setCarPickUp,
  carPickUp,
  onSelectTab,
}) {
  return (
    <div className="basket-take-away-container touch-pan-y px-2">
      <WhereTabs
        setDelivery={setDelivery}
        setCarPickUp={setCarPickUp}
        carPickUp={carPickUp}
        onSelectTab={onSelectTab}
      />
      <LocationCard />
    </div>
  );
}
