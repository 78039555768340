import { OrderStates, useDictionary, useOrder } from "@adamlip/okeoke.client";
import { useDraggable } from "@dnd-kit/core";
import { Button, CircularProgress } from "@nextui-org/react";
import OrderDetailsModal from "components/DraggableOrdersV2/OrderDetailsModal";
import { useEffect, useRef, useState, useContext } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AppParamsContext } from "contexts/AppParamsContext";

function DraggableOrderCard({
  order,
  orders,
  active,
  setOrders,
  setActiveObjectID,
}) {
  const { orderState, orderNumber } = useOrder(null, order?.uuid);

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { appParams } = useContext(AppParamsContext);
  const [lastPart, setLastPart] = useState(null);
  const { getValue } = useDictionary();
  const orderStateRef = useRef(null);
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: order.uuid,
  });
  const { x, y } = transform || { x: 0, y: 0 };

  // Adjust the transform coordinates based on the scale factor
  const scaledTransform = {
    x: x,
    y: y,
  };
  const style = scaledTransform
    ? {
        transform: `translate3d(${scaledTransform.x}px, ${scaledTransform.y}px, 0)`,
      }
    : undefined;

  const renderValue = (state) => {
    switch (state) {
      case null:
        return null;
      case OrderStates.INSERTED:
        return 20;
      case OrderStates.SCHEDULED:
        return 20;
      case OrderStates.SEEN:
        return 20;
      case OrderStates.PREPARING:
        return 40;
      case OrderStates.DONE:
        return 60;
      case OrderStates.CLOSED_SUCCESS:
        return 100;
      case OrderStates.CLOSED_FAIL:
        return 100;
      default:
        return null;
    }
  };
  const handleClick = () => {
    setActiveObjectID(null);
    setIsOpen(true);
  };
  useEffect(() => {
    if (orderStateRef.current) {
      orderStateRef.current = orderState;
    }
  }, []);
  useEffect(() => {
    if (
      orderStateRef.current !== orderState &&
      orderState > 0 &&
      appParams?.order?.toastOrderStateUpdate &&
      orderStateRef.current !== null
    ) {
      if (
        orderState === 10 ||
        orderState === 20 ||
        orderState === 40 ||
        orderState === 50 ||
        orderState === 60 ||
        orderState === 90 ||
        orderState === 91
      ) {
        toast(
          (t) => {
            return (
              <div className="w-full flex flex-col gap-2 items-center justify-center">
                <h1 className="font-bold w-full text-left">
                  {getValue("app_v2_orderStateToastNewUpdate")}
                </h1>

                <p className="text-small opacity-90 text-foreground w-full text-left">
                  {getValue(`app_v2_orderStateToast${orderState}`)}
                </p>
                <div className="flex justify-end items-end w-full ">
                  <Button
                    onClick={() => toast.dismiss(t.id)}
                    color="primary"
                    size="sm"
                  >
                    {getValue("app_v2_orderStateToastDismiss")}
                  </Button>
                </div>
              </div>
            );
          },
          {
            duration: 10000,
            style: {
              width: "100vw",
              backgroundColor: "var(--main-bg-color)",
            },
            id: "orderStateUpdate",
          }
        );
      }
    }
    if (orderStateRef.current === null) {
      orderStateRef.current = orderState;
    }
    if (orderState >= OrderStates.CLOSED_SUCCESS) {
      setTimeout(() => {
        setOrders(orders.filter((orderLol) => orderLol.uuid !== order.uuid));
      }, 3000);
    }
    // eslint-disable-next-line
  }, [orderState]);
  useEffect(() => {
    if (orderNumber) {
      const parts = orderNumber?.split("/");

      setLastPart(parts[parts?.length - 1]);
    }
  }, [orderNumber]);

  return (
    <>
      {isOpen && (
        <OrderDetailsModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          orderUUID={order?.uuid}
        />
      )}
      <div
        ref={setNodeRef}
        style={{
          ...style,
          top: order.top,
          left: order.left,
          cursor: active ? "grabbing" : "grab",
          touchAction: "none",
          position: "absolute",
          zIndex: 999,
        }}
        {...listeners}
        {...attributes}
        id={order.uuid}
      >
        <div
          className="bg-[var(--main-secondary-bg-color)] rounded-full flex items-center justify-center"
          onClick={handleClick}
        >
          <CircularProgress
            size={"lg"}
            color="primary"
            value={renderValue(orderState)}
            classNames={{
              svg: "w-16 h-16",
            }}
            aria-label="Progress"
          />
          <div className="absolute">{lastPart}</div>
        </div>
      </div>
    </>
  );
}

export default DraggableOrderCard;
