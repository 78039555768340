import CustomInput from "components/CustomInput";
import { useDictionary } from "@adamlip/okeoke.client";
import { Select, SelectItem } from "@nextui-org/react";

export default function ConfigInputs({
  userData,
  userDataHelperText,
  handleChange,
  addressConfig,
}) {
  const { getValue } = useDictionary();

  const onChange = (e) => {
    if (e.target.name !== "city" && e.target.name !== "zip") {
      handleChange(e);
    }
  };

  return (
    <>
      <CustomInput
        aria-label="address"
        type="text"
        name="addressLabel"
        label={getValue("app_v2_address_label")}
        value={userData?.addressLabel || ""}
        fullWidth={true}
        onChange={onChange}
        errorMessage={userDataHelperText.addressLabel}
        isInvalid={userDataHelperText.addressLabel}
      />
      <CustomInput
        aria-label="address"
        type="text"
        name="name"
        label={getValue("app_v2_address_name")}
        value={userData?.name || ""}
        fullWidth={true}
        onChange={onChange}
        errorMessage={userDataHelperText.name}
        isInvalid={userDataHelperText.name}
      />
      <CustomInput
        aria-label="address"
        type="text"
        name="zip"
        inputMode="numeric"
        label={getValue("app_v2_address_zip")}
        value={addressConfig?.zip || ""}
        fullWidth={true}
        onChange={onChange}
        errorMessage={userDataHelperText.zip}
        isInvalid={userDataHelperText.zip}
        mask={"9999"}
        disabled
      />
      {/* <CustomInput
        aria-label="address"
        type="text"
        name="state"
        label={getValue("app_v2_address_state")}
        value={userData?.state || ""}
        fullWidth={true}
        onChange={onChange}
        errorMessage={userDataHelperText.state}
        isInvalid={userDataHelperText.state}
      /> */}
      <CustomInput
        aria-label="address"
        type="text"
        name="city"
        label={getValue("app_v2_address_city")}
        value={addressConfig?.city || ""}
        fullWidth={true}
        onChange={onChange}
        errorMessage={userDataHelperText.city}
        isInvalid={userDataHelperText.city}
        disabled
      />
      <Select
        aria-label="12"
        label={getValue("app_v2_address_street")}
        disallowEmptySelection
        onChange={(e) =>
          onChange({ target: { name: "street", value: e.target.value } })
        }
        selectedKeys={userData?.street ? [userData?.street.toString()] : []}
        errorMessage={userDataHelperText?.street}
      >
        {(addressConfig?.streets || []).map((street) => (
          <SelectItem key={street} value={street} aria-label={street}>
            {street}
          </SelectItem>
        ))}
      </Select>
      <CustomInput
        aria-label="address"
        type="text"
        name="streetNumber"
        inputMode="numeric"
        label={getValue("app_v2_address_streetNumber")}
        value={userData?.streetNumber || ""}
        fullWidth={true}
        onChange={onChange}
        errorMessage={userDataHelperText.streetNumber}
        isInvalid={userDataHelperText.streetNumber}
      />
      <CustomInput
        aria-label="address"
        type="text"
        name="telNumber"
        inputMode="tel"
        label={getValue("app_v2_address_phone")}
        value={(userData?.telNumber || "+36").replace(
          /(\+\d{2})(\d{2})(\d{3})(\d{4})/,
          "$1 $2 $3 $4"
        )}
        fullWidth={true}
        onChange={onChange}
        errorMessage={userDataHelperText.telNumber}
        isInvalid={userDataHelperText.telNumber}
        mask={"+36 99 999 9999"}
      />
      <CustomInput
        aria-label="address"
        type="text"
        name="notes"
        label={getValue("app_v2_address_notes")}
        value={userData?.notes || ""}
        fullWidth={true}
        onChange={onChange}
        errorMessage={userDataHelperText.notes}
      />
    </>
  );
}
