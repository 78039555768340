import {useBilling, useClient, useProfile, useVipCustomer, OkeVipCustomerContext} from '@adamlip/okeoke.client';
import {createContext, useEffect, useRef, useState, useContext} from 'react';
//import { useIntercom } from "react-use-intercom";
import LoadingScreen from 'components/LoadingScreen';
import useStorage from 'hooks/useStorage';
import moment from 'moment';
import {toast} from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import {checkToken} from 'utils/User-v2';
import Login from 'views/Login';
import {loginAnonymous} from 'utils/User-v2';
import {AppParamsContext} from 'contexts/AppParamsContext';

const UserContext = createContext();

function UserContextProvider({children}) {
  const {appParams} = useContext(AppParamsContext);
  const {socketAuthenticated, catalogLoading, setToken} = useClient();
  const {getVipProfile, setVipProfile, vipLoggedIn, setVipReferredBy, setUsePoints, getCoupons, activateCoupon, payOrder, claimCoupon, getVipAchievements} = useVipCustomer();
  const {subscriptions, refreshNow} = useBilling();

  const {vipParams} = useContext(OkeVipCustomerContext);

  const {update, profile, invoiceAddresses, deliveryAddresses, updateAddress, addAddress, refresh, deleteAddress} = useProfile();

  //const { boot, update: updateIntercom } = useIntercom();
  const {getSV, removeSV, clearS, setSV} = useStorage();
  const navigate = useNavigate();

  const [isVipBrand, setIsVipBrand] = useState(null);

  const [userData, setUserData] = useState({});
  const [userLoggedIn, setUserLoggedIn] = useState(null);
  const [loginIsOpen, setLoginIsOpen] = useState(false);

  const [pointRedemption, setPointRedemption] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [vipLoading, setVipLoading] = useState(false);
  const timer = useRef(null);

  const refreshCoupons = () => {
    if (vipLoggedIn) {
      setVipLoading(true);
      getCoupons().then((res) => {
        setVipLoading(false);
        if (res.success) {
          setCoupons(res.data.cardCoupons);
        }
      });
    }
  };

  const saveUserData = (callback) => {
    if (getSV('anoLoginUUID')) return null;
    if (userData == null) {
      if (typeof callback === 'function') callback({success: false});
      return null;
    }
    if (isVipBrand) {
      let filteredUserData = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        birthDate: userData.birthDate != null ? (userData.birthDate === '' ? null : moment(userData.birthDate).format('YYYY-MM-DD 12:00')) : null,
        nameDay: userData.nameDay != null ? (userData.nameDay === '' ? null : moment(userData.nameDay).format('YYYY-MM-DD 12:00')) : null,
        gender: userData.gender,
        attributes: {
          allergens: userData.attributes?.allergens,
          foodTags: userData.attributes?.foodTags,
          otherAllergens: userData.attributes?.otherAllergens,
        },
        newsletter: userData.newsletter ? true : false,
        telNumber: userData.telNumber,
        nationality: userData.nationality,
        zip: userData.zip,
        city: userData.city,
        address: userData.address,
        countryCode: userData.countryCode,
        education: userData.education,
        profession: userData.profession,
        relationshipStatus: userData.relationshipStatus,
        anniversary: userData.anniversary != null ? (userData.anniversary === '' ? null : moment(userData.anniversary).format('YYYY-MM-DD 12:00')) : null,
        favoriteFood: userData.favoriteFood,
      };
      console.log('UserData to save:', {...filteredUserData});
      setVipProfile(filteredUserData).then((res) => {
        console.log('VipProfile update res: ', res);
        if (res.success) {
          if (res.data.firstName != null) {
            localStorage['orderIdentifier'] = res.data.firstName;
          }
        }
        if (typeof callback === 'function') callback(res);
      });
    } else {
      let filteredUserData = {
        firstName: userData?.firstName || '',
        lastName: userData?.lastName || '',
        telNumber: userData?.phoneNumber ? `${userData?.dialCode || '+36'} ${userData?.phoneNumber || ''}` : '',
      };
      console.log('Filtered data: ', filteredUserData);
      update(filteredUserData).then((response) => {
        console.log('Profile update res: ', response);
        if (response.uuid) {
          if (response.firstName != null) {
            localStorage['orderIdentifier'] = response.firstName;
          }
        }
        if (typeof callback === 'function') callback(response);
      });
    }
  };

  const saveReferal = (referredByID, callback) => {
    setVipReferredBy(referredByID).then((res) => {
      console.log('Referal save res: ', res);
      if (typeof callback === 'function') callback(res);
    });
  };

  const saveLoginNameVip = () => {
    if (getSV('anoLoginUUID')) return null;
    const refID = localStorage['referalID'];
    localStorage.removeItem('referalID');
    let firstName = getSV('login_first_name');
    if (firstName != null && firstName !== 'undefined') {
      localStorage['orderIdentifier'] = firstName;
    }
    removeSV('login_first_name');
    setVipProfile({firstName}).then((res) => {
      console.log('SaveLoginNameVipRes', res);
      if (res.success) {
        setUserData({
          ...res.data,
          firstName,
          referredByID: userData.referredByID ? userData.referredByID : refID,
        });
      }
    });
  };

  const saveUserFirstLoginNameNotVip = () => {
    if (getSV('anoLoginUUID')) return null;
    let firstName = getSV('login_first_name');
    if (firstName != null && firstName !== 'undefined') {
      localStorage['orderIdentifier'] = firstName;
      removeSV('login_first_name');
    }
    update({firstName}).then((res) => {
      console.log('SaveUserFirstLoginNameNotVipRes', res);
    });
  };

  const handleChangeUserData = (e) => {
    let newUserData = {...userData};
    newUserData[e.target.name] = e.target.value;
    setUserData(newUserData);
  };

  const handleChangeUserAttributes = (e) => {
    let newUserData = {...userData, attributes: {...userData?.attributes}};
    newUserData.attributes[e.target.name] = e.target.value;
    setUserData(newUserData);
  };

  const handleTokenResponse = (res) => {
    console.log('Check token res', res);
    if (res.success) {
      setToken(res.token);
      setUserLoggedIn(true);
      setSV('apiToken', res.token);
      setSV('apiTokenExpires', res.expires);

      let realUser = res.data?.email?.length > 0;
      if (realUser) {
        setSV('realUser', realUser);
        removeSV('anoLoginUUID');
      }
    } else {
      removeSV('apiToken');
    }
  };

  const anoLoginCallback = (res) => {
    if (res.success) {
      setToken(res.data.token);
      setUserLoggedIn(true);
      setSV('anoLoginUUID', res.data?.data?.uuid);
      setSV('apiToken', res.data.token);
      setSV('apiTokenExpires', res.data.expires);
    }
  };

  const logout = () => {
    setToken(null);
    clearS();
    navigate('/');
    window.location.reload();
  };

  useEffect(() => {
    if (!userLoggedIn) {
      // let expiresAfterNow = false;
      // let expiresDate = new Date(getSV("apiTokenExpires") * 1000);
      // let currentDate = new Date();

      // if (expiresDate.getTime() > currentDate.getTime()) expiresAfterNow = true;
      // if (expiresAfterNow) {
      //   setUserLoggedIn(true);
      // }

      if (getSV('apiToken') != null) {
        checkToken(handleTokenResponse);
      }
    }
    // eslint-disable-next-line
  }, [userLoggedIn]);
  useEffect(() => {
    if (!getSV('anoLoginUUID')) {
      if (socketAuthenticated && userLoggedIn && subscriptions != null) {
        let vipBrand;
        vipBrand = subscriptions?.brand?.['license.vip'] ? true : false;
        setIsVipBrand(vipBrand);
        if (vipBrand) {
          if (vipLoggedIn) {
            getVipProfile().then((res) => {
              console.log('Get vip profile: ', res);
              if (res.success) {
                if (localStorage['referalID']) {
                  setVipReferredBy(localStorage['referalID']).then((res) => {
                    console.log(res);
                    if (getSV('login_first_name')) {
                      saveLoginNameVip();
                    } else {
                      setUserData({
                        ...res.data,
                        referredByID: localStorage['referalID'],
                      });
                      localStorage.removeItem('referalID');
                    }
                  });
                } else {
                  if (getSV('login_first_name')) {
                    saveLoginNameVip();
                  } else {
                    setUserData({...res.data});
                  }
                }
              } else {
                toast.error('Error!');
              }
            });
          }
        } else {
          if (profile != null && Object.keys(profile).length > 0) {
            if (getSV('login_first_name')) {
              timer.current = setTimeout(() => saveUserFirstLoginNameNotVip(), 1000);
            } else {
              setUserData({...userData, ...profile});
            }
          }
        }
      }
    }
  }, [socketAuthenticated, subscriptions, vipLoggedIn, userLoggedIn]);

  useEffect(() => {
    if (!catalogLoading && !userLoggedIn) {
      if (appParams?.login?.autoAnoLoginOnAppLoaded) {
        let realUser = getSV('realUser');
        if (!realUser) {
          console.log('Ano login');
          loginAnonymous(anoLoginCallback);
        }
      }
      if (appParams?.login?.forceLoginOnAppLoaded) {
        setLoginIsOpen(true);
      }
    }
  }, [appParams, userLoggedIn, catalogLoading]);

  useEffect(() => {
    refreshCoupons();
  }, [vipLoggedIn]);

  useEffect(() => {
    if (appParams?.vip?.pointRedemption != null) {
      setPointRedemption(appParams?.vip?.pointRedemption);
    } else {
      setPointRedemption(vipParams?.pointRedemption);
    }
  }, [vipParams, appParams]);

  // useEffect(() => {
  //   //console.log(userData);
  //   if (userData != null && Object.keys(userData).length > 0) {
  //     updateIntercom({
  //       name: userData?.firstName + " " + userData?.lastName,
  //       email: userData?.email || "",
  //       phone: userData?.telNumber || "",
  //       external_id: userData?.uuid || "",
  //       user_id: userData?.uuid || "",
  //       customAttributes: {
  //         selectedLocationUUID: selectedLocationUUID || "",
  //         brandName: brandName || "",
  //         forras: "brandApp",
  //         userUUID: userData?.uuid || "",
  //         external_id: userData?.uuid || "",
  //         user_id: userData?.uuid || "",
  //       },
  //     });
  //   }
  //   //console.log(userData);
  // }, [userData]);

  // useEffect(() => {
  //   if (isVipBrand === false) {
  //     if (profile != null && Object.keys(profile).length > 0) {
  //       clearTimeout(timer.current);
  //       let firstName = getSV("login_first_name");
  //       if (firstName != null) {
  //         setUserData({ ...profile, firstName });
  //         timer.current = setTimeout(() => saveUserData(), 1000);
  //       } else {
  //         setUserData({ ...profile });
  //       }
  //     }
  //   }
  // }, [profile, isVipBrand]);

  // useEffect(() => {
  //   boot({
  //     customAttributes: {
  //       brandName: brandName || "",
  //       forras: "brandApp",
  //     },
  //   });
  // }, []);

  return (
    <UserContext.Provider
      value={{
        userData,
        saveUserData,
        logout,
        refresh,
        handleChangeUserData,
        handleChangeUserAttributes,
        invoiceAddresses,
        deliveryAddresses,
        updateAddress,
        addAddress,
        userLoggedIn,
        setUserLoggedIn,
        subscriptions,
        isVipBrand,
        refreshVip: refreshNow,
        saveReferal,
        userVipPoints: userData?.points || 0,
        pointRedemption,
        setUsePoints,
        vipLoggedIn,
        payOrder,
        activateCoupon,
        coupons,
        stores: [],
        selectedStore: null,
        vipID: userData?.vipID,
        refreshCoupons,
        claimCoupon,
        vipLoading,
        deleteAddress,
        setLoginIsOpen,
        getVipAchievements,
      }}>
      {<LoadingScreen loading={catalogLoading} message='' />}
      <Login forceVisible={true} canGuestLogin={true} isOpen={loginIsOpen} setIsOpen={setLoginIsOpen} />
      {!catalogLoading && children}
    </UserContext.Provider>
  );
}

export {UserContext, UserContextProvider};
